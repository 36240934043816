<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Projects
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField label="Search projects" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add site needs to work with Firebase Authentication -->
                    <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addProject">Add Project</AppButton>
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">
                    The following page shows all sites that have access to the App/Portal, you can use this page to Manage sites and access.
                </div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable
                    :tableheaders="filteredHeaders"
                    :loading="loading"
                    custom="sites"
                    :tabledata="tableData"
                    :datatableoptions="tableOptions"
                    :itemstotal="itemsTotal"
                    v-on:delete="deleteProject"
                    v-on:edit="editProject"
                    v-on:restore="restoreProject"
                    v-on:tableoptions="updateTableOptions"
                />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Account Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width: 100% !important">
                                    <v-btn title="Approved" value="Approved"
                                        ><v-icon class="success--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn
                                    >
                                    <v-btn title="Pending" value="Pending"
                                        ><v-icon class="warning--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn
                                    >
                                    <v-btn title="Rejected" value="Rejected"
                                        ><v-icon class="error--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn
                                    >
                                    <v-btn title="Suspended" value="Suspended"
                                        ><v-icon class="info--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn
                                    >
                                    <v-btn title="Archived" value="Archived"
                                        ><v-icon class="grey--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn
                                    >
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ project.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteProject">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VetsAdmin',
    // data
    data: () => ({
        loading: false,
        drawer: false,
        filterDrawer: false,
        confirmBox: false,
        permenantDelete: false,
        search: '',
        filter: {
            status: ['Approved', 'Pending'],
            showDeleted: false
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: 'Name', value: 'name', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'ID', value: 'id', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Organisation ID', value: 'orgId', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Organisation', value: 'orgName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'First Name', value: 'contactFirstName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Last Name', value: 'contactLastName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Job Role', value: 'contactJobRole', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Telephone', value: 'telephone', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Email', value: 'email', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Website', value: 'website', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Notes', value: 'notes', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'createdUserId', value: 'createdUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdDateTime', value: 'createdDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedUserId', value: 'modifiedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedDateTime', value: 'modifiedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedUserId', value: 'deletedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedDateTime', value: 'deletedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deleted', value: 'deleted', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '110px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['name'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        validate: {},
        project: {},
        users: [],
        orgs: [],
        countries: [],
        projectTypes: [],
        projectStatus: [],
        projectGroups: [],
        projectLevels: [],
        projectLanguages: [],
        action: 'Add',
        showInfo: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.search !== '' && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`
            } else {
                query += `@deleted:{false}`
            }
            // // Filter Status
            // if (JSON.stringify(this.filter.status) !== "[]") {
            // 	query += ` @status:{${this.filter.status.join("|")}}`;
            // }
            return query
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0]
            if (sortDesc) {
                sortDesc = 'DESC'
            } else {
                sortDesc = 'ASC'
            }
            return sortDesc
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1
                this.getTableData()
            },
            deep: true
        },
        tableOptions: {
            handler() {
                this.getTableData()
            },
            deep: true
        }
    },
    // methods
    methods: {
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true
                let dataResult = await this.REDIS_searchFor('project', this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery)
                // console.log('dataResult', JSON.stringify(dataResult, null, 2))
                this.tableData = dataResult.data.documents
                // console.log('this.tableData', JSON.stringify(this.tableData, null, 2))
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Add project
        addProject() {
            this.MIX_go('/project/new')
        },
        // Edit project
        editProject(project) {
            this.MIX_go(`/project/${project.id}`)
        },
        // Delete project
        deleteProject(project) {
            this.project = { ...project }
            this.action = 'Delete'
            this.confirmBox = true
        },
        // Close project
        closeProject(project) {
            this.project = { ...project }
            this.drawer = false
        },
        // Restore project
        async restoreProject(project) {
            try {
                this.loading = true
                this.project = { ...project }
                this.project.modifiedUserId = this.GET_currentUserProfile.entityId
                this.project.deleted = false
                this.project.deletedUserId = null
                this.project.deletedDateTime = null
                await this.REDIS_update('project', this.project.entityId, this.project)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Restored', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Restoring', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Confirm Delete project
        async confirmDeleteProject() {
            try {
                this.loading = true
                this.project.modifiedUserId = this.GET_currentUserProfile.entityId
                this.project.deleted = true
                this.project.deletedUserId = this.GET_currentUserProfile.entityId
                this.project.deletedDateTime = this.MIX_formatDateTimeNow('toISOString')
                await this.REDIS_update('project', this.project.entityId, this.project)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Deleted', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Deleting', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            this.getTableData()
            this.loading = false
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        }
    },
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
