<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="">
            <!-- CLIENT DETAILS / PROFILE / NOTES -->
            <v-col cols="12" class="grey lighten-4" style="height: calc(100vh - 60px) !important; overflow-y: hidden">
                <v-row dense>
                    <v-col cols="12" class="white">
                        <div class="text-h5 primary--text d-flex px-5 py-5">
                            Project:
                            <span class="font-weight-bold" v-if="$route.params.id === 'new'">New</span>
                            <span class="font-weight-bold" v-else>{{ project?.projectName }}</span>
                        </div>
                        <v-tabs background-color="grey darken-2" dark v-model="projectTab">
                            <v-tab href="#details"> Details </v-tab>
                            <!-- <v-tab href="#notes"> Notes </v-tab> -->
                            <!-- DETAILS -->
                            <v-tab-item value="details" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row class="">
                                    <v-col cols="6">
                                        <v-row dense>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">PORTAL</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12">
                                                <TextField BackgroundColor="white" label="Project Name *" v-model="project.name" :validate="validate.name" />
                                            </v-col>
                                            <v-col cols="12"
                                                ><DropdownAdvanced
                                                    BackgroundColor="white"
                                                    :items="organisations"
                                                    itemtext="name"
                                                    itemvalue="entityId"
                                                    label="Organisation"
                                                    v-model="project.orgId"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact First Name *"
                                                    v-model="project.contactFirstName"
                                                    :validate="validate.contactFirstName"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact Last Name *"
                                                    v-model="project.contactLastName"
                                                    :validate="validate.contactLastName"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField BackgroundColor="white" label="Contact Job Title" v-model="project.contactJobRole" />
                                            </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Telephone *" v-model="project.telephone" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Email" v-model="project.email" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Website" v-model="project.website" /> </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row dense>
                                            <!-- NOTES -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">NOTES</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"><TextArea background-color="white" label="Notes" v-model="project.notes" rows="10"></TextArea></v-col>
                                            <v-col cols="12" v-if="project.createdUserId !== ''" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">METADATA</div>
                                            </v-col>
                                            <v-col cols="12" v-if="project.createdUserId !== ''"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12" v-if="project.createdUserId !== ''">
                                                <div class="grey lighten-2 caption pa-5">
                                                    ID: {{ project.entityId }}<br />
                                                    Created: {{ MIX_findUserName(project.createdUserId) }} |
                                                    {{ $dayjs(project.createdDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    Modified: {{ MIX_findUserName(project.modifiedUserId) }} |
                                                    {{ $dayjs(project.modifiedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    <div v-if="project.deleted">
                                                        Deleted: {{ MIX_findUserName(project.deletedUserId) }} |
                                                        {{ $dayjs(project.deletedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="cancelProject()">Cancel</AppButton>
                                        </div>
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveProject()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
									<v-col cols="12">
										<pre>{{ project }}</pre>
									</v-col>
								</v-row> -->
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-col>

            <!-- CONFIRMATION BOX -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ project.projectName }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ClientView',
    // data
    data: () => ({
        loading: false,
        // LOOKUPS
        confirmBox: false,

        // PORTAL RELATED
        projectTab: 'details',
        project: {},
        validate: {}
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        })
    },
    // * METHODS
    methods: {
        // Initialise
        async initialise() {
            this.loading = true
            // Get Users
            try {
                await this.MIX_getUsers()
            } catch (error) {
                console.error(error)
            }
            // Get Organisation
            try {
                await this.MIX_getOrganisations()
            } catch (error) {
                console.error(error)
            }
            // Get Project
            if (this.$route.params.id !== 'new') {
                let projectResult = await this.REDIS_read('project', this.$route.params.id)
                this.project = projectResult.data
                // Get Project Profile
            } else {
                this.project = { ...this.$schema.project }
            }
            this.loading = false
        },
        // Save Project
        async saveProject() {
            try {
                let project = { ...this.project }
                project.orgName = this.MIX_findOrganisationName(this.project.orgId)
                project.modifiedUserId = this.GET_currentUserProfile.entityId
                project.modifiedDateTime = this.$dayjs().unix()
                project.deleted = false
                project.deletedDateTime = null
                project.deletedUserId = null
                let result = null
                if (this.$route.params.id === 'new') {
                    project.id = this.MIX_generateId()
                    project.createdUserId = this.GET_currentUserProfile.entityId
                    project.createdDateTime = this.$dayjs().unix()
                    result = await this.REDIS_createWithId('project', project, project.id)
                } else {
                    result = await this.REDIS_update('project', project.id, project)
                }
                if (result.success) {
                    this.MIX_alertBox({ show: true, message: 'Project Saved', color: 'success', timeout: '2000' })
                    this.$router.push('/project-admin')
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving Project', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        cancelProject() {
            this.$router.push('/project-admin')
        }
    },
    // * WATCH
    watch: {},
    // * CREATED
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
}
</style>
