<template>
    <v-simple-table :dense="dense" :selectionable="false">
        <template v-slot:default>
            <tbody>
                <tr v-for="(item, index) in tabledata" :key="index">
                    <td class="font-weight-bold text--secondary" width="40%">{{ item.text }}</td>
                    <td class="text--secondary" width="60%" >
                        <span :class="item.class" v-if="item.link === '' || item.link === undefined">{{ item.value }}</span>
                        <span :class="item.class" v-else><a :class="item.class" :href="item.link" target="_blank">{{ item.value }}</a></span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    // props
    props: {
        tabledata: {
            type: Array,
            default: () => [],
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
