<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="">
            <!-- CLIENT DETAILS / PROFILE / NOTES -->
            <v-col cols="12" class="grey lighten-4" style="height: calc(100vh - 60px) !important; overflow-y: hidden">
                <v-row dense>
                    <v-col cols="12" class="white">
                        <div class="text-h5 primary--text d-flex px-5 py-5">
                            Organisation:
                            <span class="font-weight-bold" v-if="$route.params.id === 'new'">New</span>
                            <span class="font-weight-bold" v-else>{{ org?.name }}</span>
                        </div>
                        <v-tabs background-color="grey darken-2" dark v-model="orgTab">
                            <v-tab href="#details"> Details </v-tab>
                            <v-tab href="#divisions"> Divions / Regions </v-tab>
                            <!-- DETAILS -->
                            <v-tab-item value="details" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row class="">
                                    <v-col cols="6">
                                        <v-row dense>
                                            <div class="mt-4 ml-2 text-h7 font-weight-bold primary--text" v-if="$route.params.id === 'new'">
                                                Google Places Search
                                            </div>
                                            <v-col v-if="$route.params.id === 'new'" cols="12">
                                                <vuetify-google-autocomplete
                                                    label="Enter a location"
                                                    autocomplete="donotcomplete"
                                                    solo
                                                    background-color="white"
                                                    name="myapp-name"
                                                    ref="addressInput"
                                                    types=""
                                                    id="map"
                                                    classname="form-control"
                                                    v-on:placechanged="getAddressData"
                                                    country="gb"
                                                    clearable
                                                >
                                                </vuetify-google-autocomplete
                                            ></v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">CONTACT</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"> <TextField BackgroundColor="white" label="Organisation Name" v-model="org.name" /> </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact First Name *"
                                                    v-model="org.contactFirstName"
                                                    :validate="validate.contactFirstName"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact Last Name *"
                                                    v-model="org.contactLastName"
                                                    :validate="validate.contactLastName"
                                                />
                                            </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Contact Job Title" v-model="org.contactJobRole" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Telephone *" v-model="org.telephone" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Email" v-model="org.email" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Website" v-model="org.website" /> </v-col>
                                            <!--- ADDRESS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">ADDRESS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 1" v-model="org.addressLine1" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 2" v-model="org.addressLine2" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 3" v-model="org.addressLine3" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Town" v-model="org.town" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="County" v-model="org.county" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Postcode" v-model="org.postcode" /> </v-col>
                                            <v-col cols="12">
                                                <DropdownSimple BackgroundColor="white" :items="countries" label="Country" v-model="org.country" />
                                            </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Latitude" v-model="org.latitude" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Longitude" v-model="org.longitude" /> </v-col>
                                            <!--- STATUS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">STATUS</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <DropdownSimple BackgroundColor="white" :items="orgStatus" label="Status" v-model="org.status" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row dense>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">DATABASE</div>
                                            </v-col>

                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"> <TextField BackgroundColor="white" label="Database URL" v-model="org.databaseURL" /> </v-col>
                                            <v-col cols="12"> <TextField BackgroundColor="white" label="Database Port" v-model="org.databasePort" /> </v-col>

                                            <v-col cols="12" class="d-flex">
                                                <div class="flex-grow-1">
                                                    <TextField
                                                        BackgroundColor="white"
                                                        label="Database Password"
                                                        :type="org.databaseEncrypted === true ? 'password' : 'text'"
                                                        v-model="org.databasePassword"
                                                        :validate="validate.contactFirstName"
                                                    />
                                                </div>
                                                <div>
                                                    <AppButton
                                                        buttonclass="secondary mt-1"
                                                        :disabled="!org.databaseEncrypted"
                                                        @click.native="
                                                            org.databaseEncrypted = false
                                                            org.databasePassword = ''
                                                        "
                                                        >Reset Password</AppButton
                                                    >
                                                </div>
                                            </v-col>
                                            <!-- ACCOUNT -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">OWNER</div>
                                                <v-spacer />
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"
                                                ><DropdownAdvanced
                                                    BackgroundColor="white"
                                                    :items="users"
                                                    itemtext="email"
                                                    itemvalue="entityId"
                                                    label="Account Owner"
                                                    v-model="org.ownerUserId"
                                                />
                                            </v-col>
                                            <!-- PARENT -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">PARENT ORGANISATION</div>
                                                <v-spacer />
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"
                                                ><DropdownAdvanced
                                                    BackgroundColor="white"
                                                    :items="organisations"
                                                    itemtext="name"
                                                    itemvalue="entityId"
                                                    label="Parent Organisation"
                                                    v-model="org.parentId"
                                                />
                                            </v-col>
                                            <!-- PORTAL -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">PORTAL</div>
                                                <v-spacer />
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"
                                                ><DropdownAdvanced
                                                    BackgroundColor="white"
                                                    :items="portals"
                                                    itemtext="portalName"
                                                    itemvalue="entityId"
                                                    label="Portal *"
                                                    v-model="org.portalId"
                                                />
                                            </v-col>
                                            <!-- LOGO -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">LOGO</div>
                                                <v-spacer />
                                            </v-col>
                                            <v-col cols="12">
                                                <TextField BackgroundColor="white" label="Logo Image URL *" v-model="org.imageURL" :validate="validate.imageURL" />
                                            </v-col>
                                            <v-col cols="12">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Report Image URL *"
                                                    v-model="org.reportImageURL"
                                                    :validate="validate.reportImageURL"
                                                />
                                            </v-col>
                                            <!-- METADATA -->
                                            <v-col cols="12" class="d-flex align-center" v-if="$route.params.id !== 'new'">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">METADATA</div>
                                                <v-spacer />
                                            </v-col>
                                            <v-col cols="12" v-if="$route.params.id !== 'new'"><v-divider class="mb-2" /></v-col>

                                            <v-col cols="12">
                                                <div class="grey lighten-2 caption pa-5" v-if="$route.params.id !== 'new'">
                                                    ID: {{ org.entityId }}<br />
                                                    Created: {{ MIX_findUserName(org.createdUserId) }} | {{ $dayjs(org.createdDateTime).format('DD MMM YY HH:mm:ss')
                                                    }}<br />
                                                    Modified: {{ MIX_findUserName(org.modifiedUserId) }} |
                                                    {{ $dayjs(org.modifiedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    <div v-if="org.deleted">
                                                        Deleted: {{ MIX_findUserName(org.deletedUserId) }} |
                                                        {{ $dayjs(org.deletedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="close()">Cancel</AppButton>
                                        </div>
                                        <!-- <div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="grey" @click.native="closeOrg">Cancel</AppButton></div> -->
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveOrg()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <!-- DIVISIONS / REGIONS -->
                            <v-tab-item value="divisions" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row class="">
                                    <v-col cols="6">
                                        <v-row dense>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">DIVISIONS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="flex-grow-1"><TextField
                                                    BackgroundColor="white"
                                                    label="Division Name"
                                                    v-model="divisionName"
                                                /></div>
                                                <div><AppButton block buttonclass="secondary" @click.native="addDivision">Add</AppButton></div>
                                            </v-col>
                                            <v-col cols="12">
                                                <table border="0" width="100%">
                                                    <tr v-for="(division, index) in org.divisions" :key="index">
                                                        <td class="text-h7">
                                                            {{ division}}
                                                        </td>
                                                        <td
                                                            width="50"><Button class="rounded pa-2 secondary white--text" @click="removeDivision(index)">X
                                                        </Button></td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row dense>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">REGIONS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="flex-grow-1"><TextField
                                                    BackgroundColor="white"
                                                    label="Region Name"
                                                    v-model="regionName"
                                                /></div>
                                                <div><AppButton block buttonclass="secondary" @click.native="addRegion">Add</AppButton></div>
                                            </v-col>
                                            <v-col cols="12">
                                                <table border="0" width="100%">
                                                    <tr v-for="(region, index) in org.regions" :key="index">
                                                        <td class="text-h7">
                                                            {{ region}}
                                                        </td>
                                                        <td
                                                            width="50"><Button class="rounded pa-2 secondary white--text" @click="removeRegion(index)">X</Button></td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
<!--                                <v-row>-->
<!--                                    <v-col cols="12">-->
<!--                                        <pre>{{ org }}</pre>-->
<!--                                    </v-col>-->
<!--                                </v-row>-->
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="close()">Cancel</AppButton>
                                        </div>
                                        <!-- <div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="grey" @click.native="closeOrg">Cancel</AppButton></div> -->
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveOrg()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-col>

            <!-- CONFIRMATION BOX -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ org.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ClientView',
    // data
    data: () => ({
        loading: false,
        // GENERAL
        confirmBox: false,

        // ORGANISATION RELATED
        orgTab: 'details',
        org: {
            location: {}
        },
        validate: {},
        showAddress: false,
        showAccount: false,
        showContact: true,

        // BUSINESS RELATED
        businessDrawer: false,
        businessTabs: 'details',
        business: {},

        // MATCH RELATED
        matchStatus: [],
        match: {},

        showResult: false,
        itemsTotal: 1,
        tableHeaders: [
            { text: 'Business', value: 'businessTitle', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Match Percentage', value: 'matchPercent', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Status', value: 'status', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '110px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['businessTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        divisionName: '',
        regionName: '',
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        }
    },
    // methods
    methods: {
        // Initialise
        async initialise() {
            this.loading = true
            //get users
            try {
                await this.MIX_getUsers()
            } catch (error) {
                console.error(error)
            }
            // Get Portals
            try {
                await this.MIX_getPortals()
            } catch (error) {
                console.error(error)
            }
            // Get Organisations
            try {
                await this.MIX_getOrganisations()
            } catch (error) {
                console.error(error)
            }
            // Get Lookups
            try {
                await this.MIX_getLookups()
            } catch (error) {
                console.error(error)
            }
            // Get Organisation
            if (this.$route.params.id !== 'new') {
                let orgResult = await this.REDIS_read('org', this.$route.params.id)
                this.org = orgResult.data
                // Get Organisation Profile
            } else {
                this.org = { ...this.$schema.org }
            }
            this.loading = false
        },
        async saveOrg() {
            try {
                let org = { ...this.org }
                org.modifiedUserId = this.GET_currentUserProfile.entityId
                org.modifiedDateTime = this.$dayjs().unix()
                org.deleted = false
                org.deletedDateTime = null
                org.deletedUserId = null
                if (org.portalId !== null && org.portalId !== undefined && org.portalId !== '') {
                    org.portalName = this.MIX_findPortalName(org.portalId)
                }
                let result = null
                if (this.$route.params.id === 'new') {
                    org.id = this.MIX_generateId()
                    org.createdUserId = this.GET_currentUserProfile.entityId
                    org.createdDateTime = this.$dayjs().unix()
                    result = await this.REDIS_createWithId('org', org, org.id)
                } else {
                    result = await this.REDIS_update('org', org.id, org)
                }
                if (result.success) {
                    this.MIX_alertBox({ show: true, message: 'Organisation Saved', color: 'success', timeout: '2000' })
                    this.$router.push('/organisation-admin')
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving Organisation', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Add Division
        addDivision() {
            if (this.divisionName !== '') {
                if (this.org.divisions === undefined || this.org.divisions === null) {
                    this.org.divisions = []
                }
                console.log('this.org.divisions', this.org.divisions + ' ' + typeof(this.org.divisions) + ' ' + this.divisionName)
                // check if division already exists
                if (this.org.divisions?.includes(this.divisionName)) {
                    this.MIX_alertBox({ show: true, message: 'Division already exists', color: 'error', timeout: '4000' })
                } else {
                    this.org.divisions.push(this.divisionName)
                    this.divisionName = ''
                }
            }
        },
        // Remove Division
        removeDivision(index) {
            this.org.divisions.splice(index, 1)
        },
        // Add Region
        addRegion() {
            if (this.regionName !== '') {
                if (this.org.regions === undefined || this.org.regions === null) {
                    this.org.regions = []
                }
                // check if region already exists
                if (this.org.regions?.includes(this.regionName)) {
                    this.MIX_alertBox({ show: true, message: 'Region already exists', color: 'error', timeout: '4000' })
                } else {
                    this.org.regions.push(this.regionName)
                    this.regionName = ''
                }
            }
        },
        // Remove Region
        removeRegion(index) {
            this.org.regions.splice(index, 1)
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData))
            this.org.name = placeResult.name
            this.org.addressLine1 = placeResult.addressLine1
            this.org.addressLine2 = placeResult.addressLine2
            this.org.town = placeResult.town
            this.org.county = placeResult.county
            this.org.postcode = placeResult.postcode
            this.org.latitude = placeResult.lat
            this.org.longitude = placeResult.lng
            this.org.website = placeResult.website
            this.org.telephone = placeResult.telephone
        },
        // Close
        closeOrg() {
            this.$router.push('/organisation-admin')
        }
    },
    watch: {},
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
}
</style>
