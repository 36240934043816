<template>
    <div>
        <v-data-table
            :headers="tableheaders"
            :items="tabledata"
            :options.sync="tableOptions"
            :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }"
            class="elevation-0 rounded-of"
            :server-items-length="itemstotal"
        >
            <template v-slot:top="{ pagination, updateOptions }">
                <v-data-footer
                    :pagination="pagination"
                    :options="tableOptions"
                    @update:options="updateOptions"
                    :items-per-page-options="[5, 10, 15, 25, 50, 100]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
            </template>
            <!-- Name -->
            <template #[`item.name`]="{ item }">
                <div class="font-weight-bold">{{ item.name }}</div>
            </template>
            <!-- Email -->
            <template #[`item.email`]="{ item }">
                <a class="text-decoration-none" :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>
            <!-- Website -->
            <template #[`item.website`]="{ item }">
                <a class="text-decoration-none" :href="`${item.website}`" target="_blank">{{ item.website }}</a>
            </template>
            <!-- Organisation -->
            <template #[`item.orgName`]="{ item }">
                <div class="primary--text pointer" @click="MIX_go('/organisation/' + item.orgId)">{{ item.orgName }}</div>
            </template>
            <!-- Status -->
            <template #[`item.status`]="{ item }">
                <v-icon title="Approved" class="success--text font-weight-bold" v-if="item.status === 'Approved'">icons8-circle</v-icon>
                <v-icon title="Pending" class="warning--text font-weight-bold" v-if="item.status === 'Pending'">icons8-circle</v-icon>
                <v-icon title="Suspended" class="info--text font-weight-bold" v-if="item.status === 'Suspended'">icons8-circle</v-icon>
                <v-icon title="Rejected" class="error--text font-weight-bold" v-if="item.status === 'Rejected'">icons8-circle</v-icon>
                <v-icon title="Achived" class="grey--text font-weight-bold" v-if="item.status === 'Archived'">icons8-circle</v-icon>
                <v-icon title="Up" class="success--text font-weight-bold" v-if="item.status === 'Up'">icons8-circle</v-icon>
                <v-icon title="Down" class="error--text font-weight-bold" v-if="item.status === 'Down'">icons8-circle</v-icon>
                <v-icon title="Active" class="success--text font-weight-bold" v-if="item.status === 'Active'">icons8-circle</v-icon>
            </template>
            <template #[`item.action`]="{ item }">
                <v-icon class="mx-1" title="Copy ID" v-if="!item.deleted && custom !== 'organisation'" @click="copyItem(item)">icons8-copy-2</v-icon>
<!--                <v-icon class="primary&#45;&#45;text mx-1" title="Dashboard" v-if="custom === 'sites' || custom === 'organisation'" @click="MIX_go('/site-dashboard/' +-->
<!--                item.id)"-->
<!--                    >icons8-bar-chart</v-icon-->
<!--                >-->
                <v-icon class="primary--text mx-1" title="Dashboard" v-if="custom === 'sites' || custom === 'organisation'" @click="MIX_go('/site-dashboardV2/' +
                item.id)"
                    >icons8-bar-chart</v-icon
                >
                <v-icon class="mx-1" title="Restore" v-if="item.deleted && actions.includes('Restore')" @click="restoreItem(item)">icons8-checked-checkbox</v-icon>
                <v-icon class="mx-1" title="Edit" v-if="!item.deleted && actions.includes('Edit')" @click="editItem(item)">icons8-right-button</v-icon>
                <v-icon class="primary--text mx-1" title="View" v-if="!item.deleted && actions.includes('View')" @click="viewItem(item)"
                    >icons8-info -squared</v-icon
                >
                <v-icon class="mx-1" title="Star" v-if="!item.deleted && actions.includes('Star')" @click="starItem(item)">icons8-rating</v-icon>
                <!--                <v-icon class="error&#45;&#45;text" title="Delete" v-if="!item.deleted && actions.includes('Delete')" @click="deleteItem(item)">icons8-close-window</v-icon>-->
            </template>
            <template #[`item.enabled`]="{ item }"> <span v-if="item.enabled">Yes</span><span v-else>No</span> </template>
            <!-- Custom -->
            <template #[`item.listingDateTime`]="{ item }" v-if="custom === 'business'">
                <span>{{ MIX_formatDateTime(item.listingDateTime, 'ISOString', 'DD MMM YY') }}</span>
            </template>
            <template #[`item.lastFeedDateTime`]="{ item }" v-if="custom === 'source'">
                <span>{{ MIX_formatDateTime(item.lastFeedDateTime, 'ISOString', 'DD MMM YY @ HH:mm') }}</span>
                | <span>{{ MIX_formatDateTimeFromNow(item.lastFeedDateTime, 'ISOString', 'Days') }}</span>
            </template>
            <template #[`item.lastSeenDateTime`]="{ item }" v-if="custom === 'business'">
                <span class="success--text" v-if="MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') === 'Today'">{{
                    MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days')
                }}</span>
                <span v-else class="warning--text">{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') }}</span>
                <!-- <span>{{ MIX_formatDateTime(item.lastSeenDateTime, "ISOString", "DD MMM YY @ HH:mm") }}</span> -->
            </template>
        </v-data-table>
        <CopyrightBanner />
    </div>
</template>
<script>
export default {
    // props
    props: {
        tableheaders: {
            type: Array,
            default: () => []
        },
        tabledata: {
            type: Array,
            default: () => []
        },
        datatableoptions: {
            type: Object,
            default: () => ({})
        },
        itemstotal: {
            type: Number,
            default: 0
        },
        custom: {
            type: String,
            default: ''
        },
        dense: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default: () => ['Edit', 'Delete']
        }
    },
    // data
    data: () => ({
        tableOptions: {}
    }),
    // computed: {
    // 	calcTableHeight() {
    // 		let tableHeight = window.innerHeight - 420;
    // 		if (tableHeight < 300) {
    // 			tableHeight = 300;
    // 		}
    // 		return tableHeight;
    // 	},
    // },
    // methods
    methods: {
        restoreItem(item) {
            this.$emit('restore', item)
        },
        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        viewItem(item) {
            this.$emit('view', item)
        },
        ViewItemTemps(item) {
            this.$emit('viewtemps', item)
        },
        copyItem(item) {
            this.$emit('copy', item)
        }
    },
    created() {
        this.tableOptions = this.datatableoptions
    },
    watch: {
        datatableoptions: {
            handler: function (val) {
                this.tableOptions = val
            },
            deep: true
        },
        tableOptions: {
            handler: function (val) {
                this.$emit('tableoptions', val)
            },
            deep: true
        }
    }
}
</script>
