<template>
    <div>
        <div v-if="loading" :style="`height: ${height}px !important;`" class="d-flex align-center justify-center flex-column">
            <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
            <div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
        </div>
        <div v-else>
            <v-chart class="chart" :option="option" :style="`height: ${height}px !important;`" />
        </div>
    </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { defineComponent, ref } from 'vue'
import dayjs from 'dayjs'

use([GridComponent, CanvasRenderer, BarChart])

export default defineComponent({
    name: 'HelloWorld',
    components: {
        VChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        datadown: {
            type: Array,
            default: () => []
        },
        seriesdata: {
            type: Array,
            default: () => []
        },
        xaxisdata: {
            type: Array,
            default: () => []
        },
        yaxisdata: {
            type: Array,
            default: () => []
        },
        yaxisunit: {
            type: String,
            default: ''
        },
        y2axisdata: {
            type: Array,
            default: () => []
        },
        chartTitle: {
            type: String,
            default: 'AutomateChart'
        },
        xaxislabel: {
            type: String,
            default: ''
        },
        yaxislabel: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: 500
        },
        print: {
            type: Boolean,
            default: false
        },
        isDay: {
            type: Boolean,
            default: true
        },
        isNight: {
            type: Boolean,
            default: true
        },
        dayData: {
            type: Array,
            default: () => []
        },
        nightData: {
            type: Array,
            default: () => []
        }
    },
    provide: {
        [THEME_KEY]: 'light'
    },
    computed: {
        xAxisSeries() {
            return this.datadown.map((item) => item.timestamp)
        },
        yAxisSeries() {
            return this.datadown.map((item) => item.value)
        }
    },
    setup(vm) {
        const option = ref({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%']
                },
                formatter: function (params) {
                    return `<span class='font-weight-bold primary--text'>Day: </span>
${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[0].data)}
                        ${vm.yaxisunit} <br/>
                        <span class='font-weight-bold primary--text'>Night: </span>
${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[1].data)}
                        ${vm.yaxisunit} <br/>
                        <span class='font-weight-bold primary--text'>total: </span>
${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[0].data + params[1].data)}
                        ${vm.yaxisunit} <br/>
                        <span class='font-weight-normal caption'>${dayjs(vm.xaxisdata[params[0].dataIndex]).format('dddd')}</span><br/>`
                }
            },
            legend: {},
            grid: {
                containLabel: true,
                left: 50,
                right: 50,
                top: 40,
                bottom: 70
            },
            toolbox: {
                show: !vm.print,
                feature: {
                    dataZoom: {
                        yAxisIndex: false
                    },
                    saveAsImage: {
                        pixelRatio: 2
                    }
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: !vm.print,
                    xAxisIndex: [0],
                    start: 1,
                    end: 100,
                    bottom: 30
                }
            ],
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: vm.xaxisdata
            },
            series: [
                {
                    name: 'Day',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        // show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                },
                {
                    name: 'Night',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        // show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                }
            ]
        })

        return { option }
    },
    watch: {
        dayData: {
            handler: function (val) {
                this.option.series[0].data = val
            },
            deep: true
        },
        nightData: {
            handler: function (val) {
                this.option.series[1].data = val
            },
            deep: true
        }
    },
    created() {
        // console.log('datadown', this.dayData)
        // console.log('isDay', this.isDay)
        // console.log('isNight', this.isNight)
        // this.option.series[0].data = this.datadown
        if (this.isDay) {
            this.option.series[0].data = this.dayData
        }
        if (this.isNight) {
            this.option.series[1].data = this.nightData
        }
    }
})
</script>

<style scoped>
.chart {
    width: 100% !important;
    /* height: 100vh; */
}
</style>
