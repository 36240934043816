const user = {
    firstName: '',
    lastName: '',
    jobRole: '',
    telephone: '',
    email: '',
    orgId: '',
    status: '',
    group: '',
    level: '',
    orgType: '',
    termsAgreed: false,
    optInAgreed: false,
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const org = {
    id: '',
    name: '',
    status: '',
    contactFirstName: '',
    contactLastName: '',
    contactJobRole: '',
    telephone: '',
    email: '',
    website: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postcode: '',
    latitude: '',
    longitude: '',
    parentId: '',
    portalId: '',
    portalName: '',
    databaseURL: '',
    databasePort: '',
    databasePassword: '',
    databaseEncrypted: false,
    imageURL: '',
    reportImageURL: '',
    divisions: [],
    regions: [],
    ownerUserId: '',
    createdUserId: '',
    createdDateTime: 0,
    modifiedUserId: '',
    modifiedDateTime: 0,
    deletedUserId: '',
    deletedDateTime: 0,
    deleted: false
}

const portal = {
    id: '',
    portalName: '',
    portalDomains: [],
    portalEmail: '',
    portalTelephone: '',
    primary: '',
    secondary: '',
    accent: '',
    accent2: '',
    imageURL: '',
    reportImageURL: '',
    ownerUserId: '',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const site = {
    id: 'current',
    siteId: '',
    siteName: '',
    status: '',
    contactFirstName: '',
    contactLastName: '',
    contactJobRole: '',
    telephone: '',
    email: '',
    website: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postcode: '',
    country: '',
    latitude: '',
    longitude: '',
    orgId: '',
    orgName: '',
    projectId: '',
    projectName: '',
    type: '',
    costPerkWh: 0,
    costStandingCharge: 0,
    rentalCost: 0,
    costPerLitre: 0,
    comparisonkWh: 0,
    mode: 'monitoring',
    cabins: 1,
    cabinNames: '',
    comparisonEnergyMap: '',
    notes: '',
    productId: '',
    division: '',
    region: '',
    boxName: '',
    accommodation: [],
    startDateTime: 0,
    endDateTime: 0,
    tier: 'Tier 3',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const orgprofile = {
    orgId: '',
    orgName: '',
    description: '',
    inSectors: [],
    exSectors: [],
    inSubSectors: [],
    exSubSectors: [],
    inKeywords: [],
    exKeywords: [],
    inLocations: [],
    exLocations: [],
    inOwnership: [],
    exOwnership: [],
    priceMin: 0,
    priceMax: 0,
    turnoverMin: 0,
    turnoverMax: 0,
    ebitdaMin: 0,
    ebitdaMax: 0,
    profitMin: 0,
    profitMax: 0,
    weights: '',
    enabled: true,
    ownerUserId: '',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const project = {
    id: '',
    name: '',
    orgId: '',
    orgName: '',
    contactFirstName: '',
    contactLastName: '',
    contactJobRole: '',
    telephone: '',
    email: '',
    website: '',
    notes: '',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const lookup = {
    text: '',
    value: '',
    type: '',
    filter: '',
    deleted: false
}

const source = {
    name: '',
    description: '',
    orgId: '',
    website: '',
    username: '',
    password: '',
    contactName: '',
    contactEmail: '',
    contactTelephone: '',
    lastFeedDateTime: '',
    status: '',
    enabled: true,
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const business = {
    id: '',
    title: '',
    summary: '',
    description: '',
    points: [],
    text: '',
    ref: '',
    location: [],
    city: [],
    county: [],
    region: [],
    country: [],
    turnover: 0,
    price: 0,
    profit: 0,
    ebitda: 0,
    currency: 'GBP',
    financialYear: '',
    sourceId: '',
    sourceName: '',
    sourceURL: '',
    sectors: [],
    subSectors: [],
    keywords: [],
    ownership: '',
    type: '',
    status: '',
    engaged: '',
    agreement: '',
    string1: '',
    string2: '',
    string3: '',
    string4: '',
    string5: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
    listingDateTime: '',
    lastSeenDateTime: '',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

const match = {
    status: '',
    orgId: '',
    orgName: '',
    orgProfileId: '',
    businessId: '',
    businessTitle: '',
    matchPercent: '',
    matchReport: '',
    reason: '',
    reasonNotes: '',
    createdUserId: '',
    createdDateTime: '',
    modifiedUserId: '',
    modifiedDateTime: '',
    deletedUserId: '',
    deletedDateTime: '',
    deleted: false
}

module.exports = {
    user,
    org,
    lookup,
    // Automate specific
    portal,
    project,
    site,
    source,
    orgprofile,
    business,
    match
}
