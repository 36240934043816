<template>
    <div>
        <div v-if="loading" :style="`height: ${height}px !important;`" class="d-flex align-center justify-center flex-column">
            <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
            <div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
        </div>
        <div v-else>
            <v-chart class="chart" :option="option" :style="`height: ${height}px !important;`" />
        </div>
        <!--        <pre>{{ datadown }}</pre>-->
    </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { defineComponent, ref } from 'vue'
import dayjs from 'dayjs'

use([GridComponent, CanvasRenderer, BarChart])

export default defineComponent({
    name: 'HelloWorld',
    components: {
        VChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        datadown: {
            type: Object,
            default: () => {}
        },
        seriesdata: {
            type: Array,
            default: () => []
        },
        xaxisdata: {
            type: Array,
            default: () => []
        },
        yaxisdata: {
            type: Array,
            default: () => []
        },
        y2axisdata: {
            type: Array,
            default: () => []
        },
        chartTitle: {
            type: String,
            default: 'AutomateChart'
        },
        xaxislabel: {
            type: String,
            default: ''
        },
        yaxislabel: {
            type: String,
            default: ''
        },
        y2axislabel: {
            type: String,
            default: ''
        },
        y2axisunit: {
            type: String,
            default: ''
        },
        yaxisunit: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: 500
        },
        print: {
            type: Boolean,
            default: false
        }
    },
    provide: {
        [THEME_KEY]: 'light'
    },
    setup(vm) {
        const option = ref({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%']
                },
                formatter: function (params) {
                    // Assuming the date is the first parameter
                    console.log('params: ' + JSON.stringify(params, null, 2))
                    const date = params[0].data[0]
                    var formattedDate = dayjs(date).format('DD-MMM-YY HH:mm')
                    return `<span class='font-weight-bold primary--text'>${vm.yaxislabel}: </span>
${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[0].data[1])}
                        ${vm.yaxisunit} <br/>
                        <span class='font-weight-normal caption'>${formattedDate}</span><br/>`
                }
            },
            grid: {
                containLabel: true,
                left: 50,
                right: 80,
                top: 40,
                bottom: 70
            },
            toolbox: {
                show: !vm.print,
                feature: {
                    dataZoom: {
                        yAxisIndex: false
                    },
                    saveAsImage: {
                        pixelRatio: 2
                    }
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: !vm.print,
                    xAxisIndex: [0],
                    start: 1,
                    end: 100,
                    bottom: 30
                }
            ],
            // yAxis: {
            //     name: vm.yaxislabel,
            //     type: "value",
            // },
            yAxis: [
                {
                    type: 'value',
                    name: vm.yaxislabel,
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true
                    },
                    nameLocation: 'middle', // Position of the title
                    nameGap: 40, // Gap between the axis and the title
                    nameRotate: 90, // Rotate the title (optional)
                    // boundaryGap: [0, '100%'],
                    axisLabel: {
                        fontSize: 14
                    }
                }
                // {
                //     type: 'value',
                //     name: 'Temperature',
                //     position: 'right',
                //     alignTicks: true,
                //     axisLine: {
                //         show: true
                //     },
                //     nameLocation: 'middle', // Position of the title
                //     nameGap: 50, // Gap between the axis and the title
                //     nameRotate: 90, // Rotate the title (optional)
                //     // boundaryGap: [0, '100%'],
                //     axisLabel: {
                //         fontSize: 14,
                //         formatter: '{value} °C'
                //     }
                // }
            ],
            //
            // xAxis: {
            //     // name: vm.xaxislabel,
            //     data: vm.xaxisdata,
            //     type: 'category'
            // },
            xAxis: {
                name: vm.xaxislabel,
                type: 'time',
                splitLine: {
                    show: false
                },
                // boundaryGap: false,
                nameLocation: 'middle', // Position of the title
                nameGap: 70, // Gap between the axis and the title
                // data: vm.mydates,
                axisLabel: {
                    fontSize: 14
                }
            },
            series: [
                {
                    name: vm.yaxislabel,
                    data: [],
                    // color: '#034853',
                    barWidth: '95%', // Adjust this value as needed
                    type: 'bar',
                    tooltip: {
                        show: true // Disable tooltips for this series
                    }
                }
                // {
                //     name: 'Temperature',
                //     type: 'line',
                //     yAxisIndex: 1,
                //     data: [],
                //     lineStyle: {
                //         width: 3 // Adjust the thickness of the series line here
                //     }
                // }
            ]
        })

        return { option }
    },
    watch: {
        datadown: {
            handler: function (val) {
                // console.log('watcher in component')
                this.option.series[0].data = val.occupancy
                // this.option.series[1].data = val.temperature
            },
            deep: true
        }
    },
    created() {
        // console.log('datadown: ' + JSON.stringify(this.datadown))
        this.option.series[0].data = this.datadown.occupancy
        // this.option.series[1].data = this.datadown.temperature
    }
})
</script>

<style scoped>
.chart {
    width: 100% !important;
    //height: 250px !important;
    /* height: 100vh; */
}
</style>
