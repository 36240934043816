import { render, staticRenderFns } from "./PortalAdmin.vue?vue&type=template&id=4502e914&scoped=true&"
import script from "./PortalAdmin.vue?vue&type=script&lang=js&"
export * from "./PortalAdmin.vue?vue&type=script&lang=js&"
import style0 from "./PortalAdmin.vue?vue&type=style&index=0&id=4502e914&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4502e914",
  null
  
)

export default component.exports