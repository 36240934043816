<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Admin
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">
                    The following page shows all users that have access to the App/Portal, you can use this page to Manage users and access.
                </div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(Lookups) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="6" class="pt-2">
                <BasicCard>
                    <div class="pa-5">
                        <div class="d-flex align-center">
                            <div class="text-h5 secondary--text ml-6">
                                Lookup
                                <v-switch v-model="showDeleted" label="Show Deleted">Show Deleted</v-switch>
                            </div>
                            <v-spacer />
                            <DropdownSimple
                                BackgroundColor="grey lighten-3"
                                v-model="lookupSelected"
                                :items="filteredLookupTypes"
                                :filled="false"
                                label="Select Lookup"
                                :dense="true"
                            />
                        </div>

                        <table width="100%" class="body-2" v-if="lookupSelected !== ''">
                            <tr height="20">
                                <td colspan="4"><v-divider /></td>
                            </tr>
                            <tr class="primary--text">
                                <th align="left" class="pa-2 pl-6">Text</th>
                                <th align="left" class="pa-2 pl-6">Value</th>
                                <th align="left" class="pa-2 pl-6">Filter</th>
                                <th align="left" class="pa-2 pl-6"></th>
                                <th align="right">Action</th>
                            </tr>
                            <tr v-for="item in filteredLookups" :key="item.entityId">
                                <td class="pa-2 pl-6"><v-icon x-small v-if="item.deleted" class="error--text pr-2">icons8-trash</v-icon>{{ item.text }}</td>
                                <td class="pa-2 pl-6">{{ item.value }}</td>
                                <td class="pa-2 pl-6">{{ item.filter }}</td>
                                <td class="pa-2 pl-6"></td>
                                <td align="right" width="100">
                                    <v-btn title="Edit" icon @click="editLookup(item)"><v-icon>icons8-menu-squared-2</v-icon></v-btn>
                                    <v-btn v-if="!item.deleted" title="Delete" icon @click="deleteLookup(item)"><v-icon>icons8-close-window</v-icon></v-btn>
                                    <v-btn v-if="item.deleted" title="Restore" icon @click="restoreLookup(item)"><v-icon>icons8-checked-checkbox</v-icon></v-btn>
                                </td>
                            </tr>
                            <tr height="20">
                                <td colspan="4"><v-divider /></td>
                            </tr>
                            <tr>
                                <td class="pl-6 font-weight-bold primary--text" colspan="4">
                                    <span v-if="lookupNew">New Lookup</span>
                                    <span v-if="!lookupNew">Edit Lookup</span>
                                </td>
                            </tr>
                            <tr height="50">
                                <td><TextField v-model="lookup.text" :filled="false" BackgroundColor="grey lighten-4" label="Text" :clearable="false" /></td>
                                <td><TextField v-model="lookup.value" :filled="false" BackgroundColor="grey lighten-4" label="Value" :clearable="false" /></td>
                                <td><TextField v-model="lookup.filter" :filled="false" BackgroundColor="grey lighten-4" label="Filter" :clearable="false" /></td>
                                <td><TextField v-model="lookup.type" :filled="false" BackgroundColor="grey lighten-4" label="Type" :clearable="false" /></td>
                                <td align="right" width="100">
                                    <v-btn title="Cancel Lookup" icon @click="cancelLookup()"><v-icon>icons8-stop-squared</v-icon></v-btn>
                                    <v-btn title="Save Lookup" icon @click="saveLookup()"><v-icon>icons8-checked-checkbox</v-icon></v-btn>
                                </td>
                            </tr>
                        </table>
                    </div>
                </BasicCard>
            </v-col>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ lookup.text }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteLookup">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
// import BasicCard from "@/components/cards/BasicCard.vue";
import { mapGetters } from 'vuex'
export default {
    name: 'LookupAdmin',
    // components: { BasicCard },
    // data
    data: () => ({
        loading: false,
        drawer: false,
        lookup: {},
        lookupNew: true,
        lookupSelected: '',
        lookups: [],
        filterDrawer: false,
        confirmBox: false,
        showDeleted: false,
        permenantDelete: false,
        itemsTotal: 0,
        validate: {},
        user: {},
        organisations: [],
        orgTypes: [],
        userStatus: [],
        userGroups: [],
        userLevels: [],
        userLanguages: [],
        action: 'Add',
        showInfo: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.showDeleted) {
                query = '*'
            } else {
                query = '@deleted:{false}'
            }
            return query
        },
        itemsFrom() {
            return 0 // (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            return 9999
            // if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
            // 	return this.itemsTotal;
            // } else {
            // 	return this.tableOptions.page * this.tableOptions.itemsPerPage;
            // }
        },
        itemsCount() {
            return 9999
        },
        itemsSortBy() {
            return 'text'
        },
        itemsSortDesc() {
            return 'ASC'
        },
        filteredLookups() {
            // filter by lookupSelected
            if (this.lookupSelected) {
                return this.lookups.filter((item) => item.type === this.lookupSelected)
            } else {
                return []
            }
        },
        filteredLookupTypes() {
            // return unique list of lookup types
            return [...new Set(this.lookups.map((item) => item.type))]
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.getLookups()
            },
            deep: true
        }
    },
    // methods
    methods: {
        // Get Table Data
        async getLookups() {
            try {
                this.loading = true
                // console.log("this.itemsFrom", this.itemsFrom);
                // console.log("this.itemsCount", this.itemsCount);
                // console.log("this.itemsSortBy", this.itemsSortBy);
                // console.log("this.itemsSortDesc", this.itemsSortDesc);
                // console.log("this.filterQuery", this.filterQuery);
                let dataResult = await this.REDIS_searchFor('lookup', this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery)
                this.lookups = dataResult.data.documents
                // console.log("this.lookups", JSON.stringify(this.lookups, null, 2));
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Add User
        async saveLookup() {
            try {
                // console.log('this.lookup', JSON.stringify(this.lookup, null, 2))
                if (this.lookup?.entityId === '' || this.lookup?.entityId === undefined || this.lookup?.entityId === null) {
                    await this.REDIS_create('lookup', this.lookup)
                } else {
                    await this.REDIS_update('lookup', this.lookup.entityId, this.lookup)
                }
                this.getLookups()
                this.lookup = { ...this.$schema.lookup }
                this.MIX_alertBox({ show: true, message: 'Lookup Saved', color: 'success', timeout: '2000' })
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Adding Lookup', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Edit User
        editLookup(lookup) {
            this.lookup = { ...lookup }
            this.lookupNew = false
        },
        // Delete User
        deleteLookup(lookup) {
            this.lookup = lookup
            this.confirmBox = true
        },
        // Cancel Lookup
        cancelLookup() {
            this.lookup = { ...this.$schema.lookup }
            this.lookupNew = true
        },
        // Restore User
        async restoreLookup(lookup) {
            try {
                this.loading = true
                this.lookup = lookup
                this.lookup.deleted = false
                await this.REDIS_update('lookup', this.lookup.entityId, this.lookup)
                this.loading = false
                this.MIX_alertBox({ show: true, message: 'Lookup Restored', color: 'success', timeout: '2000' })
                this.getLookups()
                this.lookup = { ...this.$schema.lookup }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Restoring', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Confirm Delete User
        async confirmDeleteLookup() {
            try {
                this.loading = true
                this.lookup.deleted = true
                // console.log('this.lookup', JSON.stringify(this.lookup, null, 2))
                await this.REDIS_delete('lookup', this.lookup.entityId, this.lookup)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Lookup Deleted', color: 'success', timeout: '2000' })
                this.getLookups()
                this.lookup = { ...this.$schema.lookup }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Deleting Lookup', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Save User
        async saveUser() {
            try {
                this.loading = true
                this.updateOrgName()
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId
                let validationErrors = null
                validationErrors = 0
                if (this.user.firstName === '') {
                    this.validate.firstName = 'First Name is required'
                    validationErrors++
                }
                if (this.user.lastName === '') {
                    this.validate.lastName = 'Last Name is required'
                    validationErrors++
                }
                if (this.user.email === '') {
                    this.validate.email = 'Email is required'
                    validationErrors++
                }
                if (this.user.telephone === '') {
                    this.validate.telephone = 'Telephone is required'
                    validationErrors++
                }
                if (this.user.status === '') {
                    this.validate.status = 'Status is required'
                    validationErrors++
                }
                // loop through the this.user object and trim all the values
                Object.keys(this.user).forEach((key) => {
                    if (typeof this.user[key] === 'string') {
                        this.user[key] = this.user[key].trim()
                        // remove any . from the end of the string
                        if (this.user[key].slice(-1) === '.') {
                            this.user[key] = this.user[key].slice(0, -1)
                        }
                    }
                })
                // If no validation errors
                if (this.action === 'Add' && validationErrors === 0) {
                    this.user.createdUserId = this.GET_currentUserProfile.entityId
                    this.user.createdOrgId = this.GET_currentUserProfile.orgId
                    this.user.createdDateTime = this.MIX_formatDateTimeNow('toISOString')
                    await this.REDIS_create('user', this.user)
                    this.loading = false
                    this.user = { ...this.$schema.user }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getLookups()
                } else if (this.action === 'Edit') {
                    await this.REDIS_update('user', this.user.entityId, this.user)
                    this.loading = false
                    this.user = { ...this.$schema.user }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getLookups()
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            this.lookup = { ...this.$schema.lookup }
            this.getLookups()
            this.loading = false
        }
    },
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
