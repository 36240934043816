<template>
    <div style="background-color: white" class="d-flex justify-center">
        <div class="noprint"><v-btn type="primary" @click="print()">Print</v-btn></div>
        <!-- Header -->
        <header>
            <!-- INSERT HEADER CONTENT HERE -->
            <div class="d-flex" style="width: 700px !important">
                <div class="ml-2">
                    <div class="text-h7 primary--text">Automate Report</div>
                    <div class="text-h7 font-weight-light grey--text">
                        {{ site?.orgName }} | <strong>{{ site?.siteName }}</strong>
                    </div>
                    <div class="text-body-2 font-weight-normal grey--text">
                        {{ site?.addressLine1 }} {{ site?.addressLine2 }} {{ site?.town }} {{ site?.county }} {{ site?.postcode }}
                    </div>
                </div>
                <v-spacer />
                <div class=""><img src="@/assets/gaia_blue.png" height="70" alt="AutomateLogo" /></div>
            </div>
        </header>
        <!-- Page Body -->
        <table width="700">
            <thead>
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Load (W)</div>
                        <LineChart
                            :loading="loading"
                            :datadown="loadSeries"
                            :height="400"
                            :key="chartKey"
                            xaxislabel="Time"
                            yaxislabel="Load"
                            yaxisunit="W"
                            :print="true"
                        ></LineChart>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Hourly Usage (kWh)</div>
                        <BarChart2
                            :loading="loading"
                            :datadown="usageRange"
                            :height="400"
                            class=""
                            xaxislabel="Time"
                            yaxislabel="Usage"
                            yaxisunit="kWh"
                            :key="chartKey"
                            :print="true"
                        ></BarChart2>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Usage by Circuit Type (kWh)</div>
                        <PieChart :loading="loading" :datadown="computedUsageByType" :height="400" :key="chartKey" unit="kWh"></PieChart>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Total Usage (kWh)</div>

                        <LineChart
                            :loading="loading"
                            :datadown="usageTimeseries"
                            :height="400"
                            class=""
                            xaxislabel="Time"
                            yaxislabel="Usage"
                            yaxisunit="kWh"
                            :key="chartKey"
                            :print="true"
                        ></LineChart>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Hourly Usage (kWh) and Temperature (°C)</div>

                        <BarChart
                            :loading="loading"
                            :xaxisdata="usageByHourXAxis"
                            :yaxisdata="usageByHourYAxis"
                            :y2axisdata="tempByHourYAxis"
                            :datadown="dataByHour"
                            :height="425"
                            class=""
                            xaxislabel="Time"
                            yaxislabel="Usage"
                            y2axislabel="Temperature"
                            yaxisunit="kWh"
                            y2axisunit="°C"
                            :key="chartKey"
                            :print="true"
                        ></BarChart>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Cabin Group Total Usage to {{ $dayjs(endDateUnix).format('DD-MMM-YY') }}</div>
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="circuit in computedUsageByGroup" :key="circuit.id">
                                <td class="text-h7">{{ circuit.name }}</td>
                                <td class="text-h7" align="right">{{ circuit?.value.toFixed(2) }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Top 10 Circuits by Usage</div>
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="left">Group</th>
                                    <th align="left">Type</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="circuit in computedUsageByCircuit" :key="circuit.id">
                                <td class="body-2">{{ circuit.name }}</td>
                                <td class="body-2">{{ circuit.group }}</td>
                                <td class="text-caption font-weight-bold">
                                    <v-chip label small class="grey lighten-2">{{ circuit?.type?.toUpperCase() }}</v-chip>
                                </td>
                                <td align="right">{{ circuit?.value.toFixed(2) }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!--                Savings-->
                <tr class="pagebreak" style="height: 100px">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text pb-8">Savings Breakdown</div>
                        <v-row class="grey lighten-4 rounded">
                            <!-- Energy Saved -->
                            <v-col cols="6">
                                <DashCard
                                    title="Energy Saved"
                                    icon="icons8-speed"
                                    :value="usageSaving_kWh < 0 ? 0 : usageSaving_kWh"
                                    unit="kWh"
                                    description="Power Saved"
                                    :loading="loading"
                                />
                            </v-col>
                            <!-- Diesel Saved -->
                            <v-col cols="6" v-if="site?.type === 'Diesel'">
                                <DashCard
                                    title="Diesel Saved"
                                    icon="icons8-engine"
                                    :value="usageSaving_kWh < 0 ? 0 : usageSaving_kWh"
                                    unit="ltr"
                                    description="Diesel Used"
                                    :loading="loading"
                                />
                            </v-col>
                            <!-- CO2 Saved -->
                            <v-col cols="6">
                                <DashCard
                                    title="CO2 Saved"
                                    icon="icons8-co2"
                                    :value="usageSaving_CO2 < 0 ? 0 : usageSaving_CO2"
                                    unit="kg"
                                    description="CO2 Reduction"
                                    :loading="loading"
                                />
                            </v-col>
                            <!-- Cost Saving -->
                            <v-col cols="6">
                                <DashCard
                                    title="Cost Saving"
                                    icon="icons8-sales-performance"
                                    :value="usageSaving_Total < 0 ? 0 : usageSaving_Total"
                                    unit="£"
                                    description="Saved"
                                    :loading="loading"
                                />
                            </v-col>
                            <!-- Net Saving -->
                            <v-col cols="6">
                                <DashCard
                                    title="Net Saving"
                                    icon="icons8-sales-performance"
                                    :value="usageSaving_NET < 0 ? 0 : usageSaving_NET"
                                    unit="£"
                                    description="Saving"
                                    :loading="loading"
                                />
                            </v-col>
                        </v-row>
                    </td>
                </tr>
                <tr class="pagebreak">
                    <td align="center">
                        <div class="text-h5 font-weight-bold grey--text">Cabin Total Usage to {{ $dayjs(endDateUnix).format('DD-MMM-YY') }}</div>
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="(location, key) in computedBox" :key="key">
                                <td style="border-bottom: 1px solid lightgrey !important" class="text-h7">{{ key }} - {{ location }}</td>
                                <td style="border-bottom: 1px solid lightgrey !important" class="text-h7" align="right">{{ getUsageByBox(key) }}</td>
                            </tr>
                        </table>
                        <br /><br /><br />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <!-- Footer -->
        <footer>
            <!-- INSERT FOOTER CONTENT HERE -->
        </footer>
    </div>
</template>

<script>
export default {
    name: 'SiteDashboardView',
    data(vm) {
        return {
            day: true,
            night: true,
            showHidden: 0,
            site: null,
            loading: false,
            chartKey: 0,
            startDate: null,
            endDate: null,
            initStartDate: vm.$dayjs().subtract(8, 'day').format('YYYY-MM-DD'),
            initEndDate: vm.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            startDateUnix: 0,
            endDateUnix: 0,
            loadSeries: [],
            usageTimeseries: [],
            usage_kWh: {
                firstValue: 0,
                lastValue: 0
            },
            stat: {
                powerUsage: 11,
                carbonFootprint: 5,
                totalGridCost: 10,
                powerSaved: 603,
                carbonFootprintGrid: 210,
                totalCostSavingGrid: 804
            },
            totalWLoadX: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
            totalWLoadY: [200, 100, 1200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            totalWUsageX: ['Usage without Automate', 'Usage with Automate'],
            totalWUsageY: [900, 400],
            conversion: {
                kWhPerLitre: '2.3', // per day
                kWhToDiesel: '0.4347826087',
                kWhToGrid: '0.33',
                //fuelCostPerLitre: "0.65", // £ cost per litre
                dieselLtr: '152', // litres per day
                co2Kg: '2.68',
                co2KgHvo: '0.195',
                co2KgGrid: '0.309',
                // New Values
                kwh_to_diesel: '0.4347826087',
                diesel_to_co2: '2.68',
                grid_kwh_to_co2: '0.309',
                hvo_kwh_to_co2: '0.195'
            },
            // Estimate
            estimateTotal_kWh: 0,
            estimateTotal_Diesel: 0,
            estimateTotal_CO2: 0,
            estimateTotal_Cost: 0,
            estimateRental_Cost: 0,
            // Usage
            usageTotal_kWh: 0,
            usageTotal_Diesel: 0,
            usageTotal_CO2: 0,
            usageTotal_Cost: 0,
            // usageTotal_Rental: 0,
            // estimateRental_Cost: 0,
            // Savings
            usageSaving_kWh: 0,
            usageSaving_Diesel: 0,
            usageSaving_CO2: 0,
            usageSaving_Total: 0,
            usageSaving_NET: 0,
            circuits: [],
            loadCircuit: '',
            usageCircuit: '',
            usageRangeCircuit: '',
            hourlyUsageCircuit: '',
            usageByCircuit: [],
            usageRange: [],
            usageByHour: [],
            dataByHour: {
                usage: [],
                temperature: []
            },
            usageByHourYAxis: [],
            usageByHourXAxis: [],
            tempByHour: [],
            tempByHourYAxis: [],
            tempByHourXAxis: []
            // energyUsageMap: {
            //     0: 3,
            //     1: 3,
            //     2: 3,
            //     3: 3,
            //     4: 3,
            //     5: 3,
            //     6: 4,
            //     7: 5,
            //     8: 8,
            //     9: 10,
            //     10: 11,
            //     11: 12,
            //     12: 12
            // }
        }
    },
    computed: {
        computedBox() {
            let cabinNames = this.site.cabinNames
            let box = {}
            if (cabinNames) {
                box = JSON.parse(cabinNames)
            }
            return box
        },
        computedComparisonEnergyMap() {
            let comparisonEnergyMap = this.site.comparisonEnergyMap
            let map = {}
            if (comparisonEnergyMap) {
                map = JSON.parse(comparisonEnergyMap)
            }
            // console.log('map: ' + JSON.stringify(map, null, 2))
            return map
        },
        computedUsageByCircuit() {
            // sort by value
            return this.usageByCircuit
                .filter((y) => y.circuitId !== 'total')
                .map((x) => {
                    return {
                        name: this.lookupCircuit(x.circuitId)?.name,
                        group: this.lookupCircuit(x.circuitId)?.group,
                        type: this.lookupCircuit(x.circuitId)?.type,
                        boxLetter: this.lookupCircuit(x.circuitId)?.boxLetter,
                        value: parseFloat(x.difference)
                    }
                })
                .sort((a, b) => {
                    return b.difference - a.difference
                })
                .splice(0, 10)
        },
        computedTotalUsageByCircuit() {
            // sort by value
            return this.usageByCircuit
                .filter((y) => y.circuitId !== 'total')
                .map((x) => {
                    return {
                        name: this.lookupCircuit(x.circuitId)?.name,
                        group: this.lookupCircuit(x.circuitId)?.group,
                        type: this.lookupCircuit(x.circuitId)?.type,
                        boxLetter: this.lookupCircuit(x.circuitId)?.boxLetter,
                        value: parseFloat(x.lastValue)
                    }
                })
                .sort((a, b) => {
                    return b.lastValue - a.lastValue
                })
            // .splice(0, 10)
        },
        // Usage by type
        computedUsageByType() {
            const sums = this.computedUsageByCircuit.reduce((acc, item) => {
                acc[item.type] = (acc[item.type] || 0) + item.value
                return acc
            }, {})

            return Object.entries(sums).map(([type, value]) => {
                return { name: type.toUpperCase(), text: type.toUpperCase(), value: value }
            })
        },
        // Usage By Group
        computedUsageByGroup() {
            let usageByGroup = this.computedTotalUsageByCircuit.reduce((acc, item) => {
                acc[item.group] = (acc[item.group] || 0) + item.value
                return acc
            }, {})
            return Object.entries(usageByGroup).map(([group, value]) => {
                return { name: group, text: group, value: value }
            })
        },
        // Usage By Box Letter
        computedUsageByBoxLetter() {
            let usageByBoxLetter = this.computedTotalUsageByCircuit.reduce((acc, item) => {
                acc[item.boxLetter] = (acc[item.boxLetter] || 0) + item.value
                return acc
            }, {})
            return Object.entries(usageByBoxLetter).map(([boxLetter, value]) => {
                return { name: boxLetter, text: boxLetter, value: value }
            })
        },
        computedUsageByCircuitByHour() {
            return this.usageByCircuit
        },
        computedAverageLoad() {
            return parseFloat((this.loadSeries.reduce((a, b) => a + b[1], 0) / this.loadSeries.length / 1000).toFixed(2))
        },
        computedDays() {
            return parseInt(this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'day'))
        }
        /*
        // startDateUnix() {
        //   if (this.startDate) {
        //     return this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf();
        //   } else {
        //     return this.$dayjs(this.initStartDate).tz('Europe/London').startOf('day').valueOf();
        //   }
        // },
        // endDateUnix() {
        //   if (this.endDate) {
        //     return this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf();
        //   } else {
        //     return this.$dayjs(this.initEndDate).tz('Europe/London').endOf('day').valueOf();
        //   }
        // },
        // Calculate the Usage Total in kWh by taking the last value kwh from teh first value kwh
        // usageTotal_kWh() {
        //   return (parseFloat(this.usage_kWh.lastValue) - parseFloat(this.usage_kWh.firstValue)).toFixed(2);
        // },
        // Calculate the Usage Total in Diesel by taking the Usage Total in kWh and multiplying by the kWh to Diesel conversion
        // usageTotal_Diesel() {
        //   return (parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.kwh_to_diesel)).toFixed(2);
        // },
        // Calculate the Usage Total in CO2 by taking the Diesel Usage Total in kWh and multiplying by the Diesel to CO2 conversion
        // usageTotal_CO2() {
        //   return (parseFloat(this.usageTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2);
        // },
        // Calculate the Total Cost by Multiplying the Diesel Total Usage by Cost Per Disel frmo Site
        // usageTotal_Cost() {
        //   return (parseFloat(this.usageTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2);
        // }
        *
         */
    },
    methods: {
        print() {
            window.print()
        },
        // Get Site Details
        getSite: async function () {
            const siteID = this.$route.params.id
            await this.REDIS_read('site', siteID).then((result) => {
                // console.log('site: ' + JSON.stringify(result.data, null, 2))
                this.site = result.data
                // TODO - Temporary Additions
                // this.site.mode = 'peakshaving'
                // this.site.costPerkWh = 0.2847 // per kWh
                // this.site.costStandingCharge = 2.63 // per day
                // this.site.rentalCost = 1.0 // per day
                // this.site.type = 'Diesel'
                // this.site.costPerLitre = 0.65 // check this value
            })
        },
        // Get Load Over Time
        async getLoadOverTime() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = ''
            if (this.loadCircuit === '') {
                timeseries = `ts:${this.site.siteId}:load:total`
            } else {
                timeseries = `ts:${this.site.siteId}:load:${this.loadCircuit}`
            }
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: null,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    // Day / Night
                    if (this.day && this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            return [item.timestamp, item.value]
                        })
                    } else if (this.day && !this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour >= 6 && hour <= 18 ? [item.timestamp, item.value] : [item.timestamp, 0]
                        })
                    } else if (!this.day && this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour < 6 || hour > 18 ? [item.timestamp, item.value] : [item.timestamp, 0]
                        })
                    } else {
                        this.loadSeries = []
                    }

                    // console.log('loadSeries: ' + JSON.stringify(this.loadSeries, null, 2))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        // Get Usage Over Time
        async getUsageOverTime() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = ''
            if (this.usageCircuit === '') {
                timeseries = `ts:${this.site.siteId}:usage:total`
            } else {
                timeseries = `ts:${this.site.siteId}:usage:${this.usageCircuit}`
            }
            // console.log('timeseries: ' + timeseries)s
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: null,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    // Day / Night
                    if (this.day && this.night) {
                        this.usageTimeseries = res.data.data
                    } else if (this.day && !this.night) {
                        this.usageTimeseries = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                        })
                    } else if (!this.day && this.night) {
                        this.usageTimeseries = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                        })
                    } else {
                        this.usageTimeseries = []
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getUsageTotal() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/usagetotal`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: this.day,
                    night: this.night,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    this.usageTotal_kWh = parseFloat(res.data.data.toFixed(2))
                    // console.log('total usage = ' + JSON.stringify(res.data.data, null, 2))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getUsageFirstValueByRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/firstvaluebyrange`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    this.usage_kWh.firstValue = res.data.data.value
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getUsageLastValueByRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/lastvaluebyrange`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    this.usage_kWh.lastValue = res.data.data.value
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // Get the Usage Total By Range
        async getUsageRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let t = this
            var usageRangeCircuit
            // const thirtyMinutesInMilliseconds = 30 * 60 * 1000
            if (this.usageRangeCircuit === '') {
                usageRangeCircuit = 'total'
            } else {
                usageRangeCircuit = this.usageRangeCircuit
            }
            await this.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: `ts:${t.site.siteId}:usage:${usageRangeCircuit}`,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    // console.log('res data: ' + JSON.stringify(res.data, null, 2))
                    // Day / Night
                    let data = []
                    if (this.day && this.night) {
                        data = res.data.data
                    } else if (this.day && !this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour >= 5 && hour < 18
                        })
                    } else if (!this.day && this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            // Keep all night-time data
                            return hour < 6 || hour >= 17
                        })
                    } else {
                        data = []
                    }

                    const thirtyMinutesInMilliseconds = 1800000
                    t.usageRange = data
                        .slice(1)
                        .map((item, index) => [item.timestamp + thirtyMinutesInMilliseconds, parseFloat(item.value - data[index].value).toFixed(2)])

                    // Remove the 5 AM data point for display if in day mode
                    if (this.day && !this.night) {
                        t.usageRange = t.usageRange.filter(
                            (item) =>
                                this.$dayjs(item[0] - thirtyMinutesInMilliseconds)
                                    .tz('Europe/London')
                                    .hour() !== 5
                        )
                    }

                    // Remove the 5 PM data point for display if in day mode
                    if (!this.day && this.night) {
                        t.usageRange = t.usageRange.filter(
                            (item) =>
                                this.$dayjs(item[0] - thirtyMinutesInMilliseconds)
                                    .tz('Europe/London')
                                    .hour() !== 17
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getUsageByCircuit() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/mrange/difference`
            let t = this
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    labels: ['type=usage', `siteId=${t.site.siteId}`],
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    // console.log('res data: ' + JSON.stringify(res.data.data, null, 2))
                    // Day / Night
                    this.usageByCircuit = res.data.data
                    // console.log('usageByCircuit: ' + JSON.stringify(t.usageByCircuit.length, null, 2))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async calculate() {
            this.loading = true
            // console.log('startDate: ' + this.startDate)
            // console.log('endDate: ' + this.endDate)
            if (this.startDate === null) {
                this.startDate = this.initStartDate
            }
            if (this.endDate === null) {
                this.endDate = this.initEndDate
            }
            console.log('startDate: ' + this.startDate)
            console.log('endDate: ' + this.endDate)
            this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
            this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
            console.log('startDateUnix: ' + this.startDateUnix)
            console.log('endDateUnix: ' + this.endDateUnix)
            // Get Data
            await this.getUsageTotal()
            await this.getLoadOverTime()
            await this.getUsageOverTime()
            await this.getUsageFirstValueByRange()
            await this.getUsageLastValueByRange()
            await this.getUsageByCircuit()
            await this.getUsageRange()
            await this.getUsageByHour()
            await this.getTempByHour()
            this.dataByHour = {
                usage: this.usageByHour,
                temperature: this.tempByHour
            }
            // console.log('site type: ' + this.site.type)

            // Estimate Calculation

            // kwh_to_diesel: '0.4347826087',
            //     diesel_to_co2: '2.68',
            //     grid_kwh_to_co2: '0.309',
            //     hvo_kwh_to_co2: '0.195'

            switch (this.site.type) {
                case 'Diesel':
                    // Estimate Calculation
                    this.estimateTotal_Diesel = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.kWhToDiesel)).toFixed(2))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat((parseFloat(this.estimateTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2))
                    // Usage Calculation
                    this.usageTotal_Diesel = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.kWhToDiesel)).toFixed(2))
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat((parseFloat(this.usageTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2))
                    break
                case 'Grid':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(this.tempByHour, this.computedComparisonEnergyMap))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat(
                        (parseFloat(this.estimateTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    // Usage Calculation
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat(
                        (parseFloat(this.usageTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    break
                case 'HVO':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(this.tempByHour, this.computedComparisonEnergyMap))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.hvo_kwh_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat(
                        (parseFloat(this.estimateTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    // Usage Calculation
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.hvo_kwh_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat(
                        (parseFloat(this.usageTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    break
            }

            // Estimate Rental Cost
            this.estimateRental_Cost = parseFloat(this.computedDays.toFixed(2) * parseFloat(this.site.rentalCost.toFixed(2)))

            // Calulate Savings
            switch (this.site.mode) {
                case 'monitoring':
                    this.usageSaving_kWh = 0
                    this.usageSaving_Diesel = 0
                    this.usageSaving_CO2 = 0
                    this.usageSaving_Total = 0
                    this.usageSaving_NET = 0
                    break
                default:
                    this.usageSaving_kWh = parseFloat((parseFloat(this.estimateTotal_kWh) - parseFloat(this.usageTotal_kWh)).toFixed(2))
                    this.usageSaving_Diesel = parseFloat((parseFloat(this.estimateTotal_Diesel) - parseFloat(this.usageTotal_Diesel)).toFixed(2))
                    this.usageSaving_CO2 = parseFloat((parseFloat(this.estimateTotal_CO2) - parseFloat(this.usageTotal_CO2)).toFixed(2))
                    this.usageSaving_Total = parseFloat((parseFloat(this.estimateTotal_Cost) - parseFloat(this.usageTotal_Cost)).toFixed(2))
                    this.usageSaving_NET = parseFloat((parseFloat(this.usageSaving_Total) - parseFloat(this.estimateRental_Cost)).toFixed(2))
                    break
            }
            this.chartKey++
            this.loading = false
        },
        // Get Circuits
        async getCircuits() {
            let url = `${process.env.VUE_APP_API_URL}public/circuits`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    siteId: this.site.siteId
                })
                .then((res) => {
                    this.circuits = res.data.data.documents.map((x) => x.value)
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        // Lookup Circuit
        lookupCircuit(circuitId) {
            return this.circuits.find((x) => x.id === circuitId)
        },
        lookupCircuitName(circuitId) {
            let circuit = this.circuits.find((x) => x.id === circuitId)
            if (circuit !== undefined) {
                return circuit.name
            } else {
                return 'Unknown'
            }
        },
        async getUsageByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = ''
            if (this.hourlyUsageCircuit === '') {
                timeseries = `ts:${this.site.siteId}:usage:total`
            } else {
                timeseries = `ts:${this.site.siteId}:usage:${this.hourlyUsageCircuit}`
            }
            const thirtyMinutesInMilliseconds = 30 * 60 * 1000
            // let timeseries = `ts:${t.site.siteId}:usage:total`
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    const dataArray = []
                    // const xAxis = []
                    const yAxis = []
                    let data = []
                    // Day / Night
                    if (this.day && this.night) {
                        data = res.data.data
                    } else if (this.day && !this.night) {
                        data = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                        })
                    } else if (!this.day && this.night) {
                        data = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                        })
                    } else {
                        data = []
                    }
                    // console.log('res.data.data: ' + JSON.stringify(res.data.data, null, 2))
                    for (let i = 0; i < data.length - 1; i++) {
                        const currentTimestamp = data[i][0]
                        const currentValue = data[i][1]
                        const nextValue = data[i + 1][1]
                        dataArray.push([currentTimestamp + thirtyMinutesInMilliseconds, parseFloat((nextValue - currentValue).toFixed(2))])
                        // xAxis.push(currentTimestamp)
                        yAxis.push(parseFloat((nextValue - currentValue).toFixed(2)))
                    }
                    t.usageByHour = dataArray
                    t.dataByHour = {
                        usage: this.usageByHour,
                        temperature: this.tempByHour
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getTempByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${t.site.siteId}:temperature:total`
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    let data = []
                    // // Day / Night
                    // if (this.day && this.night) {
                    data = res.data.data
                    // } else if (this.day && !this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                    //     })
                    // } else if (!this.day && this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                    //     })
                    // } else {
                    //     data = []
                    // }
                    t.tempByHour = data.map((item) => [item[0], parseInt(item[1])])
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async calculateEstimatedEnergyUsage(temperatureData, energyUsageMap) {
            return new Promise((resolve, reject) => {
                try {
                    let totalEnergyUsage = 0
                    // console.log('temperatureData: ' + JSON.stringify(temperatureData, null, 2))
                    // console.log('energyUsageMap: ' + JSON.stringify(energyUsageMap, null, 2))
                    for (const entry of temperatureData) {
                        // Assuming the temperature is rounded to the nearest whole number for lookup
                        let temperature = Math.round(entry[1])
                        if (temperature < 0) {
                            temperature = 0
                        }
                        if (temperature > 19) {
                            temperature = 19
                        }
                        const energyUsage = energyUsageMap[temperature]
                        // console.log('temperature: ' + temperature)
                        if (energyUsage !== undefined) {
                            totalEnergyUsage += energyUsage
                        } else {
                            // Handle the case where there's no matching temperature in energyUsageMap
                            console.warn(`No energy data for temperature: ${temperature}`)
                        }
                    }
                    // console.log('totalEnergyUsage: ' + totalEnergyUsage)
                    resolve(totalEnergyUsage)
                } catch (error) {
                    reject(error)
                }
            })
        },
        getUsageByBox(box) {
            if (box) {
                let boxData = this.computedUsageByBoxLetter.find((x) => x.name === box)
                if (boxData) {
                    return parseFloat(boxData.value.toFixed(2))
                } else {
                    return 0
                }
            }
        }
    },
    watch: {
        /*
        day: {
            handler: function () {
                this.calculate()
            },
            deep: true
        },
        night: {
            handler: function () {
                this.calculate()
            },
            deep: true
        },*/
        loadCircuit: {
            handler: function () {
                this.getLoadOverTime()
            },
            deep: true
        },
        usageCircuit: {
            handler: function () {
                this.getUsageOverTime()
            },
            deep: true
        },
        usageRangeCircuit: {
            handler: function () {
                this.getUsageRange()
            },
            deep: true
        },
        hourlyUsageCircuit: {
            handler: function () {
                // console.log('hourlyUsageCircuit watched')
                this.getUsageByHour()
            },
            deep: true
        }
    },
    async created() {
        this.startDate = this.$route.query.startDate
        this.endDate = this.$route.query.endDate

        this.loading = true
        await this.getSite()
        // console.log(this.computedComparisonEnergyMap)
        await this.calculate()
        await this.getCircuits()
        // await this.getUsageByCircuit()
        // await this.getUsageRange()
        // await this.getUsageByHour()
        // await this.getTempByHour()
        //
        // this.energyUsageMap = {
        //     0: 3,
        //     1: 3,
        //     2: 3,
        //     3: 3,
        //     4: 3,
        //     5: 3,
        //     6: 4,
        //     7: 5,
        //     8: 8,
        //     9: 10,
        //     10: 11,
        //     11: 12,
        //     12: 12
        // }
        //
        // this.estimateTotal_kWh = await this.calculateEstimatedEnergyUsage(this.tempByHour, this.energyUsageMap)
        //
        // this.dataByHour = {
        //     usage: this.usageByHour,
        //     temperature: this.tempByHour
        // }
        // console.log('computedUsageByCircuit = ' + JSON.stringify(this.computedUsageByCircuit, null, 2))
        this.loading = false
    }
}
</script>

<style scoped>
header,
.header-space {
    background-color: #ffffff;
    height: 100px;
    font-weight: bold;
    width: 100%;
    padding-bottom: 15pt;
}
footer,
.footer-space {
    background-color: #ffffff;
    height: 100px;
    font-weight: bold;
    width: 100%;
    padding-top: 15pt;
}

.noprint {
    z-index: 999 !important;
    position: fixed;
    //left: 10px;
    //top: 10px;
}

header {
    position: fixed;
    top: 0;
}

footer {
    position: fixed;
    bottom: 0;
}

@media print {
    .pagebreak {
        page-break-before: always;
        break-before: always;
    }
}

/*
@media print {
    table {
        width: 90%;
        max-width: 1000px;
        margin: auto;
    }
}
 */
</style>
