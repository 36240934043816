<template>
    <div>
        <div v-if="loading" :style="`height: ${height}px !important;`" class="d-flex align-center justify-center flex-column">
            <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
            <div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
        </div>
        <div v-else>
            <v-chart class="chart" :option="option" :style="`height: ${height}px !important;`" />
        </div>
    </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { defineComponent, ref } from 'vue'
import dayjs from 'dayjs'

use([GridComponent, CanvasRenderer, BarChart])

export default defineComponent({
    name: 'HelloWorld',
    components: {
        VChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        datadown: {
            type: Array,
            default: () => []
        },
        seriesdata: {
            type: Array,
            default: () => []
        },
        xaxisdata: {
            type: Array,
            default: () => []
        },
        yaxisdata: {
            type: Array,
            default: () => []
        },
        yaxisunit: {
            type: String,
            default: ''
        },
        y2axisdata: {
            type: Array,
            default: () => []
        },
        chartTitle: {
            type: String,
            default: 'AutomateChart'
        },
        xaxislabel: {
            type: String,
            default: ''
        },
        yaxislabel: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: 500
        },
        print: {
            type: Boolean,
            default: false
        }
    },
    provide: {
        [THEME_KEY]: 'light'
    },
    computed: {
        xAxisSeries() {
            return this.datadown.map((item) => item.timestamp)
        },
        yAxisSeries() {
            return this.datadown.map((item) => item.value)
        }
    },
    setup(vm) {
        const option = ref({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%']
                },
                formatter: function (params) {
                    // Assuming the date is the first parameter
                    const date = params[0].data[0]
                    var formattedDate = dayjs(date).format('DD-MMM-YY HH:mm')
                    return `<span class='font-weight-bold primary--text'>${vm.yaxislabel}: </span>
${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[0].data[1])}
                        ${vm.yaxisunit} <br/>
                        <span class='font-weight-normal caption'>${formattedDate}</span><br/>`
                }
            },
            grid: {
                containLabel: true,
                left: 50,
                right: 50,
                top: 40,
                bottom: 70
            },
            toolbox: {
                show: !vm.print,
                feature: {
                    dataView: {
                        show: true,
                        title: 'data view'

                    },
                    dataZoom: {
                        yAxisIndex: false
                    },
                    saveAsImage: {
                        pixelRatio: 2
                    }
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: !vm.print,
                    xAxisIndex: [0],
                    start: 1,
                    end: 100,
                    bottom: 30
                }
            ],
            xAxis: {
                name: vm.xaxislabel,
                type: 'time',
                splitLine: {
                    show: false
                },
                // boundaryGap: false,
                nameLocation: 'middle', // Position of the title
                nameGap: 70, // Gap between the axis and the title
                // data: vm.mydates,
                axisLabel: {
                    fontSize: 14
                }
            },
            yAxis: [
                {
                    name: vm.yaxislabel, // Title Value
                    type: 'value', // Axis type
                    nameLocation: 'middle', // Position of the title
                    nameGap: 40, // Gap between the axis and the title
                    nameRotate: 90, // Rotate the title (optional)
                    // boundaryGap: [0, '100%'],
                    axisLabel: {
                        fontSize: 14
                    }
                }
            ],

            // xAxis: {
            //     // name: vm.xaxislabel,
            //     data: vm.xAxisSeries,
            //     type: 'datetime'
            // },
            series: [
                {
                    data: vm.datadown.map(function (item) {
                        return {
                            name: new Date(item[0]).toLocaleString(),
                            value: [item[0], item[1]]
                        }
                    }),
                    type: 'bar',
                    showBackground: true,
                    barWidth: '95%', // Adjust this value as needed
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            ]
        })

        return { option }
    },
    watch: {
        datadown: {
            handler: function (val) {
                this.option.series[0].data = val
            },
            deep: true
        }
    },
    created() {
        this.option.series[0].data = this.datadown
    }
})
</script>

<style scoped>
.chart {
    width: 100% !important;
    /* height: 100vh; */
}
</style>
