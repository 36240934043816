<template>
  <div class="home full-width full-height d-flex align-center justify-center">
	<div class="text-center">
		Your account is currently Pending<br /><br />
		<AppButton class="primary mr-3" @click.native="checkStatus()">Check Status</AppButton>
		<AppButton class="primary" @click.native="FIREBASE_logout()">Log Out</AppButton>

	</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	// TODO - Need to check database every 5 minutes to see if account is approved
	computed: {
		...mapGetters({
			GET_currentUserAuth: "GET_currentUserAuth",
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
		}),
	},
	methods: {
		async checkStatus() {
			let user = await this.REDIS_read("user", this.GET_currentUserAuth.uid);
			if (user.data?.status === "Approved") {
				this.MIX_go("/dashboard");
			} else {
				this.MIX_alertBox({ show: true, message: "Your account is still pending approval", color: "error", timeout: "4000" });
			}
		}
	},
}
</script>