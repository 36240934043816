const config = {
    name: "Automate",
    description: "V3",
    companyName: "Gaia",
    country: "United Kingdom",
    email: "support@vindico.net",
    address: "Gaiagroup UK, Unit 1, Raven Industrial Estate, Garnant, Ammanford, SA18 1NS",
	website: "gaiagroupuk.com"
};

module.exports = {
    config,
};
