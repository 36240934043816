<template>
    <v-btn :height="height" class="rounded-of" :block="block" :icon="icon" :text="text" depressed :class="buttonclass" :disabled="disabled">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        buttonclass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },   
        icon: {
            type: Boolean,
            default: false,
        },
        text : {
            type: Boolean,
            default: false,
        },    
        height: {
            type: String,
            default: "46",
        },

    },
    data() {
        return {
            // innerValue: "",
        };
    },
};
</script>
