<template>
    <BasicCard footerclass="grey lighten-4" class="rounded-lg" style="min-height: 160px !important">
        <template v-slot:header>
            <div class="full-width primary rounded-t-lg d-flex align-center px-4" style="height: 60px !important; overflow: hidden !important">
                <div class="text-body-1 font-weight-bold white--text text-left">{{ title }}</div>
                <v-spacer />
                <v-icon class="primary--text text--lighten-4 text-h4">{{ icon }}</v-icon>
            </div>
        </template>
        <div class="py-3">
            <div v-if="loading" class="d-flex align-center justify-center">
                <v-progress-circular indeterminate color="grey lighten-3" size="70" width="8"></v-progress-circular>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-end text-h4 secondary--text font-weight-bold">
                    <a class="text-h6" v-if="unit === '£'">{{ unit }}</a>
                    {{ value }}
                    <a class="text-h6" v-if="unit !== '£'">{{ unit }}</a>
                </div>
                <div class="d-flex align-center justify-center grey--text">{{ description }}</div>
            </div>
        </div>
    </BasicCard>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: null
        },
        unit: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
value:empty {
    display: none !important;
}
text:empty {
    display: none !important;
}
</style>
