<template>
    <div>
        <div v-if="loading" :style="`height: ${height}px !important;`" class="d-flex align-center justify-center flex-column">
            <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
            <div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
        </div>
        <!-- <pre>{{ datadown[1].value }}</pre> -->
        <div v-else class="mt-10"><v-chart class="chart" :option="option" :loading="loading" /></div>
    </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { ref, defineComponent } from 'vue'
import { TooltipComponent } from 'echarts/components'
import { LegendComponent } from 'echarts/components'
import { PolarComponent } from 'echarts/components'
import { TitleComponent } from 'echarts/components'

use([GridComponent, CanvasRenderer, PieChart, TooltipComponent, LegendComponent, PolarComponent, TitleComponent])

export default defineComponent({
    name: 'PieChart',
    data: (vm) => ({
        updatedData: [],
        option: ref({
            grid: {
                containLabel: true,
                left: 50,
                right: 50,
                top: 50,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    // console.log(JSON.stringify(params, null, 2))

                    return `<span class="font-weight-bold" style="color:${params.color}">${params.name}: </span><br/>
                    <span class="font-weight-bold">${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
                        params.value
                    )}</span> ${vm.unit} (${params.percent}%)`
                }
                // '<span class="font-weight-bold primary--text">{b}</span><br/> ' +
                // new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format('{ c }') +
                // ' ({d}%)'
            },
            legend: {
                bottom: 10,
                left: 'center'
            },
            series: [
                {
                    // name: 'Predicted Vote',
                    // color: [
                    // 	"#DA4C4C",
                    // 	"#CD7F32",
                    // 	"#C0C0C0",
                    // 	"#FDC000",
                    // ],
                    type: 'pie',
                    radius: '65%',
                    center: ['50%', '50%'],
                    // selectedMode: 'single',
                    data: [],
                    emphasis: {
                        itemStyle: {
                            // color: '#c23531',
                            shadowBlur: 10,
                            shadowOffsetX: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        })
    }),
    components: {
        VChart
    },
    watch: {
        datadown: {
            handler: function (newData) {
                this.option.series[0].data = newData
                // this.chart.clear();
                // this.chart.setOption(this.option);
            },
            deep: true
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 500
        },
        seriesdata: {
            type: Array,
            default: () => []
        },
        xaxisdata: {
            type: Array,
            default: () => []
        },
        unit: {
            type: String,
            default: ''
        },
        datadown: {
            type: Array,
            default: () => []
        }
    },
    provide: {
        [THEME_KEY]: 'light'
    },
    created() {
        //DONT FORGET THAT SERIES DATA IS AN ARRAY OF OBJECTS
        this.option.series[0].data = this.datadown
        // console.log('Datadown ' + JSON.stringify(this.datadown))
        // console.log("SeriesData "+this.option.series.name);
        // this.chart.clear();
        // this.chart.setOption(this.option);
    }
})
</script>

<style scoped>
.chart {
    width: 100% !important;
    height: 420px !important;
    //margin-bottom: -40px;
    margin-top: -60px;
    /* height: 100vh; */
}
</style>
