import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'

// Auth
import NotAuthorised from '@/views/NotAuthorised.vue'
import PendingView from '@/views/PendingView.vue'

// Admin Views
import HomeView from '@/views/HomeView.vue'
import UsersAdmin from '@/views/UsersAdmin.vue'
import LookupAdmin from '@/views/LookupAdmin.vue'
import OrganisationAdmin from '@/views/OrganisationAdmin.vue'
import OrganisationView from '@/views/OrganisationView.vue'
import Sites from '@/views/Sites.vue'
import SiteAdmin from '@/views/SiteAdmin.vue'
import SiteView from '@/views/SiteView.vue'
import SiteDashboardView from '@/views/SiteDashboardView.vue'
import PortalsAdmin from '@/views/PortalAdmin.vue'
import PortalView from '@/views/PortalView.vue'
import ProjectView from '@/views/ProjectView.vue'
import ProjectAdmin from '@/views/ProjectAdmin.vue'
import SiteTemps from '@/views/SiteTemps.vue'

import SiteReport from '@/views/SiteReport.vue'
// Import sitedashboard copy
import SiteDashboardViewV2 from '@/views/SiteDashboardViewV2.vue'
import SiteAdminV2 from '@/views/SiteAdminV2.vue'
// import axios from "axios";

// import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from '@/store.js'

Vue.use(VueRouter)

const routes = [
    // All Users
    // Authentication Note Required
    { path: '/login', name: 'Login', component: Login },
    { path: '/notauthorised', name: 'NotAuthorised', component: NotAuthorised },
    { path: '/register', name: 'Register', component: RegisterView },
    // Authentication Required
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },
    { path: '/pending', name: 'PendingView', component: PendingView, meta: { requiresAuth: true, hideNavbar: true } },
    // Project Users
    {
        path: '/users',
        name: 'UsersAdmin',
        component: UsersAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin'], status: ['Approved'] } }
    },
    {
        path: '/admin',
        name: 'LookupAdmin',
        component: LookupAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin'], status: ['Approved'] } }
    },
    {
        path: '/portals',
        name: 'PortalsAdmin',
        component: PortalsAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin'], status: ['Approved'] } }
    },
    {
        path: '/portal/:id',
        name: 'PortalView',
        component: PortalView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin'], status: ['Approved'] } }
    },
    {
        path: '/project/:id',
        name: 'ProjectView',
        component: ProjectView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin'], status: ['Approved'] } }
    },
    {
        path: '/organisation-admin',
        name: 'OrganisationAdmin',
        component: OrganisationAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin'], status: ['Approved'] } }
    },
    {
        path: '/organisation/:id',
        name: 'OrganisationView',
        component: OrganisationView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin'], status: ['Approved'] } }
    },
    {
        path: '/site-admin',
        name: 'SiteAdmin',
        component: SiteAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager'], status: ['Approved'] } }
    },
    {
        path: '/project-admin',
        name: 'ProjectAdmin',
        component: ProjectAdmin,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager'], status: ['Approved'] } }
    },
    {
        path: '/site/:id',
        name: 'SiteView',
        component: SiteView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager'], status: ['Approved'] } }
    },
    {
        path: '/sites',
        name: 'Sites',
        component: Sites,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },
    {
        path: '/site-dashboard/:id',
        name: 'SiteDashboardView',
        component: SiteDashboardView,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },

    {
        path: '/site-temps/:id',
        name: 'SiteTemps',
        component: SiteTemps,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },
    {
        path: '/site-reports/:id',
        name: 'SiteReport',
        component: SiteReport,
        meta: { requiresAuth: true, hideNavbar: true, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },
    // Copy of sites
    
    {
        path: '/site-adminV2',
        name: 'SiteAdminV2',
        component: SiteAdminV2,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager'], status: ['Approved'] } }
    },
    // copy of site dashboard
    {
        path: '/site-dashboardV2/:id',
        name: 'SiteDashboardViewV2',
        component: SiteDashboardViewV2,
        meta: { requiresAuth: true, hideNavbar: false, item: { level: ['SuperAdmin', 'Admin', 'Manager', 'User'], status: ['Approved'] } }
    },

    //TODO: whitelabel login pages
    // {
    // 	path: '/:pathMatch(.*)*', redirect: to => {
    // 		return { path: '/', query: { q: to.params.pathMatch } }
    // 	}
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

const checkLevelAndStatus = (item, userProfile, userAuthenticated) => {
    if (userAuthenticated) {
        if (item.status !== undefined && item.level !== undefined) {
            let checkError = 0
            if (!item.status.includes(userProfile.status)) {
                checkError++
            }
            if (!item.level.includes(userProfile.level)) {
                checkError++
            }
            return checkError !== 0
        } else {
            return true
        }
    }
}

router.beforeEach(async (to, from, next) => {
    try {
        // console.log((to.meta.requiresAuth ? 'requiresAuth' : 'noAuth') + ' ' + to.path )
        // Does route require Auth
        if (to.meta.requiresAuth) {
            // Yes - Route Requires Auth
            // Update Service Worker
            navigator.serviceWorker?.register('/service-worker.js').then((reg) => {
                reg.update()
            })
            // Get User Profile
            let userProfile = store.state.currentUserProfile
            // If User Profile is null then user needs to login
            if (userProfile === null) {
                // Redirect to Login
                next({ name: 'Login' })
            } else {
                if (to.meta.item !== undefined) {
                    // console.log('to.meta.item = ' + JSON.stringify(to.meta.item, null, 2))
                    // console.log('userProfile = ' + JSON.stringify(userProfile, null, 2))
                    // console.log('1')
                    if (['Pending', 'Suspended', 'Archived', 'Rejected'].includes(userProfile.status)) {
                        // console.log('Pending User');
                        next('/pending')
                    } else {
                        // console.log('Not Pending User')
                        // console.log('to.meta.item = ' + JSON.stringify(to.meta.item, null, 2))
                        // console.log('userProfile = ' + JSON.stringify(userProfile, null, 2))
                        if (checkLevelAndStatus(to.meta.item, userProfile, true)) {
                            // console.log('Unauthorised Access');
                            next('/notauthorised')
                        } else {
                            next()
                        }
                    }
                } else {
                    // Move to Next Route
                    next()
                }
            }
        } else {
            // No - Route Does Not Require Auth
            // Move to Next Route
            next()
        }
    } catch (error) {
        console.error('An Error Occured', error)
        next('/notauthorised')
    }
})

export default router
