<template>
    <v-navigation-drawer v-model="showDrawer" app clipped class="pt-5" width="130" touchless>
        <div v-for="(item, index) in MIX_filteredNav('SideNav')" :key="index">
            <v-list-item v-if="MIX_checkLevelAndStatus(item)" @click="MIX_go(item.path)" class="text-center px-5">
                <v-list-item-content>
                    <v-icon class="grey--text text--darken-1 text-h4">{{ item.icon }}</v-icon>
                    <div class="fullwidth text-center text-body-2 grey--text text--darken-1">
                        {{ item.title }}
                    </div>
                </v-list-item-content>
            </v-list-item>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'SideNav',
    data: () => ({
        showDrawer: true
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_currentUserAuth: 'GET_currentUserAuth',
            GET_currentUserProfile: 'GET_currentUserProfile',
            GET_userAuthenticated: 'GET_userAuthenticated'
        })
    },
    watch: {
        GET_showDrawer: function () {
            this.showDrawer = this.GET_showDrawer
        },
        showDrawer: function () {
            this.ACT_showDrawer(this.showDrawer)
        }
    },
    methods: {
        ...mapActions({
            ACT_showDrawer: 'ACT_showDrawer'
        })
        // checkLevelAndStatus(item) {
        // 	if (item.enabled) {
        // 		if (this.GET_userAuthenticated) {
        // 			if (item.status !== undefined && item.level !== undefined) {
        // 				// console.log("status = ' + item.status + ' UserStatus = ' + this.GET_currentUserProfile.status)
        // 				// console.log('level = ' + item.level + ' UserLevel = ' + this.GET_currentUserProfile.level)
        // 				let checkError = 0;
        // 				if (!item.status.includes(this.GET_currentUserProfile.status)) {
        // 					checkError++;
        // 				}
        // 				if (!item.level.includes(this.GET_currentUserProfile.level)) {
        // 					checkError++;
        // 				}
        // 				if (checkError === 0) {
        // 					return false;
        // 				} else {
        // 					return true;
        // 				}
        // 			} else {
        // 				return true;
        // 			}
        // 		}
        // 	} else {
        // 		console.log('item.enabled = ' + item.enabled)
        // 		return true;
        // 	}
        // },
    }
}
</script>
