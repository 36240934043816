<template>
    <v-select
        :label="label"
        :items="filteredItems"
        :item-text="itemtext"
        :item-value="itemvalue"
        :return-object="returnobject"
        :error-messages="validateMessage"
        v-model="innerValue"
        :disabled="disabled"
        filled
        :rounded="rounded"
        class="rounded-of"
        :background-color="BackgroundColor"
        hide-details="auto"
        :menu-props="{ top: false, offsetY: true }"
        :attach="false"
        :height="height"
        :dense="dense"
        :clearable="clearable"
        @click:clear="clear()"
        :multiple="multiple"
        @change="change"
    >
        <!-- <template #[`item`]="{ item }">
            {{ item.text }} <span v-if="item.description" class="grey--text caption pl-3">{{ item.description }}</span>
        </template>
        <template #[`selection`]="{ item }">
            <span class="grey--text caption">{{ item.text }}</span>
        </template> -->
    </v-select>
</template>

<script>
export default {
    name: 'DropdownAdvanced',
    props: {
        rounded: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        value: {},
        label: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        filter: {
            type: String,
            default: ''
        },
        returnobject: {
            type: Boolean,
            default: false
        },
        BackgroundColor: {
            type: String,
            default: 'grey lighten-4'
        },
        itemtext: {
            type: String,
            default: 'text'
        },
        itemvalue: {
            type: String,
            default: 'value'
        },
        validate: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '52'
        },
        dense: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        innerValue: '',
        validateMessage: ''
    }),
    computed: {
        filteredItems() {
            if (this.filter !== '' && this.filterField !== '') {
                return this.items.filter((item) => item.filter === this.filter)
            } else {
                return this.items
            }
        }
    },
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = ''
            }, 1)
        },
        change() {
            this.$emit('change', this.value)
        }
    },
    watch: {
        value: function (value) {
            this.innerValue = value
        },
        innerValue: function (value) {
            this.$emit('input', value)
            this.validateMessage = ''
        },
        validate: function (value) {
            this.validateMessage = value
        }
    },
    mounted() {
        this.innerValue = this.value
        this.validateMessage = this.error
    }
}
</script>
