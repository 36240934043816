// import { mapActions } from "vuex";

const mixin = {
    data: () => ({
        users: [],
        portals: [],
        organisations: [],
        projects: [],
        // LOOKUPS
        lookups: [],
        countries: [],
        orgStatus: [],
        siteStatus: [],
        siteTypes: [],
        products: []
    }),
    methods: {
        MIX_indexToLetters(index) {
            let letters = ''
            index += 1 // Adjusting because A is represented by 1 instead of 0

            while (index > 0) {
                let remainder = (index - 1) % 26
                letters = String.fromCharCode(65 + remainder) + letters
                index = Math.floor((index - 1) / 26)
            }

            return letters
        },
        // Get Users
        async MIX_getUsers() {
            let userResult = await this.REDIS_searchFor('user', '', '', '', '', '@deleted:{false}')
            let users = userResult.data.documents
            this.users = users.map((user) => {
                return {
                    ...user,
                    userName: user.firstName + ' ' + user.lastName,
                    userDetails: user.firstName + ' ' + user.lastName + ' (' + user.email + ')'
                }
            })
        },
        // Find User Name
        MIX_findUserName(userId) {
            let user = this.users.find((user) => user.entityId === userId)
            if (user) {
                return user.userName
            } else {
                return 'Unknown User'
            }
        },
        // GET PORTALS
        async MIX_getPortals() {
            let portalResult = await this.REDIS_searchFor('portal', '', '', '', '', '@deleted:{false}')
            let portals = portalResult.data.documents
            // console.log('portals', JSON.stringify(portals, null, 2))
            this.portals = portals
        },
        // Find Portal Name
        MIX_findPortalName(portalId) {
            let portal = this.portals.find((portal) => portal.entityId === portalId)
            if (portal) {
                return portal.portalName
            } else {
                return 'Unknown User'
            }
        },
        async MIX_getOrganisations() {
            let organisationResult = await this.REDIS_searchFor('org', '', '', '', '', '@deleted:{false}')
            let organisations = organisationResult.data.documents
            // console.log('organisations', JSON.stringify(organisations, null, 2))
            this.organisations = organisations
        },
        // Find Organisation Name
        MIX_findOrganisationName(orgId) {
            let organisation = this.organisations.find((organisation) => organisation.entityId === orgId)
            if (organisation) {
                return organisation.name
            } else {
                return 'Unknown Organisation'
            }
        },
        // Get Projects
        async MIX_getProjects() {
            let projectResult = await this.REDIS_searchFor('project', '', '', '', '', '@deleted:{false}')
            let projects = projectResult.data.documents
            // console.log('projects', JSON.stringify(projects, null, 2))
            this.projects = projects
        },
        // Find Project Name
        MIX_findProjectName(projectId) {
            let project = this.projects.find((project) => project.entityId === projectId)
            if (project) {
                return project.name
            } else {
                return 'Unknown Project'
            }
        },
        // Lookups
        async MIX_getLookups() {
            let lookupResult = await this.REDIS_searchFor('lookup', '', '', 'text', 'asc', '@deleted:{false}')
            this.lookups = lookupResult.data.documents
            this.countries = lookupResult.data.documents.filter((item) => item.type === 'Country')
            this.orgStatus = lookupResult.data.documents.filter((item) => item.type === 'OrgStatus')
            this.siteStatus = lookupResult.data.documents.filter((item) => item.type === 'SiteStatus')
            this.siteTypes = lookupResult.data.documents.filter((item) => item.type === 'SiteType')
            this.products = lookupResult.data.documents.filter((item) => item.type === 'Product')
        }

        // OLD STUFF TO REMOVE
        // async MIX_getVetsByOwner(ownerUserId) {
        // 	return new Promise((resolve, reject) => {
        // 		try {
        // 			let query = `@deleted:{false} @type:{Vet} @ownerUserId:{${ownerUserId}}`;
        // 			this.REDIS_searchFor("org", "", "", "", "", query).then((vetsResult) => {
        // 				resolve({
        // 					success: true,
        // 					total: vetsResult.data.total,
        // 					data: vetsResult.data.documents,
        // 				});
        // 			});
        // 		} catch (error) {
        // 			console.error(error);
        // 			reject({
        // 				success: false,
        // 				error: error,
        // 			});
        // 		}
        // 	});
        // },
        // async MIX_getVetHoldingsByVet(vets) {
        // 	return new Promise((resolve, reject) => {
        // 		try {
        // 			let vetsIds = vets.map((vet) => vet.entityId);
        // 			let query = `@deleted:{false} @vetOrgId:{${vetsIds.join("|")}}`;
        // 			this.REDIS_searchFor("vetholding", "", "", "", "", query).then((vetholdingsResult) => {
        // 				resolve({
        // 					success: true,
        // 					total: vetholdingsResult.data.total,
        // 					data: vetholdingsResult.data.documents,
        // 				});
        // 			});
        // 		} catch (error) {
        // 			console.error(error);
        // 			reject({
        // 				success: false,
        // 				error: error,
        // 			});
        // 		}
        // 	});
        // },
        // async MIX_getHoldingsByVetHoldings(vetholdings) {
        // 	return new Promise((resolve, reject) => {
        // 		(async () => {
        // 			try {
        // 				let vetholdingIds = vetholdings.map((vetholding) => vetholding.holdingOrgId);
        // 				let holdings = [];
        // 				for (let i = 0; i < vetholdingIds.length; i++) {
        // 					let holdingResult = await this.REDIS_read("org", vetholdingIds[i]);
        // 					holdings.push(holdingResult.data);
        // 				}
        // 				resolve({
        // 					success: true,
        // 					data: holdings,
        // 					total: holdings.length,
        // 				});
        // 			} catch (error) {
        // 				console.error(error);
        // 				reject({
        // 					success: false,
        // 					error: error,
        // 				});
        // 			}
        // 		})();
        // 	});
        // },
        // // Get Tests
        // async MIX_getTestsByTestEvent(eventId) {
        // 	return new Promise((resolve, reject) => {
        // 		try {
        // 			let query = `@deleted:{false} @eventId:{${eventId}}`;
        // 			// console.log('query', query)
        // 			this.REDIS_searchFor("testdetail", "", "", "", "", query).then((testdetailResult) => {
        // 				resolve({
        // 					success: true,
        // 					total: testdetailResult.data.total,
        // 					data: testdetailResult.data.documents,
        // 				});
        // 			});
        // 		} catch (error) {
        // 			console.error(error);
        // 			reject({
        // 				success: false,
        // 				error: error,
        // 			});
        // 		}
        // 	});
        // },
        // // Get Animals
        // async MIX_getAnimalsByHolding(holdingOrgId) {
        // 	return new Promise((resolve, reject) => {
        // 		try {
        // 			let query = `@deleted:{false} @holdingOrgId:{${holdingOrgId}}`;
        // 			// console.log('query', query)
        // 			this.REDIS_searchFor("animal", "", "", "", "", query).then((animalResult) => {
        // 				resolve({
        // 					success: true,
        // 					total: animalResult.data.total,
        // 					data: animalResult.data.documents,
        // 				});
        // 			});
        // 		} catch (error) {
        // 			console.error(error);
        // 			reject({
        // 				success: false,
        // 				error: error,
        // 			});
        // 		}
        // 	});
        // },
        // // Lookup Holding
        // MIX_lookupHolding(entityId, holdings, field) {
        // 	if (JSON.stringify(holdings) !== "[]") {
        // 		let holding = holdings.find((holding) => holding.entityId === entityId);
        // 		if (holding === undefined) {
        // 			return "";
        // 		} else {
        // 			if (field === "") {
        // 				return holding;
        // 			} else {
        // 				return holding[field];
        // 			}
        // 		}
        // 	}
        // },
        // // Lookup Vet
        // MIX_lookupVet(entityId, vets, field) {
        // 	if (JSON.stringify(vets) !== "[]") {
        // 		let vet = vets.find((vet) => vet.entityId === entityId);
        // 		if (vet === undefined) {
        // 			return "";
        // 		} else {
        // 			if (field === "") {
        // 				return vet;
        // 			} else {
        // 				return vet[field];
        // 			}
        // 		}
        // 	}
        // },
        // // Org Stats by Type
        // MIX_getOrgStatsByType() {
        // 	return new Promise((resolve, reject) => {
        //         (async () => {
        //             try {
        //                 const url = `${this.apiUrl}stats/org/type/`;
        //                 let result = await this.$axios.get(url, this.apiOptions)
        // 				resolve(result.data)
        //             } catch (error) {
        //                 // this.ACT_noCloud(true);
        //                 reject(error);
        //             }
        //         })();
        //     });
        // },
        // MIX_getOrgStatsByCertificate() {
        // 	return new Promise((resolve, reject) => {
        // 		(async () => {
        // 			try {
        // 				const url = `${this.apiUrl}stats/org/certificate/`;
        // 				let result = await this.$axios.get(url, this.apiOptions)
        // 				resolve(result.data)
        // 			} catch (error) {
        // 				// this.ACT_noCloud(true);
        // 				reject(error);
        // 			}
        // 		})();
        // 	});
        // },
        // MIX_getAnimalStatsByStatus() {
        // 	return new Promise((resolve, reject) => {
        // 		(async () => {
        // 			try {
        // 				const url = `${this.apiUrl}stats/animal/status/`;
        // 				let result = await this.$axios.get(url, this.apiOptions)
        // 				resolve(result.data)
        // 			} catch (error) {
        // 				// this.ACT_noCloud(true);
        // 				reject(error);
        // 			}
        // 		})();
        // 	}
        // )},
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin)
    }
}
