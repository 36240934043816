<template>
    <div class="d-flex align-center rounded-of pl-5" :class="BackgroundColor" :style="`minWidth: 100% !important; minHeight: ${height} !important;`">
        <div class="pl-1 text--secondary text-subtitle-1">{{ label }}</div>
        <v-radio-group class="pa-0 ma-0" v-model="innerValue" :column="column" :row="row" :disabled="disabled" hide-details="auto">
            <v-radio
            v-for="option in options"
                :key="option"
                :label="option"
                :value="option"
                :class="radioclass"
            ></v-radio>
        </v-radio-group>       
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        BackgroundColor: {
            type: String,
            default: "grey lighten-4",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        radioclass: {
            type: String,
            default: "",
        },
        height: {
            type: String,
            default: "52px",
        },
        options: {
            type: Array,
            default: () => [],
        },
        column: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Boolean,
            default: true,
        },
		label: {
			type: String,
			default: "",
		}
    },
    data() {
        return {
            innerValue: "",
        };
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>
