import { mapActions } from "vuex";

const mixin = {
	data: () => ({
	}),
	methods: {
		...mapActions({
			ACT_alertBox: "ACT_alertBox",
		}),
		MIX_alertBox(payload) {
			this.ACT_alertBox(payload);
		},
		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},
		// * CHANGE ROUTE FUNCTION
		MIX_goExternal(path) {
			window.open(path, "_blank").focus();
		},
		MIX_mailto(email) {
			window.location.href = "mailto:" + email;
		},
		MIX_call(phone) {
			window.location.href = "call:" + phone;
		},
		MIX_googleMaps(address) {
			window.open("https://www.google.com/maps/search/?api=1&query=" + address, "_blank").focus();
		},
		// SCROLL TO TOP
		MIX_scrollToTop() {
			window.scrollTo(0, 0);
		},
		// FORMAT DATE TIME NOW
		MIX_formatDateTimeNow: function (formatOut) {
			switch (formatOut) {
				case "toISOString":
					return this.$dayjs().toISOString();
				case "toJSON":
					return this.$dayjs().toJSON();
				case "toObject":
					return this.$dayjs().toObject();
				case "toArray":
					return this.$dayjs().toArray();
				case "unix":
					return this.$dayjs().unix();
				case "valueOf":
					return this.$dayjs().valueOf();
				default:
					return this.$dayjs().format(formatOut);
			}
		},
		MIX_formatDateTimeNEW: function (date, formatIn, formatOut) {
			if (formatIn === "") {
				formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
			}
			switch(formatOut) {
				case "unix":
					return this.$dayjs(date, formatIn).unix();
				case "valueOf":
					return this.$dayjs(date, formatIn).valueOf();
				default:
					return this.$dayjs(date, formatIn).format(formatOut);
			}
		},
		// FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				if (formatIn === "ISOString") {
					formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
				}
				switch (formatOut) {
					case "toISOString":
						return this.$dayjs(date, formatIn).toISOString();
					case "toJSON":
						return this.$dayjs(date, formatIn).toJSON();
					case "toObject":
						return this.$dayjs(date, formatIn).toObject();
					case "toArray":
						return this.$dayjs(date, formatIn).toArray();
					case "unix":
						return this.$dayjs(date, formatIn).unix();
					case "valueOf":
						return this.$dayjs(date, formatIn).valueOf();
					default:
						return this.$dayjs(date, formatIn).format(formatOut);
				}
			} else {
				return "";
			}
		},
		// FORMAT DATE TIME FROM NOW
		MIX_formatDateTimeFromNow: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				if (formatIn === "ISOString") {
					formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
				}
				let now = this.$dayjs();
				let newdate = this.$dayjs(date, formatIn);
				var difference
				switch (formatOut) {
					case "Days":						
						difference = now.diff(newdate, 'days');
						if (difference === 0) {
							return 'Today'
						} else if (difference === 1) {
							return 'Yesterday'
						} else {
							return difference + ' days ago'
						} 	
					default:
						difference = now.diff(newdate, 'minutes');
						if (difference === 0) {
							return 'Just now'
						} else if (difference < 60) {
							return difference + ' minutes ago'
						} else if (difference > 60 && difference < 1440) {
							return Math.floor(difference / 60) + ' hours ago'
						} else if (difference > 1440 && difference < 43200) {
							return Math.floor(difference / 1440) + ' days ago'
						} else if (difference > 43200 && difference < 525600) {
							return Math.floor(difference / 43200) + ' months ago'
						}						
				}
			} else {
				return "";
			}
		},		
		// ADD HOUR MINUTES SECONDS TO DATE TIME
		MIX_addTimeAndFormatDateTime: function (data, formatIn, formatOut, hours, minutes, seconds) {
			if (data !== "" && data !== null && data !== undefined && data != 0) {
				let date = this.$dayjs(data, formatIn);
				date = date.add(hours, "hour");
				date = date.add(minutes, "minute");
				date = date.add(seconds, "second");
				switch (formatOut) {
					case "toISOString":
						return this.$dayjs(date, formatIn).toISOString();
					case "toJSON":
						return this.$dayjs(date, formatIn).toJSON();
					case "toObject":
						return this.$dayjs(date, formatIn).toObject();
					case "toArray":
						return this.$dayjs(date, formatIn).toArray();
					case "unix":
						return this.$dayjs(date, formatIn).unix();
					case "valueOf":
						return this.$dayjs(date, formatIn).valueOf();
					default:
						return this.$dayjs(date, formatIn).format(formatOut);
				}
			} else {
				return "";
			}
		},
		// Format Address
		MIX_formatAddress: function (address) {
			if (JSON.stringify(address) !== "{}") {
				let fullAddress = "";
				if (address?.addressLine1) {
					fullAddress += address.addressLine1;
				}
				if (address?.addressLine2) {
					fullAddress += ", " + address.addressLine2;
				}
				if (address?.addressLine3) {
					fullAddress += ", " + address.addressLine3;
				}
				if (address?.town) {
					fullAddress += ", " + address.town;
				}
				if (address?.county) {
					fullAddress += ", " + address.county;
				}
				if (address?.postcode) {
					fullAddress += ", " + address.postcode;
				}
				return fullAddress;
			} else {
				return "";
			}
		},
		MIX_formatCurrency(amount, decimals) {
			const formatter = new Intl.NumberFormat("en-GB", {
				style: "currency",
				currency: "GBP",
				minimumFractionDigits: decimals,
			});

			return formatter.format(amount);
		},
		//this converts the google places data into a format that can be used by the address component
		MIX_googlePlacesConversion (placeResultData) {
			placeResultData = JSON.parse(placeResultData);
			// console.log('placeResultData', JSON.stringify(placeResultData, null, 2));
			let result = {}
			result.name = placeResultData.name;
			let premise = placeResultData.address_components.filter((component) => component.types.includes('premise'));
			let street_number = placeResultData.address_components.filter((component) => component.types.includes('street_number'));
			let route = placeResultData.address_components.filter((component) => component.types.includes('route'));
			let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_1'));
			let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_2'));
			let locality = placeResultData.address_components.filter((component) => component.types.includes('locality'));
			let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes('administrative_area_level_2'));
			let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
			let postal_code = placeResultData.address_components.filter((component) => component.types.includes('postal_code'));
			let postal_town = placeResultData.address_components.filter((component) => component.types.includes('postal_town'));
			let formatted_phone_number = placeResultData.formatted_phone_number;

			var addressLine1 = '';
			var addressLine2 = '';
			// Address Line 1
			if (JSON.stringify(premise) !== '[]') {
				addressLine1 = premise[0]['long_name'];
			}
			if (JSON.stringify(street_number) !== '[]') {
				addressLine1 = addressLine1 + ' ' + street_number[0]['long_name'];
			}
			if (JSON.stringify(route) !== '[]') {
				addressLine1 = addressLine1 + ' ' + route[0]['long_name'];
			}
			if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== '') {
				result.addressLine1 = addressLine1.trim();
			} else {
				result.addressLine1 = placeResultData.formatted_address.split(',')[0];
			}
			// Address Line 2
			if (JSON.stringify(sublocality_level_1) !== '[]') {
				addressLine2 = sublocality_level_1[0]['long_name'];
			}
			if (JSON.stringify(sublocality_level_2) !== '[]') {
				addressLine2 = addressLine2 + ' ' + sublocality_level_2[0]['long_name'];
			}
			if (JSON.stringify(locality) !== '[]') {
				addressLine2 = addressLine2 + ' ' + locality[0]['long_name'];
			}
			if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== '') {
				result.addressLine2 = addressLine2.trim();
			}
			// Address Line 3
			result.AddressLine3 = '';
			// Check for Postal Town
			if (JSON.stringify(postal_town) !== '[]') {
				result.town = postal_town[0]['long_name'];
			}
			// Check for County
			if (JSON.stringify(administrative_area_level_2) !== '[]') {
				result.county = administrative_area_level_2[0]['long_name'];
			}
			// Check for Country
			if (JSON.stringify(country) !== '[]') {
				result.country = country[0]['long_name'];
			}
			// Check for postcode
			if (JSON.stringify(postal_code) !== '[]') {
				result.postcode = postal_code[0]['long_name'];
			}
			// Add LAttitude and Longitude
			// console.log('lat = ', placeResultData.geometry);
			result.lat = placeResultData.geometry.location.lat;
			result.lng = placeResultData.geometry.location.lng;
			// Check for Phone Number
			if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== '') {
				result.telephone = formatted_phone_number.replace(/\s/g, '');
			}
			// Add Website
			result.website = placeResultData.website;
			return result;
		},
		// generates a random alphanumeric string
		MIX_generateId() {
			let generatedId = "";
			const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
			for (let i = 0; i < 20; i++) {
				generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return generatedId;
		},
		// Account Status
		MIX_accountStatus(status) {
			switch (status) {
				case "Completed":
					return "success";
				case "Approved":
					return "success";
				case "Awaiting Approval":
					return "warning";
				case "Rejected":
					return "error";
				case "Accepted":
					return "success";
				case "Pending":
					return "warning";
				default:
					return "grey";
			}
		},
		// Certificate Status
		MIX_certificateStatus(status) {
			switch (status) {
				case "Gold":
					return "gold";
				case "Silver":
					return "silver";
				case "Bronze":
					return "bronze";
				default:
					return "error";
			}
		},
		// // * FORMAT DATE TIME
		// MIX_formatDateTime: function (date, formatIn, formatOut) {
		// 	if (date !== "" && date !== null && date !== undefined && date != 0) {
		// 		return moment(date, formatIn).format(formatOut);
		// 	} else {
		// 		return moment().format(formatOut);
		// 	}
		// },
		// MIX_addFormatDateTime: function(data, formatIn, formatOut, timeValue, timeUnit) {
		// 	if (data !== "" && data !== null && data !== undefined && data != 0) {
		// 		return moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
		// 	} else {
		// 		return moment().add(timeValue, timeUnit).format(formatOut);
		// 	}
		// },
		// MIX_fromNow: function (date, formatIn, ago) {
		// 	return moment(date, formatIn).fromNow(ago);
		// },
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
