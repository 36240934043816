<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Organisations
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField label="Search Orgs" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add Org needs to work with Firebase Authentication -->
                    <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addOrg">Add Organisation</AppButton>
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">
                    The following page shows all orgs that have access to the App/Portal, you can use this page to Manage orgs and access.
                </div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable
                    :tableheaders="filteredHeaders"
                    :loading="loading"
                    custom="orgs"
                    :tabledata="tableData"
                    :datatableoptions="tableOptions"
                    :itemstotal="itemsTotal"
                    v-on:delete="deleteOrg"
                    v-on:copy="copyOrg"
                    v-on:edit="editOrg"
                    v-on:restore="restoreOrg"
                    v-on:tableoptions="updateTableOptions"
                />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Account Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width: 100% !important">
                                    <v-btn title="Approved" value="Approved"
                                        ><v-icon class="success--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn
                                    >
                                    <v-btn title="Pending" value="Pending"
                                        ><v-icon class="warning--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn
                                    >
                                    <v-btn title="Rejected" value="Rejected"
                                        ><v-icon class="error--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn
                                    >
                                    <v-btn title="Suspended" value="Suspended"
                                        ><v-icon class="info--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn
                                    >
                                    <v-btn title="Archived" value="Archived"
                                        ><v-icon class="grey--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn
                                    >
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Edit Window -->
            <v-navigation-drawer width="400" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
                <!-- Practice Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <div class="text-h5 secondary--text">{{ action }} Organisation</div>
                            <v-spacer />
                            <v-btn icon depressed @click="closeOrg"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the form below to edit the Org's details
                    </v-col>
                    <v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
                        <div v-if="org.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Approve</AppButton>
                        </div>
                        <div v-if="org.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="error" @click.native="updateStatus('Rejected')">Reject</AppButton>
                        </div>
                        <div v-if="org.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="info" @click.native="updateStatus('Suspended')">Suspend</AppButton>
                        </div>
                        <div v-if="org.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="grey white--text" @click.native="updateStatus('Archived')">Archive</AppButton>
                        </div>
                        <div v-if="org.status === 'Suspended' || org.status === 'Archived'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Restore</AppButton>
                        </div>
                    </v-col>
                    <!--					<v-col><v-btn @click="MIX_go('/client/' + org.entityId)">Go</v-btn></v-col>-->
                </v-row>
                <!-- Org Organisation Details -->
                <!-- TODO - Org can be assigned to multiple organisations -->
                <v-row>
                    <v-col cols="12">
                        <v-row dense class="">
                            <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Details</div> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Client Name" v-model="org.name" /> </v-col>
                            <v-col cols="6">
                                <TextField BackgroundColor="white" label="First Name *" v-model="org.contactFirstName" :validate="validate.contactFirstName" />
                            </v-col>
                            <v-col cols="6">
                                <TextField BackgroundColor="white" label="Last Name *" v-model="org.contactLastName" :validate="validate.contactLastName" />
                            </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Job Title" v-model="org.contactJobRole" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Telephone *" v-model="org.telephone" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Email" v-model="org.email" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Website" v-model="org.website" /> </v-col>
                            <v-col cols="12">
                                <DropdownAdvanced
                                    BackgroundColor="white"
                                    :items="org"
                                    itemtext="name"
                                    itemvalue="entityId"
                                    label="Parent Org"
                                    v-model="org.parentId"
                                />
                            </v-col>
                            <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Address</div> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Address Line 1" v-model="org.addressLine1" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 2" v-model="org.addressLine2" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 3" v-model="org.addressLine3" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Town" v-model="org.town" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="County" v-model="org.county" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="Postcode" v-model="org.postcode" /> </v-col>
                            <v-col cols="12"> <DropdownSimple BackgroundColor="white" :items="countries" label="Country" v-model="org.country" /> </v-col>
                            <v-col cols="12"> <DropdownMultiple BackgroundColor="white" :items="orgTypes" label="Type" v-model="org.type" /> </v-col>
                            <v-col cols="12"> <DropdownSimple BackgroundColor="white" :items="orgStatus" label="Status" v-model="org.status" /> </v-col>
                            <v-col cols="12">
                                <DropdownAdvanced
                                    BackgroundColor="white"
                                    :items="users"
                                    itemtext="fullName"
                                    itemvalue="entityId"
                                    label="Account Owner"
                                    v-model="org.ownerUserId"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="6">
					</v-col> -->
                    <!-- <pre>{{ org }}</pre> -->
                </v-row>

                <!-- <pre>{{ org }}</pre> -->
                <!-- Action Buttons -->
                <v-row dense class="mt-5">
                    <v-col cols="6">
                        <div><AppButton block buttonclass="grey" @click.native="closeOrg">Cancel</AppButton></div>
                    </v-col>
                    <v-col cols="6">
                        <div><AppButton block buttonclass="primary" @click.native="saveOrg">Save</AppButton></div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Org</p>
                <strong>{{ org.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VetsAdmin',
    // data
    data: () => ({
        loading: false,
        drawer: false,
        filterDrawer: false,
        confirmBox: false,
        permenantDelete: false,
        search: '',
        filter: {
            status: ['Approved', 'Pending'],
            showDeleted: false
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: 'Status', value: 'status', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Name', value: 'name', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'First Name', value: 'contactFirstName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Last Name', value: 'contactLastName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Job Title', value: 'contactJobRole', align: 'start', hidden: false, sortable: false, shrunk: false },
            { text: 'Telephone', value: 'telephone', align: 'start', hidden: false, sortable: false, shrunk: false },
            { text: 'Email', value: 'email', align: 'start', hidden: false, sortable: false, shrunk: false },
            { text: 'Type', value: 'type', align: 'start', hidden: true, sortable: false, shrunk: false },

            { text: 'createdUserId', value: 'createdUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdDateTime', value: 'createdDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedUserId', value: 'modifiedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedDateTime', value: 'modifiedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedUserId', value: 'deletedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedDateTime', value: 'deletedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deleted', value: 'deleted', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '130px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['name'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        validate: {},
        org: [],
        users: [],
        countries: [],
        orgTypes: [],
        orgStatus: [],
        orgGroups: [],
        orgLevels: [],
        orgLanguages: [],
        action: 'Add',
        showInfo: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.search !== '' && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`
            } else {
                query += `@deleted:{false}`
            }
            // // Filter Status
            // if (JSON.stringify(this.filter.status) !== "[]") {
            // 	query += ` @status:{${this.filter.status.join("|")}}`;
            // }
            return query
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0]
            if (sortDesc) {
                sortDesc = 'DESC'
            } else {
                sortDesc = 'ASC'
            }
            return sortDesc
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1
                this.getTableData()
            },
            deep: true
        },
        tableOptions: {
            handler() {
                this.getTableData()
            },
            deep: true
        }
    },
    // methods
    methods: {
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true
                let dataResult = await this.REDIS_searchFor('org', this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery)
                this.tableData = dataResult.data.documents
                // console.log("this.tableData", JSON.stringify(this.tableData, null, 2));
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Add Org
        addOrg() {
            this.MIX_go('/organisation/new')
        },
        // Edit Org
        editOrg(org) {
            this.MIX_go(`/organisation/${org.id}`)
            // this.org = { ...org };
            // this.action = "Edit";
            // this.getUsers();
            // this.filterDrawer = false;
            // this.drawer = true;
        },
        // Copy Org
        copyOrg(org) {
            // copy org.id to clipboard
            navigator.clipboard.writeText(org.id)
            this.MIX_alertBox({ show: true, message: 'Org ID ' + org.id + ' - Copied to clipboard', color: 'success', timeout: '2000' })
        },
        // Delete Org
        deleteOrg(org) {
            this.org = { ...org }
            this.action = 'Delete'
            this.confirmBox = true
        },
        // Close ORg
        closeOrg(org) {
            this.org = { ...org }
            this.drawer = false
        },
        // Restore Org
        async restoreOrg(org) {
            try {
                this.loading = true
                this.org = { ...org }
                this.org.modifiedUserId = this.GET_currentUserProfile.entityId
                this.org.modifiedOrgId = this.GET_currentUserProfile.orgId
                this.org.deleted = false
                this.org.deletedUserId = null
                this.org.deletedOrgId = null
                this.org.deletedDateTime = null
                await this.REDIS_update('org', this.org.entityId, this.org)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Restored', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Restoring', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Confirm Delete Org
        async confirmDeleteOrg() {
            try {
                this.loading = true
                this.org.modifiedUserId = this.GET_currentUserProfile.entityId
                this.org.modifiedOrgId = this.GET_currentUserProfile.orgId
                this.org.deleted = true
                this.org.deletedUserId = this.GET_currentUserProfile.entityId
                this.org.deletedOrgId = this.GET_currentUserProfile.orgId
                this.org.deletedDateTime = this.MIX_formatDateTimeNow('toISOString')
                await this.REDIS_update('org', this.org.entityId, this.org)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Deleted', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Deleting', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Save Org
        async saveOrg() {
            try {
                this.loading = true
                this.org.modifiedUserId = this.GET_currentUserProfile.entityId
                this.org.modifieDateTime = this.MIX_formatDateTimeNow('toISOString')
                let validationErrors = null
                validationErrors = 0
                // if (this.org.firstName === "") {
                // 	this.validate.firstName = "First Name is required";
                // 	validationErrors++;
                // }
                // if (this.org.lastName === "") {
                // 	this.validate.lastName = "Last Name is required";
                // 	validationErrors++;
                // }
                // if (this.org.email === "") {
                // 	this.validate.email = "Email is required";
                // 	validationErrors++;
                // }
                // if (this.org.telephone === "") {
                // 	this.validate.telephone = "Telephone is required";
                // 	validationErrors++;
                // }
                // if (this.org.status === "") {
                // 	this.validate.status = "Status is required";
                // 	validationErrors++;
                // }
                // loop through the this.org object and trim all the values
                Object.keys(this.org).forEach((key) => {
                    if (typeof this.org[key] === 'string') {
                        this.org[key] = this.org[key].trim()
                        // remove any . from the end of the string
                        if (this.org[key].slice(-1) === '.') {
                            this.org[key] = this.org[key].slice(0, -1)
                        }
                    }
                })
                // If no validation errors
                if (this.action === 'Add' && validationErrors === 0) {
                    this.org.createdUserId = this.GET_currentUserProfile.entityId
                    this.org.createdDateTime = this.MIX_formatDateTimeNow('toISOString')
                    await this.REDIS_create('org', this.org)
                    this.loading = false
                    this.org = { ...this.$schema.org }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getTableData()
                } else if (this.action === 'Edit') {
                    await this.REDIS_update('org', this.org.entityId, this.org)
                    this.loading = false
                    this.org = { ...this.$schema.org }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getTableData()
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            let lookupResult = await this.REDIS_searchFor('lookup', '', '', 'text', 'asc', '@deleted:{false} @type:{Organisation Type|Country|OrgStatus}')
            this.orgTypes = lookupResult.data.documents.filter((item) => item.type === 'Organisation Type')
            this.orgStatus = lookupResult.data.documents.filter((item) => item.type === 'OrgStatus')
            this.countries = lookupResult.data.documents.filter((item) => item.type === 'Country')
            this.getTableData()
            this.loading = false
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        },
        // Get Users
        async getUsers() {
            try {
                this.loading = true
                let query = `@deleted:{false}`
                let usersResult = await this.REDIS_searchFor('user', '', '', 'firstName', 'asc', query)
                let users = usersResult.data.documents
                for (let i = 0; i < users.length; i++) {
                    const user = users[i]
                    user.fullName = user.firstName + ' ' + user.lastName + ' (' + user.email + ')'
                }
                this.users = users
                console.log('this.users', JSON.stringify(this.users, null, 2))
                this.loading = false
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Getting Users', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        updateStatus(status) {
            this.org.status = status
            this.saveOrg()
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false
            }
            this.filterDrawer = !this.filterDrawer
        }
    },
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
