<template>
    <v-banner v-if="updateExists" app :style="dynamicStyle" class="secondary fixed foreground-high elevation-0 pa-0 ma-0 bottom-border foreground-medium" height="80">
        <div class="d-flex align-center justify-center">
            <v-spacer />
            <div class="text-right text-h7 primary--text font-weight-bold mr-5">The App has beeen updated,<br />Click to get the latest version.</div>
            <div class="">
                <v-btn @click="refreshApp" depressed class="animate__animated animate__pulse animate__infinite white--text primary animated"><v-icon class="mr-2">icons8-download-from-the-cloud</v-icon>Update Now</v-btn>
            </div>
            <v-spacer />
            <v-btn @click="updateExists = false" icon><v-icon class="white--text">icons8-close</v-icon></v-btn>
        </div>
    </v-banner>
</template>
<script>
export default {
	computed: {
		dynamicStyle() {
			if (this.$vuetify.breakpoint.lgAndDown) {
				return {
					width: '100vw !important'
				}
			} else {
				return {
					width: 'calc(100vw - 120px) !important'			};
			}
		}
	}
}
</script>