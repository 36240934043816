<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="6">
                <div class="text-h4 primary--text">{{ site?.siteName }}</div>
                <div class="text-h6 grey--text">Site Dashboard</div>
            </v-col>
            <!--        <pre>{{ site }}</pre>-->
            <v-col cols="6" class="d-flex justify-end align-center">
                <!-- <AppButton class="mr-3" ><v-icon class="primary--text">icons8-csv</v-icon></AppButton>
              <AppButton class="mr-3" ><v-icon class="primary--text">icons8-pdf</v-icon></AppButton> -->
                <!-- StartDate -->
                <!--        <div>{{startDate}} | {{startDateUnix}} | {{ endDate }} {{ endDateUnix }}</div>-->
                <div><DatePicker BackgroundColor="white" label="Start Date" v-model="startDate" :dense="true" :clearable="false" /></div>
                <!-- EndDate -->
                <div class="mx-2"><DatePicker BackgroundColor="white" label="End Date" v-model="endDate" :clearable="false" :dense="true" /></div>
                <AppButton @click.native="calculate">Update</AppButton>
            </v-col>

            <!--      <v-col cols="12">type: {{ site?.type }} | costPerkWh: {{ site?.costPerkWh }} | costPerLitre: {{ site?.costPerLitre }} | comparisonkWh: {{ site?.comparisonkWh-->
            <!--        }} |</v-col>-->

            <!--Load in W Over Time-->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width secondary rounded-t-lg d-flex align-center px-4" style="height: 60px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Load in W Over Time</div>
                            <v-spacer />
                            <v-icon class="white--text text--darken-1">icons8-price-tag-pound</v-icon>
                        </div>
                    </template>
                    <LineChart :loading="loading" :datadown="loadSeries" :height="400" class="" :key="chartKey"></LineChart>
                </BasicCard>
            </v-col>
            <!--Total Usage in kWh Over Time-->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width secondary rounded-t-lg d-flex align-center px-4" style="height: 60px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Usage in kWh Over Time</div>
                            <v-spacer />
                            <v-icon class="white--text text--darken-1">icons8-price-tag-pound</v-icon>
                        </div>
                    </template>
                    <LineChart
                        :loading="loading"
                        :datadown="usageTimeseries"
                        :height="400"
                        class=""
                        xaxislabel="Time"
                        yaxislabel="Usage (kWh)"
                        :key="chartKey"
                    ></LineChart>
                </BasicCard>
            </v-col>
            <!-- Total Usage By Hours -->
            <v-col cols="12" class="mt-2">
                <BasicCard>
                    <!--                    <template v-slot:header>-->
                    <!--                        <div class="full-width secondary rounded-t-lg d-flex align-center px-4" style="height: 60px !important; overflow: hidden !important">-->
                    <!--                            <div class="text-body-1 font-weight-bold white&#45;&#45;text text-left">Hourly Usage</div>-->
                    <!--                            <v-spacer />-->
                    <!--                            <v-icon class="white&#45;&#45;text text&#45;&#45;darken-1">icons8-price-tag-pound</v-icon>-->
                    <!--                        </div>-->
                    <!--                    </template>-->
                    <BarChart
                        :loading="loading"
                        :xaxisdata="usageByHourXAxis"
                        :yaxisdata="usageByHourYAxis"
                        :y2axisdata="tempByHourYAxis"
                        :seriesdata="usageByHour"
                        :height="600"
                        class=""
                        xaxislabel="Time"
                        yaxislabel="Usage (kWh)"
                        :key="chartKey"
                    ></BarChart>
                </BasicCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// import AreaChart from "@/components/charts/AreaChart.vue";

export default {
    name: 'SiteDashboardView',
    // components: { AreaChart },
    data(vm) {
        return {
            site: null,
            loading: false,
            chartKey: 0,
            startDate: null,
            endDate: null,
            initStartDate: vm.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            initEndDate: vm.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            startDateUnix: 0,
            endDateUnix: 0,
            loadSeries: [],
            usageTimeseries: [],
            usageByHour: [],
            usageByHourYAxis: [],
            usageByHourXAxis: [],
            tempByHour: [],
            tempByHourYAxis: [],
            tempByHourXAxis: [],
            usage_kWh: {
                firstValue: 0,
                lastValue: 0
            },
            stat: {
                powerUsage: 11,
                carbonFootprint: 5,
                totalGridCost: 10,
                powerSaved: 603,
                carbonFootprintGrid: 210,
                totalCostSavingGrid: 804
            },
            totalWLoadX: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
            totalWLoadY: [200, 100, 1200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            totalWUsageX: ['Usage without Automate', 'Usage with Automate'],
            totalWUsageY: [900, 400],
            conversion: {
                kWhPerLitre: '2.3', // per day
                kWhToDiesel: '0.4347826087',
                kWhToGrid: '0.33',
                //fuelCostPerLitre: "0.65", // £ cost per litre
                dieselLtr: '152', // litres per day
                co2Kg: '2.68',
                co2KgHvo: '0.195',
                co2KgGrid: '0.309',
                // New Values
                kwh_to_diesel: '0.4347826087',
                diesel_to_co2: '2.68'
            },
            usageTotal_kWh: 0,
            usageTotal_Diesel: 0,
            usageTotal_CO2: 0,
            totalCost: 0
        }
    },
    computed: {},
    watch: {},
    methods: {
        // Get Site Details
        async getSite() {
            const siteID = this.$route.params.id
            await this.REDIS_read('site', siteID).then((result) => {
                this.site = result.data
            })
        },
        // Get Load Over Time
        async getLoadOverTime() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${this.site.siteId}:load:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: null,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    this.loadSeries = res.data.data
                    // console.log(JSON.stringify(res, null, 2));
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // Get Usage Over Time
        async getUsageOverTime() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    this.usageTimeseries = res.data.data
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getUsageByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    const dataArray = []
                    const xAxis = []
                    const yAxis = []
                    for (let i = 0; i < res.data.data.length - 1; i++) {
                        const currentTimestamp = res.data.data[i][0]
                        const currentValue = res.data.data[i][1]
                        const nextValue = res.data.data[i + 1][1]
                        dataArray.push([t.$dayjs(currentTimestamp).format(), parseFloat((nextValue - currentValue).toFixed(2))])
                        xAxis.push(t.$dayjs(currentTimestamp).add(1, 'hour').format('HH'))
                        yAxis.push(parseFloat((nextValue - currentValue).toFixed(2)))
                    }
                    this.usageByHourXAxis = xAxis
                    this.usageByHourYAxis = yAxis
                    this.usageByHour = dataArray
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getTempByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${this.site.siteId}:temperature:total`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    const dataArray = []
                    const xAxis = []
                    const yAxis = []
                    for (let i = 0; i < res.data.data.length - 1; i++) {
                        const currentTimestamp = res.data.data[i][0]
                        const currentValue = res.data.data[i][1]
                        const nextValue = res.data.data[i + 1][1]
                        dataArray.push([t.$dayjs(currentTimestamp).format(), parseFloat((nextValue - currentValue).toFixed(2))])
                        xAxis.push(t.$dayjs(currentTimestamp).add(1, 'hour').format('HH'))
                        yAxis.push(parseInt(currentValue))
                    }
                    this.tempByHourXAxis = xAxis
                    this.tempByHourYAxis = yAxis
                    this.tempByHour = dataArray
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async calculate() {
            this.loading = true
            console.log('startDate: ' + this.startDate)
            console.log('endDate: ' + this.endDate)
            if (this.startDate === null) {
                this.startDate = this.initStartDate
            }
            if (this.endDate === null) {
                this.endDate = this.initEndDate
            }
            console.log('startDate: ' + this.startDate)
            console.log('endDate: ' + this.endDate)
            this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
            this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
            console.log('startDateUnix: ' + this.startDateUnix)
            console.log('endDateUnix: ' + this.endDateUnix)
            // Get Data
            await this.getLoadOverTime()
            await this.getUsageOverTime()
            await this.getUsageByHour()
            await this.getTempByHour()
            this.chartKey++
            this.loading = false
        }
    },
    async created() {
        this.loading = true
        await this.getSite()
        await this.calculate()
        this.loading = false
    }
}
</script>
