<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-0 pt-0 secondary">
            <v-col cols="4" class="px-8 py-8">
                <div class="text-h5 font-weight-light white--text">
                    Organisation: <strong>{{ MIX_findOrganisationName(GET_currentUserProfile.orgId) }}</strong>
                </div>
            </v-col>
            <!--        <pre>{{ site }}</pre>-->
            <!--            <v-col cols="8" class="d-flex justify-end align-center px-5">-->
            <!--                <div>-->
            <!--                    <DatePicker BackgroundColor="white" label="Start Date" v-model="startDate" :initialdate="initStartDate" :dense="true" :clearable="false" />-->
            <!--                </div>-->
            <!--                <div class="mx-2">-->
            <!--                    <DatePicker BackgroundColor="white" label="End Date" v-model="endDate" :initialdate="initEndDate" :clearable="false" :dense="true" />-->
            <!--                </div>-->
            <!--                <AppButton @click.native="calculate" class="primary mx-1"><v-icon class="mr-2">icons8-refresh</v-icon>Update</AppButton>-->
            <!--                <AppButton :disabled="true" class="primary mx-1"><v-icon>icons8-export-pdf</v-icon>PDF</AppButton>-->
            <!--                <AppButton :disabled="true" class="primary mx-1"><v-icon>icons8-csv</v-icon>CSV</AppButton>-->
            <!--            </v-col>-->

            <!--      <v-col cols="12">type: {{ site?.type }} | costPerkWh: {{ site?.costPerkWh }} | costPerLitre: {{ site?.costPerLitre }} | comparisonkWh: {{ site?.comparisonkWh-->
            <!--        }} |</v-col>-->
        </v-row>

        <!-- USAGE -->
        <!--        <v-row dense class="px-5 pt-2">-->
        <!--            &lt;!&ndash;Total Power Usage&ndash;&gt;-->
        <!--            <v-col cols="2">-->
        <!--                <DashCard title="Total Power Usage" icon="icons8-speed" :value="usageTotal_kWh" unit="kWh" description="Power Used" :loading="loading" />-->
        <!--            </v-col>-->
        <!--            &lt;!&ndash;Carbon Footprint&ndash;&gt;-->
        <!--            <v-col cols="2">-->
        <!--                <DashCard title="Carbon Footprint" icon="icons8-co2" :value="usageTotal_CO2" unit="kg" description="C02 Emitted" :loading="loading" />-->
        <!--            </v-col>-->
        <!--            &lt;!&ndash;Total Cost&ndash;&gt;-->
        <!--            <v-col cols="2">-->
        <!--                <DashCard title="Total Cost" icon="icons8-sales-performance" :value="totalCost" unit="£" description="Spent" :loading="loading" />-->
        <!--            </v-col>-->
        <!--            &lt;!&ndash; Automation Mode &ndash;&gt;-->
        <!--            <v-col cols="2">-->
        <!--                <DashCard title="Sites" icon="icons8-map-2" unit="" value="1" description="Sites" :loading="loading" />-->
        <!--            </v-col>-->
        <!--        </v-row>-->

        <!--        <v-row dense class="px-5">-->
        <!--            &lt;!&ndash; Top 10 Circuits by Usage - Table &ndash;&gt;-->
        <!--            <v-col cols="6" class="mt-2 mb-5">-->
        <!--                <BasicCard style="height: 505px !important">-->
        <!--                    <template v-slot:header>-->
        <!--                        <div class="full-width primary rounded-t-lg d-flex align-center px-4" style="height: 80px !important; overflow: hidden !important">-->
        <!--                            <div class="text-body-1 font-weight-bold white&#45;&#45;text text-left">Top 10 Sites by Usage</div>-->
        <!--                            <v-spacer />-->
        <!--                            <v-icon class="primary&#45;&#45;text text&#45;&#45;lighten-4 text-h4">icons8-list-2</v-icon>-->
        <!--                        </div>-->
        <!--                    </template>-->
        <!--                    <div v-if="loading" style="height: 400px !important" class="d-flex align-center justify-center flex-column">-->
        <!--                        <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>-->
        <!--                        <div class="grey&#45;&#45;text text&#45;&#45;lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>-->
        <!--                    </div>-->
        <!--                    <div v-else style="height: 400px !important" class="">-->
        <!--                        <table width="100%" class="pa-5">-->
        <!--                            <thead>-->
        <!--                                <tr>-->
        <!--                                    <th align="left">Site Name</th>-->
        <!--                                    <th align="left">Project</th>-->
        <!--                                    <th align="left">Type</th>-->
        <!--                                    <th align="right">Usage kWh</th>-->
        <!--                                    <th></th>-->
        <!--                                </tr>-->
        <!--                            </thead>-->
        <!--                            <tr>-->
        <!--                                <td class="text-h6">Pear Tree</td>-->
        <!--                                <td class="text-h6">Pear Tree Academy</td>-->
        <!--                                <td class="text-h6">Grid</td>-->
        <!--                                <td class="text-h6" align="right">{{ usageTotal_kWh }}</td>-->
        <!--                                <td align="right">-->
        <!--                                    <v-icon large class="primary&#45;&#45;text" @click="MIX_go('/site-dashboard/TUUVnXkKDObRsiApJFJy')">icons8-next-3</v-icon>-->
        <!--                                </td>-->
        <!--                            </tr>-->
        <!--                            &lt;!&ndash;                    <tr v-for="circuit in computedUsageByCircuit" :key="circuit.id">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <td class="body-2">{{ circuit.name }}</td>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <td class="body-2">{{ circuit.group }}</td>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <td class="text-caption font-weight-bold">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            <v-chip label small class="grey lighten-2">{{ circuit?.type?.toUpperCase() }}</v-chip>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        </td>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <td align="right">{{ circuit?.value.toFixed(2) }}</td>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                    </tr>&ndash;&gt;-->
        <!--                        </table>-->
        <!--                    </div>-->
        <!--                </BasicCard>-->
        <!--            </v-col>-->
        <!--            &lt;!&ndash; Hourly Usage (kWh) - Bar Chart &ndash;&gt;-->
        <!--            <v-col cols="6" class="mt-2">-->
        <!--                <BasicCard>-->
        <!--                    <template v-slot:header>-->
        <!--                        <div class="full-width primary rounded-t-lg d-flex align-center px-4" style="height: 80px !important; overflow: hidden !important">-->
        <!--                            <div class="text-body-1 font-weight-bold white&#45;&#45;text text-left ml-4">Hourly Usage (kWh)</div>-->
        <!--                            <v-spacer />-->
        <!--                            <v-spacer />-->
        <!--                            <v-icon class="primary&#45;&#45;text text&#45;&#45;lighten-4 text-h4">icons8-bar-chart-2</v-icon>-->
        <!--                        </div>-->
        <!--                    </template>-->
        <!--                    <BarChart2-->
        <!--                        :loading="loading"-->
        <!--                        :datadown="usageRange"-->
        <!--                        :height="400"-->
        <!--                        class=""-->
        <!--                        xaxislabel="Time"-->
        <!--                        yaxislabel="Usage"-->
        <!--                        yaxisunit="kWh"-->
        <!--                        :key="chartKey"-->
        <!--                    ></BarChart2-->
        <!--                    ><br />-->
        <!--                </BasicCard>-->
        <!--            </v-col>-->
        <!--        </v-row>-->
        <v-row dense class="px-5 pt-2">
            <v-col cols="12" class="d-flex align-center justify-end">
                <!-- Search -->
                <SearchField label="Search" v-model="search" :dense="true" />
                <!--					<AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>-->
                <!-- <AppButton @click.native="getTableData"><v-icon class="gold--text">icons8-rating</v-icon></AppButton> -->
                <AppButton @click.native="previous()" :disabled="this.tableOptions.page === 1"><v-icon class="primary--text">icons8-previous</v-icon></AppButton>
                <AppButton v-if="!table" @click.native="table = true"><v-icon class="primary--text">icons8-table</v-icon></AppButton>
                <AppButton v-if="table" @click.native="table = false"><v-icon class="primary--text">icons8-rows</v-icon></AppButton>
                <AppButton @click.native="next()" :disabled="tableOptions.page * tableOptions.itemsPerPage >= itemsTotal"
                    ><v-icon class="primary--text">icons8-next</v-icon></AppButton
                >

                <!--                <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addSite">Add Site</AppButton>-->
                <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                <!-- TODO - Invite Button required -->
                <!-- TODO - Add User needs to work with Firebase Authentication -->
                <!-- <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addUser">Add User</AppButton> -->
                <!-- <div class="white--text">
                    <DropdownMenu>
                        <div class="d-flex align-center">
                            <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                            <div class="font-weight-bold secondary--text">Show Deleted</div>
                        </div>
                    </DropdownMenu>
                </div> -->
            </v-col>
        </v-row>
        <v-row dense class="px-5">
            <div class="d-flex align-center px-2 my-2" style="width: 100% !important">
                <div class="body-2 grey--text text--darken-2">
                    Showing <strong>{{ itemsFrom + 1 }}</strong>
                    <span v-if="itemsTo !== itemsTotal">
                        to <strong>{{ itemsTo }}</strong></span
                    >
                    of <strong>{{ itemsTotal }}</strong>
                </div>
            </div>
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable
                    v-if="table"
                    :actions="['View']"
                    :tableheaders="filteredHeaders"
                    :loading="loading"
                    custom="organisation"
                    :tabledata="tableData"
                    :datatableoptions="tableOptions"
                    :itemstotal="itemsTotal"
                    v-on:view="viewSite"
                    v-on:tableoptions="updateTableOptions"
                />
                <v-row v-if="!table">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        v-for="item in tableData"
                        :key="item.entityId"
                        @click="$router.push('/site-dashboard/' + item.entityId)"
                    >
                        <BasicCard footerclass="grey lighten-4" class="" style="min-height: 230px !important">
                            <template v-slot:header>
                                <div class="full-width d-flex align-start px-4 pt-2" style="height: 60px !important; overflow: hidden !important">
                                    <div class="text-body-1 font-weight-bold primary--text text-left">
                                        {{ item.siteName.substring(0, 80) }}
                                    </div>
                                    <v-spacer />
                                </div>
                            </template>
                            <div class="mt-2 mb-1 grey lighten-3" style="height: 3px !important"></div>
                            <div class="px-4 text-body-1 grey--text text--darken-4 pb-5 mt-3">
                                <div class="mt-2 d-flex align-center">
                                    <div class="text-body-2 font-italic">{{ item.sourceName }}</div>
                                    <v-spacer />
                                    <div class="text-body-2">{{ item.type }}</div>
                                </div>
                                <div class="mt-1">{{ item.town }}</div>
                                <div class="mt-1">{{ item.postcode }}</div>
                                <!-- <v-icon class="grey--text pr-1" small>icons8-link</v-icon><a :href="item.sourceURL" class="" target="_blank">Mark to Market</a></div> -->
                            </div>
                            <!--                            <template v-slot:footer>-->
                            <!--                                <div class="full-width px-4 py-2 d-flex align-center" style="overflow: hidden !important">-->
                            <!--                                    &lt;!&ndash; <div class=""><v-icon class="grey&#45;&#45;text text&#45;&#45;darken-1">icons8-address</v-icon></div>-->
                            <!--									<div class="">{{ item?.location }}</div> &ndash;&gt;-->
                            <!--                                    <div class="flex-grow-1 d-flex align-center justify-center">-->
                            <!--                                        &lt;!&ndash;										<v-icon class="grey&#45;&#45;text text&#45;&#45;darken-1">icons8-sales-balance</v-icon>&ndash;&gt;-->
                            <!--                                        <span><b>Load:</b> 21kWp</span>-->
                            <!--                                        &lt;!&ndash;										<span v-else>-</span>&ndash;&gt;-->
                            <!--                                    </div>-->
                            <!--                                    <div class="flex-grow-1 d-flex align-center justify-center">-->
                            <!--                                        &lt;!&ndash;										<v-icon class="grey&#45;&#45;text text&#45;&#45;darken-1">icons8-price-tag-pound</v-icon>&ndash;&gt;-->
                            <!--                                        <span><b>Usage:</b> 104kWh</span>-->
                            <!--                                        &lt;!&ndash;										<span v-else>-</span>&ndash;&gt;-->
                            <!--                                    </div>-->
                            <!--                                    <div class="flex-grow-1 d-flex align-center justify-center">-->
                            <!--                                        &lt;!&ndash;										<v-icon class="grey&#45;&#45;text text&#45;&#45;darken-1">icons8-total-sales</v-icon>&ndash;&gt;-->
                            <!--                                        <span><b>C02:</b> 12kg</span>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </template>-->
                        </BasicCard>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ user.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteUser">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VetsAdmin',
    // data
    data: (vm) => ({
        loading: false,
        drawer: false,
        tab: 'description',
        filterDrawer: false,
        table: true,
        confirmBox: false,
        permenantDelete: false,
        search: '',
        filter: {
            turnoverMin: '',
            turnoverMax: '',
            priceMin: '',
            priceMax: '',
            ebitdaMin: '',
            ebitdaMax: '',
            profitMin: '',
            profitMax: '',
            sectors: [],
            subsectors: [],
            location: [],
            sourceId: '',
            showDeleted: false
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: 'Site Name', value: 'siteName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Address Line 1', value: 'addressLine1', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Address Line 2', value: 'addressLine2', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Town', value: 'town', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'County', value: 'county', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Postcode', value: 'postcode', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'createdUserId', value: 'createdUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdOrgId', value: 'createdOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdDateTime', value: 'createdDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedUserId', value: 'modifiedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifedOrgId', value: 'modifedOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedDateTime', value: 'modifiedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedUserId', value: 'deletedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedOrgId', value: 'deletedOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedDateTime', value: 'deletedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deleted', value: 'deleted', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '150px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 9,
            sortBy: ['siteName'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        validate: {},
        user: {},
        organisations: [],
        orgTypes: [],
        sectors: [],
        subsectors: [],
        action: 'Add',
        showInfo: false,
        business: {},
        ignorekeywordlist: [],
        sources: [],
        // Usage
        usageTotal_kWh: 0,
        usageTotal_Diesel: 0,
        usageTotal_CO2: 0,
        totalCost: 0,
        // usageTotal_Rental: 0,
        // estimateRental_Cost: 0,
        startDate: null,
        endDate: null,
        initStartDate: vm.$dayjs().subtract(8, 'day').format('YYYY-MM-DD'),
        initEndDate: vm.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        startDateUnix: 0,
        endDateUnix: 0,
        // temporary
        conversion: {
            kWhPerLitre: '2.3', // per day
            kWhToDiesel: '0.4347826087',
            kWhToGrid: '0.33',
            //fuelCostPerLitre: "0.65", // £ cost per litre
            dieselLtr: '152', // litres per day
            co2Kg: '2.68',
            co2KgHvo: '0.195',
            co2KgGrid: '0.309',
            // New Values
            kwh_to_diesel: '0.4347826087',
            diesel_to_co2: '2.68',
            grid_kwh_to_co2: '0.309',
            hvo_kwh_to_co2: '0.195'
        },
        usageRange: [],
        chartKey: 0,
        day: true,
        night: true
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.search !== '' && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`
            } else {
                query += `@deleted:{false}`
            }
            // Filter Turnover
            if (this.filter.turnoverMin !== '') {
                if (this.filter.turnoverMax !== '') {
                    query += ` @turnover:[${this.filter.turnoverMin} ${this.filter.turnoverMax}]`
                } else {
                    query += ` @turnover:[${this.filter.turnoverMin} 9999999999]`
                }
            }
            if (this.filter.turnoverMax !== '') {
                if (this.filter.turnoverMin !== '') {
                    query += ` @turnover:[${this.filter.turnoverMin} ${this.filter.turnoverMax}]`
                } else {
                    query += ` @turnover:[0 ${this.filter.turnoverMax}]`
                }
            }
            // Filter Price
            if (this.filter.priceMin !== '') {
                if (this.filter.priceMax !== '') {
                    query += ` @price:[${this.filter.priceMin} ${this.filter.priceMax}]`
                } else {
                    query += ` @price:[${this.filter.priceMin} 9999999999]`
                }
            }
            if (this.filter.priceMax !== '') {
                if (this.filter.priceMin !== '') {
                    query += ` @price:[${this.filter.priceMin} ${this.filter.priceMax}]`
                } else {
                    query += ` @price:[0 ${this.filter.priceMax}]`
                }
            }
            // Filter Profit
            if (this.filter.profitMin !== '') {
                if (this.filter.profitMax !== '') {
                    query += ` @profit:[${this.filter.profitMin} ${this.filter.profitMax}]`
                } else {
                    query += ` @profit:[${this.filter.profitMin} 9999999999]`
                }
            }
            if (this.filter.profitMax !== '') {
                if (this.filter.profitMin !== '') {
                    query += ` @profit:[${this.filter.profitMin} ${this.filter.profitMax}]`
                } else {
                    query += ` @profit:[0 ${this.filter.profitMax}]`
                }
            }
            // Filter EBITDA
            if (this.filter.ebitdaMin !== '') {
                if (this.filter.ebitdaMax !== '') {
                    query += ` @ebitda:[${this.filter.ebitdaMin} ${this.filter.ebitdaMax}]`
                } else {
                    query += ` @ebitda:[${this.filter.ebitdaMin} 9999999999]`
                }
            }
            if (this.filter.ebitdaMax !== '') {
                if (this.filter.ebitdaMin !== '') {
                    query += ` @ebitda:[${this.filter.ebitdaMin} ${this.filter.ebitdaMax}]`
                } else {
                    query += ` @ebitda:[0 ${this.filter.ebitdaMax}]`
                }
            }
            // Filter Sector
            if (JSON.stringify(this.filter.sectors) !== '[]') {
                let sectors = this.filter.sectors.map((sector) => sector.toLowerCase())
                // replace any & with \&
                sectors = sectors.map((sector) => sector.replace('&', '\\&'))
                query += ` @sectors:{${sectors.join('|').toLowerCase()}}`
            }
            // Filter Source
            if (this.filter.sourceId !== '') {
                query += ` @sourceId:{${this.filter.sourceId}}`
            }
            // Filter Status
            // if (JSON.stringify(this.filter.status) !== "[]") {
            // 	query += ` @status:{${this.filter.status.join("|")}}`;
            // }
            return query
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0]
            if (sortDesc) {
                sortDesc = 'DESC'
            } else {
                sortDesc = 'ASC'
            }
            return sortDesc
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1
                console.log('getTableData')
                this.getTableData()
            },
            deep: true
        },
        tableOptions: {
            handler() {
                this.getTableData()
            },
            deep: true
        }
    },
    // methods
    methods: {
        addSite() {
            this.MIX_go('/site/new')
        },
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true
                const filterQuery = this.filterQuery + ` @orgId:{${this.GET_currentUserProfile.orgId}}`
                let dataResult = await this.REDIS_searchFor('site', this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, filterQuery)

                //get the siteIds for the current user
                const userSiteIds = this.GET_currentUserProfile.siteIds

                //only show sites that the user has access to
                this.tableData = dataResult.data.documents.filter((item) => userSiteIds.includes(item.entityId))
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            let lookupResult = await this.REDIS_searchFor('lookup', '', '', 'text', 'asc', '@deleted:{false} @type:{Sector|Subsector}')
            this.sectors = lookupResult.data.documents.filter((item) => item.type === 'Sector')
            this.subsectors = lookupResult.data.documents.filter((item) => item.type === 'Subsector')
            this.getTableData()
            this.loading = false
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        },
        // Get Organisations
        async getOrganisations() {
            try {
                this.loading = true
                let query = `@deleted:{false}`
                if (this.user.orgType !== '') {
                    let organisationResult = await this.REDIS_searchFor('org', '', '', 'name', 'asc', query)
                    this.organisations = organisationResult.data.documents
                } else {
                    this.organisations = []
                }
                this.loading = false
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Getting Organisations', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        updateOrgName() {
            if (this.user.orgId !== '') {
                this.user.orgName = this.organisations.find((item) => item.entityId === this.user.orgId).name
            }
        },
        updateStatus(status) {
            this.user.status = status
            this.saveUser()
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false
            }
            this.filterDrawer = !this.filterDrawer
        },
        next() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
                this.tableOptions.page++
            }
        },
        previous() {
            if (this.tableOptions.page > 1) {
                this.tableOptions.page--
            }
        },
        alertme() {
            alert('Testing')
        },
        filterIgnoreList(keywords) {
            // if any of the keywords are in the ignoreList then filter them out
            const result = []
            for (let i = 0; i < keywords.length; i++) {
                if (!this.ignorekeywordlist.includes(keywords[i])) {
                    result.push(keywords[i])
                }
            }
            return result
        },
        async addIgnoreKeyword(keyword) {
            try {
                await this.REDIS_addIgnoreKeyword(keyword)
                this.MIX_alertBox({ show: true, message: 'Keyword Added to Ignore List', color: 'success', timeout: '2000' })
                let ignorekeywordlistResult = await this.REDIS_getIgnoreKeywordList()
                this.ignorekeywordlist = ignorekeywordlistResult.data
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Adding Keyword', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        viewSite(item) {
            return this.$router.push('/site-dashboard/' + item.entityId)
        },
        async calculate() {
            this.loading = true
            // console.log('startDate: ' + this.startDate)
            // console.log('endDate: ' + this.endDate)
            if (this.startDate === null) {
                this.startDate = this.initStartDate
            }
            if (this.endDate === null) {
                this.endDate = this.initEndDate
            }
            console.log('startDate: ' + this.startDate)
            console.log('endDate: ' + this.endDate)
            this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
            this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
            console.log('startDateUnix: ' + this.startDateUnix)
            console.log('endDateUnix: ' + this.endDateUnix)
            await this.getUsageTotal()
            let conversion = 0
            // Conversion Based on Site Type
            switch ('Grid') {
                case 'Diesel':
                    conversion = this.conversion.diesel_to_co2
                    break
                case 'Grid':
                    conversion = this.conversion.grid_kwh_to_co2
                    break
                case 'HVO':
                    conversion = this.conversion.hvo_kwh_to_co2
                    break
            }
            this.usageTotal_Diesel = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.kWhToDiesel)).toFixed(2))
            this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_Diesel) * parseFloat(conversion)).toFixed(2))
            console.log('usageTotal_Diesel: ' + this.usageTotal_Diesel)
            this.totalCost = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(0.2847) + 2.63 * 7).toFixed(2))
            this.loading = false
        },
        async getUsageTotal() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/usagetotal`
            let timeseries = `ts:TUUVnXkKDObRsiApJFJy:usage:total`
            await this.$axios
                .post(url, {
                    orgId: this.GET_currentUserProfile.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: true,
                    night: true,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    this.usageTotal_kWh = parseFloat(res.data.data.toFixed(2))
                    console.log('total usage = ' + JSON.stringify(res.data.data, null, 2))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // Get the Usage Total By Range
        async getUsageRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let t = this
            // const thirtyMinutesInMilliseconds = 30 * 60 * 1000
            await this.$axios
                .post(url, {
                    orgId: this.GET_currentUserProfile.orgId,
                    timeseries: `ts:TUUVnXkKDObRsiApJFJy:usage:total`,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    // console.log('res data: ' + JSON.stringify(res.data, null, 2))
                    // Day / Night
                    let data = []
                    if (this.day && this.night) {
                        data = res.data.data
                    } else if (this.day && !this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour >= 5 && hour < 18
                        })
                    } else if (!this.day && this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            // Keep all night-time data
                            return hour < 6 || hour >= 17
                        })
                    } else {
                        data = []
                    }

                    const thirtyMinutesInMilliseconds = 1800000
                    t.usageRange = data
                        .slice(1)
                        .map((item, index) => [item.timestamp + thirtyMinutesInMilliseconds, parseFloat(item.value - data[index].value).toFixed(2)])
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    },
    async created() {
        this.startDate = this.initStartDate
        this.endDate = this.initEndDate
        this.MIX_getOrganisations()
        this.initialise()
        // await this.calculate()
        // await this.getUsageRange()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
