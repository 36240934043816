<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Users
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField label="Search Users" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add User needs to work with Firebase Authentication -->
                    <AppButton
                        v-if="GET_currentUserProfile.level === 'Admin' || GET_currentUserProfile.level === 'SuperAdmin'"
                        :disabled="drawer"
                        buttonclass="secondary"
                        @click.native="addUser"
                        >Add User</AppButton
                    >
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">
                    The following page shows all users that have access to the App/Portal, you can use this page to Manage users and access.
                </div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable
                    :tableheaders="filteredHeaders"
                    :loading="loading"
                    custom="users"
                    :tabledata="tableData"
                    :datatableoptions="tableOptions"
                    :itemstotal="itemsTotal"
                    v-on:delete="deleteUser"
                    v-on:edit="editUser"
                    v-on:restore="restoreUser"
                    v-on:tableoptions="updateTableOptions"
                />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Account Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width: 100% !important">
                                    <v-btn title="Approved" value="Approved"
                                        ><v-icon class="success--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn
                                    >
                                    <v-btn title="Pending" value="Pending"
                                        ><v-icon class="warning--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn
                                    >
                                    <v-btn title="Rejected" value="Rejected"
                                        ><v-icon class="error--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn
                                    >
                                    <v-btn title="Suspended" value="Suspended"
                                        ><v-icon class="info--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn
                                    >
                                    <v-btn title="Archived" value="Archived"
                                        ><v-icon class="grey--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn
                                    >
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Edit Window -->
            <v-navigation-drawer width="25%" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
                <!-- Practice Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <div class="text-h5 secondary--text">{{ action }} User</div>
                            <v-spacer />
                            <v-btn icon depressed @click="closeUser"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the form below to edit the User's details
                    </v-col>
                    <v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
                        <div v-if="user.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Approve</AppButton>
                        </div>
                        <div v-if="user.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="error" @click.native="updateStatus('Rejected')">Reject</AppButton>
                        </div>
                        <div v-if="user.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="info" @click.native="updateStatus('Suspended')">Suspend</AppButton>
                        </div>
                        <div v-if="user.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="grey white--text" @click.native="updateStatus('Archived')">Archive</AppButton>
                        </div>
                        <div v-if="user.status === 'Suspended' || user.status === 'Archived'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Restore</AppButton>
                        </div>
                    </v-col>
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">User Details</div> </v-col>
                    <v-col cols="6"> <TextField BackgroundColor="white" label="First Name *" v-model="user.firstName" :validate="validate.firstName" /> </v-col>
                    <v-col cols="6"> <TextField BackgroundColor="white" label="Last Name *" v-model="user.lastName" :validate="validate.lastName" /> </v-col>
                    <v-col cols="12"> <TextField BackgroundColor="white" label="Telephone *" v-model="user.telephone" :validate="validate.telephone" /> </v-col>
                    <v-col cols="12">
                        <TextField v-bind:disabled="this.action !== 'Add'" BackgroundColor="white" label="Email *" v-model="user.email" :validate="validate.email" />
                    </v-col>
                    <v-col cols="12" v-if="this.action === 'Add'"> <PasswordField label="Password" v-model="password" :validate="validate.password" /> </v-col>
                    <v-col cols="12" v-if="this.action === 'Add'">
                        <PasswordField label="Password Confirmation" v-model="passwordConfirm" :validate="validate.passwordConfirm" />
                    </v-col>
                    <v-col cols="12" v-if="this.action === 'Edit'">
                        <AppButton buttonclass="error" class="mr-2" @click.native="passwordResetConfirmBox = true">Send Password Reset</AppButton>
                        <AppButton buttonclass="error" @click.native="passwordUpdateConfirmBox = true">Update Password</AppButton>
                    </v-col>
                </v-row>
                <!-- User Organisation Details -->
                <!-- TODO - User can be assigned to multiple organisations -->
                <v-row dense class="mt-5" v-if="GET_currentUserProfile.level === 'SuperAdmin'">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Organisation Details</div> </v-col>
                    <!--					<v-col cols="12"> <DropdownAdvanced BackgroundColor="white" label="Organisation Type *" v-model="user.orgType" :items="orgTypes" /> </v-col>-->
                    <v-col cols="12">
                        <DropdownAdvanced
                            BackgroundColor="white"
                            label="Organisation *"
                            v-on:change="updateOrgName"
                            v-model="user.orgId"
                            :items="organisations"
                            itemtext="name"
                            itemvalue="entityId"
                        />
                    </v-col>
                </v-row>
                <!--				Site Details-->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Site Access Details</div> </v-col>
                    <!--					<v-col cols="12"> <DropdownAdvanced BackgroundColor="white" label="Organisation Type *" v-model="user.orgType" :items="orgTypes" /> </v-col>-->
                    <v-col cols="12">
                        <v-select
                            v-bind:disabled="user.orgId === ''"
                            solo
                            class="rounded-of"
                            BackgroundColor="white"
                            label="Sites Allowed Access"
                            v-model="user.siteIds"
                            :items="sites"
                            item-text="siteName"
                            item-value="entityId"
                            multiple
                        />
                    </v-col>
                </v-row>
                <!-- User Security Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">App Security Details</div> </v-col>
                    <!-- <v-col cols="12"> <DropdownAdvanced BackgroundColor="white" label="Group" v-model="user.group" :items="userGroups" :validate="validate.group" /> </v-col> -->
                    <v-col cols="12">
                        <DropdownAdvanced BackgroundColor="white" label="UserLevel" v-model="user.level" :items="userLevels" :validate="validate.level" />
                    </v-col>
                    <v-col cols="12">
                        <DropdownAdvanced BackgroundColor="white" label="Status" v-model="user.status" :items="userStatus" :validate="validate.status" />
                    </v-col>
                </v-row>
                <!-- User Other -->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Other</div> </v-col>
                    <v-col cols="12">
                        <DropdownAdvanced BackgroundColor="white" label="Language" v-model="user.language" :items="userLanguages" :validate="validate.language" />
                    </v-col>
                    <v-col cols="12"
                        ><CheckboxField v-model="user.termsAgreed" disabled background="white"> Terms & Conditions (Not Editable)</CheckboxField>
                    </v-col>
                    <v-col cols="12">
                        <CheckboxField v-model="user.optInAgreed" disabled background="white"> Communication Opt-in (Not Editable)</CheckboxField>
                    </v-col>
                </v-row>
                <!-- <pre>{{ user }}</pre> -->
                <!-- Action Buttons -->
                <v-row dense class="mt-5">
                    <v-col cols="6">
                        <div><AppButton block buttonclass="grey" @click.native="closeUser">Cancel</AppButton></div>
                    </v-col>
                    <v-col cols="6">
                        <div><AppButton block buttonclass="secondary" @click.native="saveUser">Save</AppButton></div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ user.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteUser">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>

            <!-- Confirmation Box Password Reset Email-->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="passwordResetConfirmBox" v-on:close="passwordResetConfirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="passwordResetConfirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Are you sure you want to send a password reset to the email below?</p>
                <strong>{{ user.email }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="passwordResetConfirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="error" @click.native="resetUserPassword()">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>

            <!-- Confirmation Box Update Password-->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="passwordUpdateConfirmBox" v-on:close="passwordUpdateConfirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="passwordUpdateConfirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p style="margin-top: -0.5em">Enter the new user Password below</p>
                <v-row>
                    <v-col cols="12"> <PasswordField label="New Password" v-model="userNewPassword" :validate="validate.userNewPassword" /> </v-col>
                    <v-col cols="12">
                        <PasswordField label="Confirm New Password" v-model="userNewPasswordConfirm" :validate="validate.userNewPasswordConfirm" />
                    </v-col>
                </v-row>
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="passwordUpdateConfirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="error" @click.native="updateUserPassword()">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VetsAdmin',
    // data
    data: () => ({
        loading: false,
        drawer: false,
        filterDrawer: false,
        confirmBox: false,
        permenantDelete: false,
        passwordResetConfirmBox: false,
        passwordUpdateConfirmBox: false,
        search: '',
        filter: {
            status: ['Approved', 'Pending'],
            showDeleted: false
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: 'Status', value: 'status', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'ID', value: 'entityId', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'First Name', value: 'firstName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Last Name', value: 'lastName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Job Title', value: 'jobRole', align: 'start', hidden: false, sortable: false, shrunk: false },
            { text: 'Telephone', value: 'telephone', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'Email', value: 'email', align: 'start', hidden: false, sortable: false, shrunk: false },
            // { text: "Group", value: "group", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: 'Org Id', value: 'orgId', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Organisation', value: 'orgName', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Organisation Type', value: 'orgType', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'UserLevel', value: 'level', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Terms Agreed', value: 'termsAgreed', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'Opt In Agreed', value: 'optInAgreed', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'Language', value: 'language', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdUserId', value: 'createdUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdOrgId', value: 'createdOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdDateTime', value: 'createdDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedUserId', value: 'modifiedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifedOrgId', value: 'modifedOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedDateTime', value: 'modifiedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedUserId', value: 'deletedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedOrgId', value: 'deletedOrgId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedDateTime', value: 'deletedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deleted', value: 'deleted', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '110px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['firstName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        validate: {},
        user: {},
        password: '',
        passwordConfirm: '',
        userNewPassword: '',
        userNewPasswordConfirm: '',
        organisations: [],
        sites: [],
        orgTypes: [],
        userStatus: [],
        userGroups: [],
        userLevels: [],
        userLanguages: [],
        action: 'Add',
        showInfo: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.search !== '' && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`
            } else {
                query += `@deleted:{false}`
            }
            // Filter Status
            if (JSON.stringify(this.filter.status) !== '[]') {
                query += ` @status:{${this.filter.status.join('|')}}`
            }
            return query
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0]
            if (sortDesc) {
                sortDesc = 'DESC'
            } else {
                sortDesc = 'ASC'
            }
            return sortDesc
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1
                this.getTableData()
            },
            deep: true
        },
        tableOptions: {
            handler() {
                this.getTableData()
            },
            deep: true
        }
    },
    // methods
    methods: {
        alertme() {
            alert('me')
        },
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true
                let orgQuery = ''
                if (this.GET_currentUserProfile.level === 'Admin') {
                    orgQuery = ` @orgId:{${this.GET_currentUserProfile.orgId}}`
                }
                let dataResult = await this.REDIS_searchFor(
                    'user',
                    this.itemsFrom,
                    this.itemsCount,
                    this.itemsSortBy,
                    this.itemsSortDesc,
                    this.filterQuery + orgQuery
                )
                this.tableData = dataResult.data.documents
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Add User
        addUser() {
            this.user = { ...this.$schema.user }
            this.action = 'Add'
            this.filterDrawer = false
            this.drawer = true
        },
        // Edit User
        editUser(user) {
            this.user = { ...user }
            this.sites = []
            this.action = 'Edit'
            this.getSites()
            this.getOrganisations()
            this.filterDrawer = false
            this.drawer = true
        },
        // Delete User
        deleteUser(user) {
            this.user = { ...user }
            this.action = 'Delete'
            this.confirmBox = true
        },
        // Close ORg
        closeUser(user) {
            this.user = { ...user }
            this.drawer = false
        },
        // Restore User
        async restoreUser(user) {
            try {
                this.loading = true
                this.user = { ...user }
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId
                this.user.deleted = false
                this.user.deletedUserId = null
                this.user.deletedOrgId = null
                this.user.deletedDateTime = null
                await this.REDIS_update('user', this.user.entityId, this.user)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Restored', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Restoring', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Confirm Delete User
        async confirmDeleteUser() {
            try {
                this.loading = true
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId
                this.user.deleted = true
                this.user.deletedUserId = this.GET_currentUserProfile.entityId
                this.user.deletedOrgId = this.GET_currentUserProfile.orgId
                this.user.deletedDateTime = this.MIX_formatDateTimeNow('toISOString')
                await this.REDIS_update('user', this.user.entityId, this.user)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Deleted', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Deleting', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        checkPasswordComplexity(password) {
            if (password.length < 8) {
                return 'Password must be at least 8 characters long.'
            } else if (!/[a-z]/.test(password)) {
                return 'Password must contain at least one lowercase letter.'
            } else if (!/[A-Z]/.test(password)) {
                return 'Password must contain at least one uppercase letter.'
            } else if (!/[0-9]/.test(password)) {
                return 'Password must contain at least one numerical digit.'
            } else if (!/[!@#$%^&*()]/.test(password)) {
                return 'Password must contain at least one special character.'
            } else {
                return 'Password is valid.'
            }
        },
        // Save User
        async saveUser() {
            try {
                this.loading = true
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId
                let validationErrors = null
                validationErrors = 0
                if (this.user.firstName === '') {
                    this.validate.firstName = 'First Name is required'
                    validationErrors++
                }
                if (this.user.lastName === '') {
                    this.validate.lastName = 'Last Name is required'
                    validationErrors++
                }
                if (this.user.email === '') {
                    this.validate.email = 'Email is required'
                    validationErrors++
                }
                if (this.user.telephone === '') {
                    this.validate.telephone = 'Telephone is required'
                    validationErrors++
                }
                if (this.user.status === '') {
                    this.validate.status = 'Status is required'
                    validationErrors++
                }
                if (this.action === 'Add') {
                    if (this.password === '') {
                        this.validate.password = 'Password is required'
                        validationErrors++
                    }

                    if (this.passwordConfirm === '') {
                        this.validate.passwordConfirm = 'Password Confirm is required'
                        validationErrors++
                    }

                    if (this.password !== this.passwordConfirm) {
                        this.validate.passwordConfirm = 'Password and Password Confirm must match'
                        validationErrors++
                    }

                    if (this.checkPasswordComplexity(this.password) !== 'Password is valid.') {
                        this.validate.password = this.checkPasswordComplexity(this.password)
                        validationErrors++
                    }
                }
                // loop through the this.user object and trim all the values
                Object.keys(this.user).forEach((key) => {
                    if (typeof this.user[key] === 'string') {
                        this.user[key] = this.user[key].trim()
                        // remove any . from the end of the string
                        if (this.user[key].slice(-1) === '.') {
                            this.user[key] = this.user[key].slice(0, -1)
                        }
                    }
                })
                // If no validation errors
                if (this.action === 'Add' && validationErrors === 0) {
                    const fullName = this.user.firstName + ' ' + this.user.lastName
                    let firebaseAuthResult = await this.FIREBASE_register(fullName, this.user.email, this.password)
                    console.log('firebaseAuthResult = ', firebaseAuthResult)
                    if (firebaseAuthResult.success) {
                        // console.log('this.GET_currentUserAuth = ', this.GET_currentUserAuth)
                        //if an org admin, set the new user to same org
                        if (this.GET_currentUserProfile.level === 'Admin') {
                            this.user.orgId = this.GET_currentUserProfile.orgId
                        }
                        this.user.createdUserId = this.GET_currentUserProfile.entityId
                        this.user.createdOrgId = this.GET_currentUserProfile.orgId
                        this.user.createdDateTime = this.MIX_formatDateTimeNow('toISOString')
                        this.user.status = 'Pending'
                        // console.log("user = ", JSON.stringify(this.user, null, 2));
                        let createNewUserResult = await this.REDIS_createNewUser(this.user, this.GET_currentUserAuth.uid)
                        // console.log(createNewUserResult);
                        if (createNewUserResult.success) {
                            this.loading = false
                            this.user = { ...this.$schema.user }
                            this.drawer = false
                            this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                            this.getTableData()
                            this.updateOrgName()
                        }
                    } else {
                        if (firebaseAuthResult.error.toString().search('auth/email-already-in-use') !== -1) {
                            console.error('Email Already in Use')
                        } else {
                            console.error('Unknown Account Creation Error')
                        }
                    }
                } else if (this.action === 'Edit') {
                    await this.REDIS_update('user', this.user.entityId, this.user)
                    this.loading = false
                    this.user = { ...this.$schema.user }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    await this.getTableData()
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            let lookupResult = await this.REDIS_searchFor(
                'lookup',
                '',
                '',
                'text',
                'asc',
                '@deleted:{false} @type:{UserStatus|UserLevel|Language|Organisation Type}'
            )
            this.orgTypes = lookupResult.data.documents.filter((item) => item.type === 'Organisation Type')
            this.userStatus = lookupResult.data.documents.filter((item) => item.type === 'UserStatus')
            // this.userGroups = lookupResult.data.documents.filter((item) => item.type === "userGroups");
            this.userLevels = lookupResult.data.documents.filter((item) => item.type === 'UserLevel')
            this.userLanguages = lookupResult.data.documents.filter((item) => item.type === 'Language')
            await this.getTableData()
            this.loading = false
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        },
        // Get Organisations
        async getOrganisations() {
            let orgResult = await this.REDIS_searchFor('org', '', '', '', '', '@deleted:{false}')
            this.organisations = orgResult.data.documents
        },
        async getSites() {
            let query = ''
            if (this.user.orgId) {
                query = `@deleted:{false} @orgId:{${this.user.orgId}}`
            } else {
                query = `@deleted:{false}`
            }
            let siteResult = await this.REDIS_searchFor('site', '', '', '', '', query)
            this.sites = siteResult.data.documents
        },
        updateOrgName() {
            this.user.siteIds = []
            this.sites = []
            setTimeout(() => {
                this.getSites()
            }, 500)
            if (this.user.orgId !== '') {
                this.user.orgName = this.organisations.find((item) => item.entityId === this.user.orgId).name
                return
            }
        },
        updateStatus(status) {
            this.user.status = status
            this.saveUser()
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false
            }
            this.filterDrawer = !this.filterDrawer
        },
        async resetUserPassword() {
            const RESPONSE = await this.FIREBASE_resetPassword(this.user.email)

            if (RESPONSE.hasErrors) {
                //console.error('Error Resetting Password: ', RESPONSE.error)
                this.loading = false
            } else {
                this.loading = false
                this.passwordResetConfirmBox = false
                this.MIX_alertBox({ show: true, message: 'Password Reset Email Sent!', color: 'green', timeout: '4000' })
            }
        },
        //TODO: fix validation not updating on text fields until a user goes to type in it
        async updateUserPassword() {
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line
                ;(async () => {
                    try {
                        let errorCount = 0
                        if (this.checkPasswordComplexity(this.userNewPassword) !== 'Password is valid.') {
                            this.validate.userNewPassword = this.checkPasswordComplexity(this.userNewPassword)
                            errorCount++
                        }
                        if (this.userNewPassword === '') {
                            this.validate.userNewPassword = 'Password required'
                            errorCount++
                        }
                        if (this.userNewPasswordConfirm === '') {
                            this.validate.userNewPasswordConfirm = 'Password confirmation required'
                            errorCount++
                        }
                        if (this.userNewPasswordConfirm !== this.userNewPassword) {
                            this.validate.userNewPasswordConfirm = 'Passwords do not match'
                            errorCount++
                        }

                        if (errorCount === 0) {
                            let data = {}
                            data.userPassword = this.userNewPassword
                            const url = `${this.apiUrl}user/updatefirebaseauth/${this.user.entityId}`
                            let result = await this.$axios.put(url, data)

                            if (result.status === 200) {
                                // console.log(result);
                                resolve(result.data)
                                this.passwordUpdateConfirmBox = false
                            } else {
                                console.log(result)
                                this.passwordUpdateConfirmBox = false
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        this.passwordUpdateConfirmBox = false
                        reject(error)
                    }
                })()
            })
        }
    },
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>

<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
}
</style>
