<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            SitesV2
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField label="Search sites" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add site needs to work with Firebase Authentication -->
                    <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addSite">Add Site</AppButton>
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">
                    The following page shows all sites that have access to the App/Portal, you can use this page to Manage sites and access.
                </div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable
                    :tableheaders="filteredHeaders"
                    :loading="loading"
                    custom="sitesV2"
                    :tabledata="tableData"
                    :datatableoptions="tableOptions"
                    :itemstotal="itemsTotal"
                    v-on:delete="deletesite"
                    v-on:edit="editsite"
                    v-on:copy="copysite"
                    v-on:restore="restoresite"
                    v-on:tableoptions="updateTableOptions"
                    v-on:viewtemps="viewTemps"
                />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Account Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width: 100% !important">
                                    <v-btn title="Approved" value="Approved"
                                        ><v-icon class="success--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn
                                    >
                                    <v-btn title="Pending" value="Pending"
                                        ><v-icon class="warning--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn
                                    >
                                    <v-btn title="Rejected" value="Rejected"
                                        ><v-icon class="error--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn
                                    >
                                    <v-btn title="Suspended" value="Suspended"
                                        ><v-icon class="info--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn
                                    >
                                    <v-btn title="Archived" value="Archived"
                                        ><v-icon class="grey--text">icons8-100-</v-icon
                                        ><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn
                                    >
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Edit Window -->
            <v-navigation-drawer width="400" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
                <!-- Practice Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <div class="text-h5 secondary--text">{{ action }} Site</div>
                            <v-spacer />
                            <v-btn icon depressed @click="closesite"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the form below to edit the site's details
                    </v-col>
                    <v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
                        <div v-if="site.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Approve</AppButton>
                        </div>
                        <div v-if="site.status === 'Pending'" class="flex-grow-1">
                            <AppButton block buttonclass="error" @click.native="updateStatus('Rejected')">Reject</AppButton>
                        </div>
                        <div v-if="site.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="info" @click.native="updateStatus('Suspended')">Suspend</AppButton>
                        </div>
                        <div v-if="site.status === 'Approved'" class="flex-grow-1">
                            <AppButton block buttonclass="grey white--text" @click.native="updateStatus('Archived')">Archive</AppButton>
                        </div>
                        <div v-if="site.status === 'Suspended' || site.status === 'Archived'" class="flex-grow-1">
                            <AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Restore</AppButton>
                        </div>
                    </v-col>
                    <!--					<v-col><v-btn @click="MIX_go('/client/' + site.entityId)">Go</v-btn></v-col>-->
                </v-row>
                <!-- site SiteDetails -->
                <!-- TODO - site can be assigned to multiple Site -->
                <v-row>
                    <v-col cols="12">
                        <v-row dense class="">
                            <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Details</div> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Site Name" v-model="site.siteName" /> </v-col>
                            <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Address</div> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Address Line 1" v-model="site.addressLine1" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Address Line 2" v-model="site.addressLine2" /> </v-col>
                            <v-col cols="12"> <TextField BackgroundColor="white" label="Town" v-model="site.town" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="County" v-model="site.county" /> </v-col>
                            <v-col cols="6"> <TextField BackgroundColor="white" label="Postcode" v-model="site.postcode" /> </v-col>
                            <v-col cols="12"> <DropdownSimple BackgroundColor="white" :items="countries" label="Country" v-model="site.country" /> </v-col>
                            <v-col cols="12">
                                <DropdownAdvanced BackgroundColor="white" :items="orgs" itemtext="name" itemvalue="entityId" label="Site Org" v-model="site.orgId" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="6">
					</v-col> -->
                    <!-- <pre>{{ site }}</pre> -->
                </v-row>

                <!-- <pre>{{ site }}</pre> -->
                <!-- Action Buttons -->
                <v-row dense class="mt-5">
                    <v-col cols="6">
                        <div><AppButton block buttonclass="grey" @click.native="closesite">Cancel</AppButton></div>
                    </v-col>
                    <v-col cols="6">
                        <div><AppButton block buttonclass="primary" @click.native="savesite">Save</AppButton></div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ site.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeletesite">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VetsAdmin',
    // data
    data: () => ({
        loading: false,
        drawer: false,
        filterDrawer: false,
        confirmBox: false,
        permenantDelete: false,
        search: '',
        filter: {
            status: ['Approved', 'Pending'],
            showDeleted: false
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: 'ID', value: 'id', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Site Name', value: 'siteName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Organisation', value: 'orgName', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Type', value: 'type', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Address Line 1', value: 'addressLine1', align: 'start', hidden: true, sortable: false, shrunk: true },
            { text: 'Town', value: 'town', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'Postcode', value: 'postcode', align: 'start', hidden: false, sortable: false, shrunk: true },
            { text: 'createdUserId', value: 'createdUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'createdDateTime', value: 'createdDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedUserId', value: 'modifiedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'modifiedDateTime', value: 'modifiedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedUserId', value: 'deletedUserId', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deletedDateTime', value: 'deletedDateTime', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: 'deleted', value: 'deleted', align: 'start', hidden: true, sortable: false, shrunk: false },
            { text: '', value: 'action', align: 'center', hidden: false, sortable: false, width: '130px', shrunk: true }
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['siteName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        validate: {},
        site: {},
        users: [],
        orgs: [],
        countries: [],
        siteTypes: [],
        siteStatus: [],
        siteGroups: [],
        siteLevels: [],
        siteLanguages: [],
        action: 'Add',
        showInfo: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden)
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk)
            }
            return headers
        },
        filterQuery() {
            let query = ''
            if (this.search !== '' && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`
            } else {
                query += `@deleted:{false}`
            }
            // // Filter Status
            // if (JSON.stringify(this.filter.status) !== "[]") {
            // 	query += ` @status:{${this.filter.status.join("|")}}`;
            // }
            return query
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0]
            if (sortDesc) {
                sortDesc = 'DESC'
            } else {
                sortDesc = 'ASC'
            }
            return sortDesc
        }
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1
                this.getTableData()
            },
            deep: true
        },
        tableOptions: {
            handler() {
                this.getTableData()
            },
            deep: true
        }
    },
    // methods
    methods: {
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true
                let dataResult = await this.REDIS_searchFor('site', this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery)
                // console.log('dataResult', JSON.stringify(dataResult, null, 2))
                this.tableData = dataResult.data.documents
                // console.log("this.tableData", JSON.stringify(this.tableData, null, 2));
                this.itemsTotal = dataResult.data.total
                this.loading = false
            } catch (error) {
                console.error(error)
            }
        },
        // Add site
        addSite() {
            this.MIX_go('/site/new')
        },
        // Edit site
        editsite(site) {
            this.MIX_go(`/site/${site.id}`)
            // this.site = { ...site };
            // this.action = "Edit";
            // this.getUsers();
            // this.filterDrawer = false;
            // this.drawer = true;
        },
        // Copy site
        copysite(site) {
            // copy site.id to clipboard
            navigator.clipboard.writeText(site.id)
            this.MIX_alertBox({ show: true, message: 'Site ID ' + site.id + ' - Copied to clipboard', color: 'success', timeout: '2000' })
        },
        // Delete site
        deletesite(site) {
            this.site = { ...site }
            this.action = 'Delete'
            this.confirmBox = true
        },
        // Close site
        closesite(site) {
            this.site = { ...site }
            this.drawer = false
        },
        // Restore site
        async restoresite(site) {
            try {
                this.loading = true
                this.site = { ...site }
                this.site.modifiedUserId = this.GET_currentUserProfile.entityId
                this.site.modifiedsiteId = this.GET_currentUserProfile.siteId
                this.site.deleted = false
                this.site.deletedUserId = null
                this.site.deletedsiteId = null
                this.site.deletedDateTime = null
                await this.REDIS_update('site', this.site.entityId, this.site)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Restored', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Restoring', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Confirm Delete site
        async confirmDeletesite() {
            try {
                this.loading = true
                this.site.modifiedUserId = this.GET_currentUserProfile.entityId
                this.site.modifiedsiteId = this.GET_currentUserProfile.siteId
                this.site.deleted = true
                this.site.deletedUserId = this.GET_currentUserProfile.entityId
                this.site.deletedsiteId = this.GET_currentUserProfile.siteId
                this.site.deletedDateTime = this.MIX_formatDateTimeNow('toISOString')
                await this.REDIS_update('site', this.site.entityId, this.site)
                this.loading = false
                this.confirmBox = false
                this.permenantDelete = false
                this.MIX_alertBox({ show: true, message: 'Deleted', color: 'success', timeout: '2000' })
                this.getTableData()
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Deleting', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Save site
        async savesite() {
            try {
                this.loading = true
                this.site.modifiedUserId = this.GET_currentUserProfile.entityId
                this.site.modifieDateTime = this.MIX_formatDateTimeNow('toISOString')
                let validationErrors = null
                validationErrors = 0
                // if (this.site.firstName === "") {
                // 	this.validate.firstName = "First Name is required";
                // 	validationErrors++;
                // }
                // if (this.site.lastName === "") {
                // 	this.validate.lastName = "Last Name is required";
                // 	validationErrors++;
                // }
                // if (this.site.email === "") {
                // 	this.validate.email = "Email is required";
                // 	validationErrors++;
                // }
                // if (this.site.telephone === "") {
                // 	this.validate.telephone = "Telephone is required";
                // 	validationErrors++;
                // }
                // if (this.site.status === "") {
                // 	this.validate.status = "Status is required";
                // 	validationErrors++;
                // }
                // loop through the this.site object and trim all the values
                Object.keys(this.site).forEach((key) => {
                    if (typeof this.site[key] === 'string') {
                        this.site[key] = this.site[key].trim()
                        // remove any . from the end of the string
                        if (this.site[key].slice(-1) === '.') {
                            this.site[key] = this.site[key].slice(0, -1)
                        }
                    }
                })
                // If no validation errors
                if (this.action === 'Add' && validationErrors === 0) {
                    this.site.createdUserId = this.GET_currentUserProfile.entityId
                    this.site.createdDateTime = this.MIX_formatDateTimeNow('toISOString')
                    await this.REDIS_create('site', this.site)
                    this.loading = false
                    this.site = { ...this.$schema.site }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getTableData()
                } else if (this.action === 'Edit') {
                    await this.REDIS_update('site', this.site.entityId, this.site)
                    this.loading = false
                    this.site = { ...this.$schema.site }
                    this.drawer = false
                    this.MIX_alertBox({ show: true, message: 'Saved', color: 'success', timeout: '2000' })
                    this.getTableData()
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        // Initialise
        async initialise() {
            this.loading = true
            let lookupResult = await this.REDIS_searchFor('lookup', '', '', 'text', 'asc', '@deleted:{false} @type:{SiteType|Country|SiteStatus}')
            this.siteTypes = lookupResult.data.documents.filter((item) => item.type === 'SiteType')
            this.siteStatus = lookupResult.data.documents.filter((item) => item.type === 'SiteStatus')
            this.countries = lookupResult.data.documents.filter((item) => item.type === 'Country')
            this.getTableData()
            this.getOrgs()
            this.loading = false
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options
        },
        // Get Users
        async getUsers() {
            try {
                this.loading = true
                let query = `@deleted:{false}`
                let usersResult = await this.REDIS_searchFor('user', '', '', 'firstName', 'asc', query)
                let users = usersResult.data.documents
                for (let i = 0; i < users.length; i++) {
                    const user = users[i]
                    user.fullName = user.firstName + ' ' + user.lastName + ' (' + user.email + ')'
                }
                this.users = users
                // console.log("this.users", JSON.stringify(this.users, null, 2));
                this.loading = false
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Getting Users', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        async getOrgs() {
            let orgResult = await this.REDIS_searchFor('org', '', '', '', '', '@deleted:{false}')
            this.orgs = orgResult.data.documents
            // console.log(this.orgs)
        },
        updateStatus(status) {
            this.site.status = status
            this.savesite()
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false
            }
            this.filterDrawer = !this.filterDrawer
        },
        viewTemps(site) {
            this.MIX_go(`/site-temps/${site.id}`)
        }
    },
    created() {
        this.initialise()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
