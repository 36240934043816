<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="">
			<!-- CLIENT DETAILS / PROFILE / NOTES -->
			<v-col cols="12" class="grey lighten-4"  style="height: calc(100vh - 60px) !important; overflow-y: hidden;">
				<v-row dense>
					<v-col cols="12" class="white">
						<div class="text-h5 primary--text d-flex px-5 py-5">
							Portal:
							<span class="font-weight-bold" v-if="$route.params.id === 'new'">New</span>
							<span class="font-weight-bold" v-else>{{ portal?.portalName }}</span>
						</div>
						<v-tabs background-color="grey darken-2" dark v-model="portalTab">
							<v-tab href="#details"> Details </v-tab>
							<!-- <v-tab href="#notes"> Notes </v-tab> -->
							<!-- DETAILS -->
							<v-tab-item value="details" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll;">
								<v-row class="">
									<v-col cols="6">
										<v-row dense>
											<v-col cols="12" class="d-flex align-center">
												<div class="mt-4 text-h7 font-weight-bold primary--text">PORTAL</div>
											</v-col>
											
											<v-col cols="12"><v-divider  class="mb-2"/></v-col>
											<v-col cols="12"> <TextField BackgroundColor="white" label="Portal Name *" v-model="portal.portalName" :validate="validate.portalName" /> </v-col>
											<v-col cols="12"> <TextField BackgroundColor="white" label="Support Email*" v-model="portal.portalEmail" :validate="validate.portalEmail" /> </v-col>
											<v-col cols="12"> <TextField BackgroundColor="white" label="Support Telephone No *" v-model="portal.portalTelephone" :validate="validate.portalTelephone" /> </v-col>
											<v-col cols="12"> <DropdownAdvanced BackgroundColor="white" :items="users" itemtext="userDetails" itemvalue="entityId" label="Portal Owner" v-model="portal.ownerUserId" /> </v-col>										
											<v-col cols="12" class="d-flex align-center">
												<div class="mt-4 text-h7 font-weight-bold primary--text">DOMAINS</div>
											</v-col>
											
											<v-col cols="12">
												<div class="mx-6">
													<ul v-if="JSON.stringify(portal.portalDomains) !== '[]'">
														<li v-for="domain in portal.portalDomains" :key="domain">{{ domain }}</li>
													</ul>
												</div>
											</v-col>
											<v-col cols="12" class="d-flex"> <TextField BackgroundColor="white" label="Add Domain Name *" v-model="newPortalDomain" /> <AppButton buttonclass="secondary" @click.native="addDomainName()">Add</AppButton></v-col>
										</v-row>
									</v-col>
									<v-col cols="6">
										<v-row dense>
											<v-col cols="12" class="d-flex align-center">
												<div class="mt-4 text-h7 font-weight-bold primary--text">BRANDING</div>
											</v-col>
											<v-col cols="12"><v-divider  class="mb-2"/></v-col>
											<v-col cols="6" class="d-flex"> <TextField BackgroundColor="white" label="Primary Colour *" v-model="portal.primary" :validate="validate.primary" /> <v-icon class="mr-2" :style="{ color: portal.primary }">icons8-filled-circle</v-icon></v-col>
											<v-col cols="6" class="d-flex"> <TextField BackgroundColor="white" label="Secondary Colour *" v-model="portal.secondary" :validate="validate.secondary" /> <v-icon class="mr-2" :style="{ color: portal.secondary }">icons8-filled-circle</v-icon></v-col>
											<v-col cols="6" class="d-flex"> <TextField BackgroundColor="white" label="Accent Colour *" v-model="portal.accent" :validate="validate.accent" /> <v-icon class="mr-2" :style="{ color: portal.accent }">icons8-filled-circle</v-icon></v-col>
											<v-col cols="6" class="d-flex"> <TextField BackgroundColor="white" label="Accent 2 Colour *" v-model="portal.accent2" :validate="validate.accent2" /> <v-icon class="mr-2" :style="{ color: portal.accent2 }">icons8-filled-circle</v-icon></v-col>
											<!-- LOGO -->
											<v-col cols="12" class="d-flex align-center">
												<div class="mt-4 text-h7 font-weight-bold primary--text">LOGO</div>
												<v-spacer />
											</v-col>
											<v-col cols="12"> <TextField BackgroundColor="white" label="Logo Image URL *" v-model="portal.imageURL" :validate="validate.imageURL" /> </v-col>
											<v-col cols="12"> <TextField BackgroundColor="white" label="Report Image URL *" v-model="portal.reportImageURL" :validate="validate.reportImageURL" /> </v-col>
											<v-col cols="12" class="d-flex align-center" v-if="$route.params.id !== 'new'">
												<div class="mt-4 text-h7 font-weight-bold primary--text">METADATA</div>
											</v-col>
											<v-col cols="12" v-if="$route.params.id !== 'new'"><v-divider  class="mb-2"/></v-col>											
											<v-col cols="12"  v-if="$route.params.id !== 'new'"> 
												<div class="grey lighten-2 caption pa-5">
													ID: {{ portal.entityId }}<br />
													Created: {{ MIX_findUserName(portal.createdUserId) }} | {{ $dayjs(portal.createdDateTime).format('DD MMM YY HH:mm:ss') }}<br />
													Modified: {{ MIX_findUserName(portal.modifiedUserId) }} | {{ $dayjs(portal.modifiedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
													<div v-if="portal.deleted">
														Deleted: {{ MIX_findUserName(portal.deletedUserId) }} | {{ $dayjs(portal.deletedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
													</div>
												</div>
											</v-col>

											
										</v-row>												
									</v-col>
								</v-row>
								<v-row dense class="my-4 justify-end">
									<v-col cols="2" class="d-flex justify-end align-center">
										<div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="grey" @click.native="cancelPortal()">Cancel</AppButton></div>
										<div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="secondary" @click.native="savePortal()">Save</AppButton></div>
									</v-col>
								</v-row>
								<!-- <v-row>
									<v-col cols="12">
										<pre>{{ portal }}</pre>
									</v-col>
								</v-row> -->
							</v-tab-item>
						</v-tabs>
					</v-col>
				</v-row>
			</v-col>

			<!-- CONFIRMATION BOX -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Vet</p>
				<strong>{{ portal.portalName }}</strong
				><br />
				<!-- permenantDelete : {{ permenantDelete }}<br /> -->
				<!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "ClientView",
	// data
	data: () => ({
		loading: false,
		// LOOKUPS
		portalStatus: [],
		confirmBox: false,
		
		// PORTAL RELATED
		portalTab: "details",
		portal: {},
		validate: {},
		newPortalDomain: ""
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filteredMatchesByStatus() {
			if (JSON.stringify(this.matchStatus) !== '[]') {
				return this.matches.filter((match) => this.matchStatus.includes(match.status));
			} else {
				return this.matches;
			}
		},
		computedClientId() {
			return this.$route.params.id;
		},
	},
	// * METHODS
	methods: {
		// Initialise
		async initialise() {
			this.loading = true;
			// Get Users
			try {
				await this.MIX_getUsers();
			} catch (error) {
				console.error(error);
			}
			// Get Portal
			if (this.$route.params.id !== 'new') {
				let portalResult = await this.REDIS_read("portal", this.$route.params.id);
				this.portal = portalResult.data;
				// Get Portal Profile
			} else {
				this.portal = { ...this.$schema.portal };
			}
			this.loading = false;
		},
		// Save Portal
		async savePortal(){
			try {
				let portal = { ...this.portal };
				portal.modifiedUserId = this.GET_currentUserProfile.entityId;
				portal.modifiedDateTime = this.$dayjs().unix();
				portal.deleted = false;
				portal.deletedDateTime = null;				
				portal.deletedUserId = null;
				let result = null;
				if (this.$route.params.id === 'new') {
					portal.id = this.MIX_generateId();
					portal.createdUserId = this.GET_currentUserProfile.entityId;
					portal.createdDateTime = this.$dayjs().unix();
					result = await this.REDIS_createWithId('portal',portal, portal.id);
				} else {
					result = await this.REDIS_update('portal',portal.id, portal);
				}
				if (result.success) {
					this.MIX_alertBox({ show: true, message: "Portal Saved", color: "success", timeout: "2000" });
					this.$router.push('/portals')
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving Portal", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		// // Get Users
		// async getUsers(){
		// 	let userResult = await this.REDIS_searchFor('user','','','','','@deleted:{false}')
		// 	let users = userResult.data.documents;
		// 	this.users = users.map((user) => {
		// 		return {
		// 			...user,
		// 			userName: user.firstName + ' ' + user.lastName,
		// 			userDetails: user.firstName + ' ' + user.lastName + ' (' + user.email + ')'
		// 		}
		// 	})
		// },
		addDomainName() {
			this.portal.portalDomains.push (this.newPortalDomain);
		},
	},
	// * WATCH
	watch: {
		
	},
	// * CREATED
	created() {
		this.initialise();
	},
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
	box-shadow: none !important;
}
</style>
