<template>
    <div>
        <div v-if="loading" :style="`height: ${height}px !important;`" class="d-flex align-center justify-center flex-column">
            <v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
            <div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
        </div>
        <div v-else><v-chart :option="option" class="chart" :style="`height: ${height}px !important;`" /></div>
        <!--        <pre>{{ datadown }}</pre>-->
    </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { GridComponent } from 'echarts/components'
import { TitleComponent } from 'echarts/components'
import { DataZoomComponent } from 'echarts/components'
import { ToolboxComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { ref, defineComponent } from 'vue'

use([TitleComponent, GridComponent, CanvasRenderer, LineChart, DataZoomComponent, ToolboxComponent])

export default defineComponent({
    name: 'LineChart',
    data: (vm) => ({
        mydata: [],
        mydates: [],
        option: ref({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%']
                },
                formatter: function (params) {
                    // Assuming the date is the first parameter
                    const date = params[0].data[0]
                    var formattedDate = vm.$dayjs(date).format('DD-MMM-YY HH:mm')
                    return `<span class='font-weight-bold primary--text'>${vm.yaxislabel}: </span>
            ${new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(params[0].data[1])}
                                    ${vm.yaxisunit} <br/>
                                    <span class='font-weight-normal caption'>${formattedDate}</span><br/>`
                }
            },
            grid: {
                containLabel: true,
                left: 50,
                right: 50,
                top: 40,
                bottom: 70
            },
            toolbox: {
                show: !vm.print,
                feature: {
                    dataView: {
                        show: true,
                        title: 'data view'

                    },
                    dataZoom: {
                        yAxisIndex: false
                    },
                    saveAsImage: {
                        pixelRatio: 2
                    }
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: !vm.print,
                    xAxisIndex: [0],
                    start: 1,
                    end: 100,
                    bottom: 30
                }
            ],
            xAxis: {
                name: vm.xaxislabel,
                type: 'time',
                // data: vm.mydates,
                nameLocation: 'middle', // Position of the title
                nameGap: 70, // Gap between the axis and the title
                axisLabel: {
                    fontSize: 14
                }
            },
            yAxis: {
                name: vm.yaxislabel, // Title Value
                type: 'value', // Axis type
                nameLocation: 'middle', // Position of the title
                nameGap: 60, // Gap between the axis and the title
                nameRotate: 90, // Rotate the title (optional)
                // boundaryGap: [0, '100%'],
                axisLabel: {
                    fontSize: 14
                }
            },

            series: [
                {
                    name: '',
                    type: 'line',
                    symbol: 'none',
                    sampling: 'lttb',
                    connectNulls: false,
                    data: vm.mydata
                }
            ]
        })
    }),
    components: {
        VChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        xaxislabel: {
            type: String,
            default: ''
        },
        yaxislabel: {
            type: String,
            default: ''
        },
        yaxisunit: {
            type: String,
            default: ''
        },
        datadown: {
            type: Array,
            default: () => []
        },
        height: {
            type: Number,
            default: 500
        },
        print: {
            type: Boolean,
            default: false
        }
    },
    computed: {},
    provide: {
        [THEME_KEY]: 'light'
    },
    watch: {
        datadown: {
            handler: function (val) {
                this.option.series[0].data = val
                if (this.yaxisunit === 'kWh') {
                    this.option.series[0].areaStyle = { opacity: 0.3 }
                }
            },
            deep: true
        }
    },
    created() {
        this.option.series[0].data = this.datadown
        if (this.yaxisunit === 'kWh') {
            this.option.series[0].areaStyle = { opacity: 0.3 }
        }
    }
})
</script>

<style scoped>
.chart {
    width: 100% !important;
}
</style>
