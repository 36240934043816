<template>
    <div class="pb-10">
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="6" xs="12" sm="4" md="8" lg="6" class="">
                <div class="white lighten-3 d-flex align-center justify-center pa-5">
                    <img src="@/assets/gaia_blue.png" height="100" alt="AutomateLogo" />
                </div>
            </v-col>
        </v-row>
                <v-row class="px-5">
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                        <BasicCard style="height: 100% !important">
                            <div class="pa-5">
                                <p class='text-h5 primary--text'>Hi {{ GET_currentUserProfile.firstName }}, </p>

                                <p>We are thrilled to welcome you to the Automate Reporting System. This
                                    portal
                                is your gateway to access comprehensive, real-time data and reports that will help you make informed decisions.</p>

                                <p>Here, you'll find user-friendly tools and resources designed to enhance your understanding and analysis of the data. The Automate
                                    platform is dedicated to providing you with the most accurate and up-to-date information.</p>
                                
                                        <p>Thank you for choosing Automate from Gaia. We look forward to supporting your journey towards data-driven excellence.</p>
                            </div>
                        </BasicCard>
                    </v-col>
                </v-row>

<!--        <v-row class="px-5">-->
<!--            <v-col cols="12" xs="12" sm="4" md="4" lg="3">-->
<!--                <BasicCard style="height: 100% !important" @click.native="MIX_go('/sites')">-->
<!--                    <div class="pa-5 d-flex align-start">-->
<!--                        <div><v-icon class="text-h3">icons8-organization</v-icon></div>-->
<!--                        <div class="ml-3">-->
<!--                            <div class="text-h6 font-weight-light primary&#45;&#45;text">My Organisation</div>-->
<!--                            <div class="text-body-2">Click to see your Organisation, Sites, Projects and data</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </BasicCard>-->
<!--            </v-col>-->
<!--            <v-col cols="12" xs="12" sm="4" md="4" lg="3">-->
<!--                <BasicCard style="height: 100% !important" @click.native="MIX_go('/clients')">-->
<!--                    <div class="pa-5 d-flex align-start">-->
<!--                        <div><v-icon class="text-h3">icons8-business-building</v-icon></div>-->
<!--                        <div class="ml-3">-->
<!--                            <div class="text-h6 font-weight-light primary&#45;&#45;text">Organisations</div>-->
<!--                            <div class="text-body-2">-->
<!--                                The organisations section provides a platform to efficiently manage clients and their corresponding match profiles.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </BasicCard>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-row class="px-5">-->
<!--            <v-col cols="12" xs="12" sm="4" md="4" lg="3">-->
<!--                <BasicCard style="height: 100% !important" @click.native="MIX_go('/users')">-->
<!--                    <div class="pa-5 d-flex align-start">-->
<!--                        <div><v-icon class="text-h3">icons8-staff</v-icon></div>-->
<!--                        <div class="ml-3">-->
<!--                            <div class="text-h6 font-weight-light primary&#45;&#45;text">Users</div>-->
<!--                            <div class="text-body-2">-->
<!--                                In the Users section of Automate, you can easily manage and approve users. This feature allows you to efficiently handle user-->
<!--                                accounts, granting access to the platform.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </BasicCard>-->
<!--            </v-col>-->
<!--            <v-col cols="12" xs="12" sm="4" md="4" lg="3">-->
<!--                <BasicCard style="height: 100% !important" @click.native="MIX_go('/site-admin')">-->
<!--                    <div class="pa-5 d-flex align-start">-->
<!--                        <div><v-icon class="text-h3">icons8-building</v-icon></div>-->
<!--                        <div class="ml-3">-->
<!--                            <div class="text-h6 font-weight-light primary&#45;&#45;text">Site Management</div>-->
<!--                            <div class="text-body-2">-->
<!--                                The Sites section in Automate provides an administrative function that enables users to monitor the status of each site and view the-->
<!--                                last feed obtained.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </BasicCard>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <!--        <v-row class="px-5">-->
        <!--            <v-col cols="6" xs="12" sm="4" md="8" lg="6" class="">-->
        <!--                <div class="font-weight-light primary&#45;&#45;text text-h6">Release Notes</div>-->
        <!--                <v-expansion-panels class="rounded-of elevation-0" flat accordion>-->
        <!--                    <v-expansion-panel dense>-->
        <!--                        <v-expansion-panel-header class="white font-weight-bold">-->
        <!--                            <span class="font-weight-bold primary&#45;&#45;text">16 May 23 - V0.1 - Development Release</span>-->
        <!--                        </v-expansion-panel-header>-->
        <!--                        <v-expansion-panel-content class="white">-->
        <!--                            <p class="font-weight-light primary&#45;&#45;text">Features</p>-->
        <!--                            <ul class="grey&#45;&#45;text text&#45;&#45;darken-2">-->
        <!--                                <li>User Management</li>-->
        <!--                                <li>Org Management</li>-->
        <!--                                <li>Site Management</li>-->
        <!--                            </ul>-->
        <!--                            <p class="mt-3 font-weight-light primary&#45;&#45;text">Known Issues</p>-->
        <!--                            <ul class="grey&#45;&#45;text text&#45;&#45;darken-2">-->
        <!--                                <li>Responsive View - Not yet optimised for mobile / tablet</li>-->
        <!--                            </ul>-->
        <!--                            <p class="mt-3 font-weight-light primary&#45;&#45;text">Coming Soon</p>-->
        <!--                            <ul class="grey&#45;&#45;text text&#45;&#45;darken-2">-->
        <!--                                <li>Site Dashboard</li>-->
        <!--                            </ul>-->
        <!--                            <p class="mt-3 font-weight-light primary&#45;&#45;text"></p>-->

        <!--                            <p></p>-->
        <!--                        </v-expansion-panel-content>-->
        <!--                    </v-expansion-panel>-->
        <!--                </v-expansion-panels>-->
        <!--            </v-col>-->
        <!--        </v-row>-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'HomeView',
    // data
    data: () => ({
        loading: false,
        releasenote: 0
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        })
    },
}
</script>
