import { mapGetters } from 'vuex'

const mixin = {
    data: () => ({
        MIX_nav: [
            // Project Nav (Admin)
            {
                title: 'Home',
                path: '/',
                icon: 'icons8-home',
                orgType: [''],
                level: ['SuperAdmin', 'Admin', 'Manager', 'User'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Organisation',
                path: '/sites',
                icon: 'icons8-building-3',
                orgType: [''],
                level: ['SuperAdmin', 'Admin', 'Manager', 'User'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Sites',
                path: '/site-admin',
                icon: 'icons8-map-2',
                orgType: [''],
                level: ['SuperAdmin', 'Admin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Organisations',
                path: '/organisation-admin',
                icon: 'icons8-hierarchy',
                orgType: [''],
                level: ['SuperAdmin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Projects',
                path: '/project-admin',
                icon: 'icons8-flow-2',
                orgType: [''],
                level: ['SuperAdmin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Users',
                path: '/users/',
                icon: 'icons8-staff',
                orgType: [''],
                level: ['SuperAdmin', 'Admin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Portals',
                path: '/portals/',
                icon: 'icons8-window-cloud',
                orgType: [''],
                level: ['SuperAdmin', 'Admin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            {
                title: 'Lookups',
                path: '/admin/',
                icon: 'icons8-list',
                orgType: [''],
                level: ['SuperAdmin'],
                status: ['Approved'],
                enabled: true,
                nav: ['SideNav']
            },
            // Nav to Sites copy
            // {
            //     title: 'Sites Copy',
            //     path: '/site-adminV2',
            //     icon: 'icons8-map-2',
            //     orgType: [''],
            //     level: ['SuperAdmin', 'Admin'],
            //     status: ['Approved'],
            //     enabled: true,
            //     nav: ['SideNav']
            // },
        ]
    }),
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
            GET_userAuthenticated: 'GET_userAuthenticated'
        })
    },
    methods: {
        MIX_filteredNav(nav) {
            if (this.GET_userAuthenticated) {
                return this.MIX_nav.filter((item) => {
                    return item.orgType.includes(this.GET_currentUserProfile.orgType) && item.nav.includes(nav)
                })
            } else {
                return []
            }
        },
        // CHeck UserLevel and Status
        MIX_checkLevelAndStatus(item) {
            // console.log('item = ' + JSON.stringify(item, null, 2))
            if (item.enabled) {
                if (this.GET_userAuthenticated) {
                    if (item.status !== undefined && item.level !== undefined) {
                        let checkError = 0
                        if (!item.status.includes(this.GET_currentUserProfile.status)) {
                            checkError++
                        }
                        if (item.level.includes(this.GET_currentUserProfile.level)) {
                            checkError++
                        }
                        if (checkError === 0) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return true
                    }
                }
            } else {
                return true
            }
        }
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin)
    }
}
