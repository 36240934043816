<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5">
			<v-col cols="12">
				<div class="d-flex align-center">
					<div>
						<div class="text-h4 primary--text d-flex">
							Portals
							<v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
						</div>
					</div>
					<v-spacer />
					<!-- Search -->
					<SearchField label="Search Portals" v-model="search" :disabled="loading" :dense="true" />
					<AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
					<!-- TODO - Invite Button required -->
					<!-- TODO - Add Portal needs to work with Firebase Authentication -->
					<AppButton :disabled="drawer" buttonclass="secondary" @click.native="addPortal">Add Portal</AppButton>
					<div class="white--text">
						<DropdownMenu>
							<div class="d-flex align-center">
								<v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
								<div class="font-weight-bold secondary--text">Show Deleted</div>
							</div>
						</DropdownMenu>
					</div>
				</div>
				<div class="text--secondary pt-4" v-if="showInfo">The following page shows all orgs that have access to the App/Portal, you can use this page to Manage orgs and access.</div>
			</v-col>
			<!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
			<!-- Table -->
			<v-col cols="12" class="pt-2">
				<!-- Data Table -->
				<DataTable :tableheaders="filteredHeaders" :loading="loading" custom="orgs" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deletePortal" v-on:edit="editPortal" v-on:restore="restorePortal" v-on:tableoptions="updateTableOptions" />
			</v-col>
			<!-- Confirmation Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Portal</p>
				<strong>{{ portal.name }}</strong
				><br />
				<!-- permenantDelete : {{ permenantDelete }}<br /> -->
				<!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeletePortal">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "VetsAdmin",
	// data
	data: () => ({
		loading: false,
		drawer: false,
		filterDrawer: false,
		confirmBox: false,
		permenantDelete: false,
		search: "",
		filter: {
			status: ["Approved", "Pending"],
			showDeleted: false,
		},
		tableData: [],
		itemsTotal: 0,
		tableHeaders: [
			{ text: "Name", value: "portalName", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "ID", value: "id", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Support Email", value: "portalEmail", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Support Telephone", value: "portalTelephone", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Domains", value: "portalDomains", align: "start", hidden: false, sortable: false, shrunk: true },

			{ text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
		],
		tableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["portalName"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
		validate: {},
		portal: [],
		portals: [],
		countries: [],
		portalTypes: [],
		portalStatus: [],
		portalGroups: [],
		portalLevels: [],
		portalLanguages: [],
		action: "Add",
		showInfo: false,
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filterQuery() {
			let query = "";
			if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
				query += `${this.search}*`;
			}
			if (this.filter.showDeleted) {
				query += ` @deleted:{true}`;
			} else {
				query += `@deleted:{false}`;
			}
			// // Filter Status
			// if (JSON.stringify(this.filter.status) !== "[]") {
			// 	query += ` @status:{${this.filter.status.join("|")}}`;
			// }
			return query;
		},
		itemsFrom() {
			return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
		},
		itemsTo() {
			if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
				return this.itemsTotal;
			} else {
				return this.tableOptions.page * this.tableOptions.itemsPerPage;
			}
		},
		itemsCount() {
			return this.tableOptions.itemsPerPage;
		},
		itemsSortBy() {
			let sortBy = this.tableOptions.sortBy;
			if (sortBy.length > 0) {
				sortBy = sortBy.map((x) => x);
				sortBy = sortBy.join(",");
			}
			return sortBy;
		},
		itemsSortDesc() {
			let sortDesc = this.tableOptions.sortDesc[0];
			if (sortDesc) {
				sortDesc = "DESC";
			} else {
				sortDesc = "ASC";
			}
			return sortDesc;
		},
	},
	// watch
	watch: {
		filterQuery: {
			handler() {
				this.tableOptions.page = 1;
				this.getTableData();
			},
			deep: true,
		},
		tableOptions: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
	},
	// methods
	methods: {
		// Get Table Data
		async getTableData() {
			try {
				this.loading = true;
				console.log("this.filterQuery", this.filterQuery)
				let dataResult = await this.REDIS_searchFor("portal", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
				this.tableData = dataResult.data.documents;
				// console.log("this.tableData", JSON.stringify(this.tableData, null, 2));
				this.itemsTotal = dataResult.data.total;
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		// Add Portal
		addPortal() {
			this.MIX_go('/portal/new');
		},
		// Edit Portal
		editPortal(portal) {
			this.MIX_go(`/portal/${portal.id}`);
			// this.portal = { ...portal };
			// this.action = "Edit";
			// this.getPortals();
			// this.filterDrawer = false;
			// this.drawer = true;
		},
		// Delete Portal
		deletePortal(portal) {
			this.portal = { ...portal };
			this.action = "Delete";
			this.confirmBox = true;
		},
		// Close ORg
		closePortal(portal) {
			this.portal = { ...portal };
			this.drawer = false;
		},
		// Restore Portal
		async restorePortal(portal) {
			try {
				this.loading = true;
				this.portal = { ...portal };
				this.portal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.portal.deleted = false;
				this.portal.deletedUserId = null;
				this.portal.deletedDateTime = null;
				await this.REDIS_update("portal", this.portal.entityId, this.portal);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Confirm Delete Portal
		async confirmDeletePortal() {
			try {
				this.loading = true;
				this.portal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.portal.deleted = true;
				this.portal.deletedUserId = this.GET_currentUserProfile.entityId;
				this.portal.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
				await this.REDIS_update("org", this.portal.entityId, this.org);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Save Portal
		async savePortal() {
			try {
				this.loading = true;
				this.portal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.portal.modifieDateTime = this.MIX_formatDateTimeNow("toISOString");
				let validationErrors = null;
				validationErrors = 0;
				// if (this.portal.firstName === "") {
				// 	this.validate.firstName = "First Name is required";
				// 	validationErrors++;
				// }
				// if (this.portal.lastName === "") {
				// 	this.validate.lastName = "Last Name is required";
				// 	validationErrors++;
				// }
				// if (this.portal.email === "") {
				// 	this.validate.email = "Email is required";
				// 	validationErrors++;
				// }
				// if (this.portal.telephone === "") {
				// 	this.validate.telephone = "Telephone is required";
				// 	validationErrors++;
				// }
				// if (this.portal.status === "") {
				// 	this.validate.status = "Status is required";
				// 	validationErrors++;
				// }
				// loop through the this.org object and trim all the values
				Object.keys(this.org).forEach((key) => {
					if (typeof this.org[key] === "string") {
						this.org[key] = this.org[key].trim();
						// remove any . from the end of the string
						if (this.org[key].slice(-1) === ".") {
							this.org[key] = this.org[key].slice(0, -1);
						}
					}
				});
				// If no validation errors
				if (this.action === "Add" && validationErrors === 0) {
					this.portal.createdUserId = this.GET_currentUserProfile.entityId;
					this.portal.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
					await this.REDIS_create("org", this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else if (this.action === "Edit") {
					await this.REDIS_update("org", this.portal.entityId, this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else {
					this.loading = false;
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Initialise
		async initialise() {
			this.loading = true;
			this.getTableData();
			this.loading = false;
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		// Get Portals
		async getPortals() {
			try {
				this.loading = true;
				let query = `@deleted:{false}`;
				let portalsResult = await this.REDIS_searchFor("user", "", "", "firstName", "asc", query);
				let portals = portalsResult.data.documents;
				for (let i = 0; i < portals.length; i++) {
					const user = portals[i];
					user.fullName = user.firstName + " " + user.lastName + " (" + user.email + ")";
				}
				this.portals = portals;
				console.log("this.portals", JSON.stringify(this.portals, null, 2));
				this.loading = false;
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Getting Portals", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		updateStatus(status) {
			this.portal.status = status;
			this.savePortal();
		},
		toggleFilters() {
			if (this.drawer) {
				this.drawer = false;
			}
			this.filterDrawer = !this.filterDrawer;
		},
	},
	created() {
		this.initialise();
	},
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}
</style>
