<template>
    <v-app>
        <!-- Navigation Drawer -->
        <!-- <NavDrawer v-if="$vuetify.breakpoint.xsOnly && !$route.meta.hideNavbar" /> -->
        <!-- Top Navigation -->
        <TopNav v-if="GET_userAuthenticated && !$route.meta.hideNavbar" class="noprint" />
        <!-- No Connection Banner -->
        <NoConnection v-if="noconnection" class="noprint" />
        <!-- No Database Banner -->
        <NoCloud v-if="GET_noCloud" class="noprint" />
        <!-- Alert Box -->
        <!-- Terms and Conditions -->
        <TermsConditions class="noprint" />
        <!-- Main Content -->
        <v-main class="background">
            <!-- App Update Banner -->
            <AppUpdate class="noprint" style="z-index: 99999 !important" />
            <!-- Side Navigation Bar - Tablet and Desktop Only -->
            <SideNav v-if="$vuetify.breakpoint.mdAndUp && GET_userAuthenticated && !$route.meta.hideNavbar" class="noprint" />
            <transition
                enter-active-class="animate__animated animate__fadeIn animate__faster"
                leave-active-class="animate__animated animate__fadeOut animate__faster"
                mode="out-in"
            >
                <!-- Maintenance Block -->
<!--                <v-dialog v-if="maintenance" v-model="maintenance" style="z-index: 99999 !important ; height: 100% !important; width: 50vw !important">-->
<!--                    <v-card class="d-flex align-center justify-center text-center" height="400">-->
<!--                        <div>-->
<!--                            <img src="@/assets/gaia_blue.png" height="100" /><br /><br />-->

<!--                            22-Jan-2024 - The Automate Portal is currently undergoing routine maintenance. Please check back later .-->
<!--                        </div>-->
<!--                    </v-card>-->
<!--                </v-dialog>-->
                <!-- Router View -->
                <router-view />
            </transition>
        </v-main>
        <!-- Semi - Transparent Blurred Footer -->
        <div class="noprint footerbanner frontend-low" v-if="$vuetify.breakpoint.xsOnly && GET_userAuthenticated && !$route.meta.hideNavbar"></div>
        <!-- Bottom Navigation -->
        <BottomNav v-if="$vuetify.breakpoint.xsOnly && GET_userAuthenticated && !$route.meta.hideNavbar" class="noprint" />
        <AlertBox class="noprint" />
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import TopNav from './components/navigation/TopNav.vue'
// import NavDrawer from "./components/navigation/NavDrawer.vue";
import SideNav from './components/navigation/SideNav.vue'
import BottomNav from './components/navigation/BottomNav.vue'
import AppUpdate from './components/general/AppUpdate.vue'
import NoConnection from './components/general/NoConnection.vue'
import NoCloud from './components/general/NoCloud.vue'
export default {
    name: 'App',
    components: {
        TopNav,
        // NavDrawer,
        SideNav,
        BottomNav,
        AppUpdate,
        NoConnection,
        NoCloud
    },

    data: () => ({
        noconnection: false,
        maintenance: false
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_currentUserAuth: 'GET_currentUserAuth',
            GET_userAuthenticated: 'GET_userAuthenticated',
            GET_noCloud: 'GET_noCloud'
        })
    },
    methods: {},
    created() {},
    mounted() {
        console.log('apiUrl: ' + process.env.VUE_APP_API_URL)
    }
}
</script>

<style>
/* WIDTH / HEIGHT */
.full-width {
    width: 100% !important;
}
.half-width {
    width: 50% !important;
}
.full-height {
    height: 100% !important;
}
.full-viewpoint-width {
    width: 100vw !important;
}
.full-viewpoint-height {
    height: 100vh !important;
}
/* REMOVE BANNER LINE */
.v-banner__wrapper {
    border-bottom: 0px !important;
}
/* POSITIONING */
.fixed {
    position: fixed !important;
}
/* Z-INDEX HIERARCHY */
.foreground-highest {
    z-index: 99999 !important;
}
.foreground-high {
    z-index: 9999 !important;
}
.foreground-medium {
    z-index: 999 !important;
}
.foreground-low {
    z-index: 99 !important;
}
/* ROUNDED CORNERS */
.rounded-of {
    border-radius: 5px !important;
}
.rounded-of-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.rounded-of-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.footerbanner {
    bottom: 0px;
    width: 100vw !important;
    position: fixed !important;
    margin-bottom: 100px !important;
    height: 30px !important;
    background: -moz-linear-gradient(bottom, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.v-item-group {
    margin-top: 5px !important;
}
a {
    text-decoration: none !important;
}
.pointer {
    cursor: pointer !important;
}

@media print {
    .noprint {
        display: none !important;
    }
}
</style>
