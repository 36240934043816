<template>
    <!-- <div>
		dateMenu: {{ dateMenu }}<br />
		date1: {{ date1 }}<br />
		computedDate: {{ computedDate }}<br />
		innerValue: {{ innerValue }}<br />
		validateMessage: {{ validateMessage }}<br />
		<br /> -->
    <!--  <div>-->

    <!--  <div>{{  date1 }}<br /> {{ computedDate }}</div>-->
    <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :error-messages="validateMessage"
                :disabled="disabled"
                dense
                filled
                rounded
                v-model="computedDate"
                class="rounded-of"
                :background-color="BackgroundColor"
                :label="label"
                hide-details="auto"
                append-icon="icons8-timeline-week"
                readonly
                v-bind="attrs"
                v-on="on"
                :clearable="clearable"
                @click:clear="clearDate"
            >
            </v-text-field>
            <!-- v-model="innerValue" -->
        </template>
        <v-date-picker v-model="date1" no-title @input="dateMenu = false" :min="min" :max="max"></v-date-picker>
    </v-menu>
    <!--  </div>-->
    <!-- </div> -->
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        BackgroundColor: {
            type: String,
            default: 'grey lighten-4'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        initialdate: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: true
        },
        validate: {
            type: String,
            default: ''
        },
        min: {
            type: String,
            default: null
        },
        max: {
            type: String,
            default: null
        }
    },
    data: () => ({
        innerValue: '',
        validateMessage: '',
        dateMenu: false,
        date1: '' //vm.MIX_formatDateTimeNow("YYYY-MM-DD")
    }),
    computed: {
        // Computed Date
        computedDate() {
            // console.log("XXX computedDate: " + this.date1);
            return this.$dayjs(this.date1, 'YYYY-MM-DD').format('DD-MMM-YY')
        }
    },
    watch: {
        value: function (value) {
            this.innerValue = value
            this.date1 = value
            this.validateMessage = ''
        },
        initialdate: function (value) {
            this.date1 = value
        },
        validate: function (value) {
            this.validateMessage = value
        },
        // date1: function (value) {
        //   this.innerValue = this.MIX_formatDateTime(value, "YYYY-MM-DD", "DD-MMM-YY")
        // },

        // Update Parent v-model
        date1: function (value) {
            this.$emit('input', value)
        }
    },
    methods: {
        clearDate() {
            setTimeout(() => {
                this.innerValue = ''
                this.dateMenu = false
            }, 1)
        }
    },
    mounted() {
        this.innerValue = this.value
        // console.log("XXX initialdate = " + this.initialdate)
        this.date1 = this.initialdate
    }
}
</script>
